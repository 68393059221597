/* reset */
$min-width: 320px; 
$max-width: 1220px;
$base-bg: #FFF; 
$base-font-size: 16px; 
$base-line-height: 18px; 
$base-font-family: 'Montserrat', sans-serif; 
$custom-font-family: 'Montserrat', sans-serif; 
$link-color: #b3724f;
$transition: all 0.3s linear;

/* main variables */

//text, titles
$text-color: #666666;
$title-color: #222222;
$title-font-family: $custom-font-family;

$font-size-h1: 70px;
$line-height-h1: 70px;
$font-size-h2: 48px;
$line-height-h2: 54px;
$font-size-h3: 40px;
$line-height-h3: 56px;