/* reset */
$min-width: 320px;
$max-width: 1220px;
$base-bg: #FFF;
$base-font-size: 16px;
$base-line-height: 18px;
$base-font-family: 'Montserrat', sans-serif;
$custom-font-family: 'Montserrat', sans-serif;
$link-color: #b3724f;
$transition: all 0.3s linear;

/* main variables */

//text, titles
$text-color: #666666;
$title-color: #222222;
$title-font-family: $custom-font-family;

$font-size-h1: 70px;
$line-height-h1: 70px;
$font-size-h2: 48px;
$line-height-h2: 54px;
$font-size-h3: 40px;
$line-height-h3: 56px;


.main-wrapper {
  padding: 0 0 0 0;
  min-width: $min-width;
  width: 100%;
  position: relative;
  overflow: hidden;
  min-height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
}

.wrapper {
  min-width: $min-width;
  max-width: $max-width;
  padding: 0 40px 0 40px;
  margin: 0 auto;
  position: relative;
}

/* titles */
p {
  @include font($base-font-size, $base-line-height, $text-color);
  font-weight: 400;
  line-height: 170%;
  color: #000000;
}

h1 {
  @include font($font-size-h1, $line-height-h1, $title-color);
  font-family: 'Montserrat', sans-serif;
  text-transform: capitalize;
  font-weight: 700;
}

h2 {
  @include font($font-size-h2, $line-height-h2, $title-color);
  font-family: 'Montserrat', sans-serif;
  text-transform: capitalize;
  font-weight: 700;
}

h3 {
  @include font($font-size-h3, $line-height-h3, $title-color);
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
  text-transform: capitalize;
}


h4 {
  font-size: 32px;
  line-height: 130%;
  font-family: 'Montserrat', sans-serif;
  text-transform: capitalize;
  font-weight: 400;
}

h5,
.title5 {
  font-size: 24px;
  line-height: 100%;
  font-family: 'Montserrat', sans-serif;
  text-transform: capitalize;
  font-weight: 400;
  color: #222222;
}

h6,
.title6 {
  font-size: 20px;
  line-height: 120%;
  font-family: 'Montserrat', sans-serif;
  text-transform: capitalize;
  font-weight: 400;
  color: #222222;
}

/* text position */
.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.nowrap {
  white-space: nowrap !important;
}

/* loader */
.loaded .main-wrapper {
  visibility: hidden;
  opacity: 0;
}

.icon-load {
  background: url(../../../public/assets/img/loader.gif) no-repeat left top;
  width: 40px;
  height: 40px;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -20px;
  margin-left: -20px;
  display: none;
}

.loaded .icon-load {
  display: block;
}

.turnkey.info-blocks-video .info-blocks__item-img img {
  height: 71%;
  margin-top: 150px;
  margin-left: 0px !important;
}

/*
================================================================================
|                                     CONTENT                                 |
================================================================================
*/
.content {
  min-width: $min-width;
  text-align: left;
  width: 100%;
  order: 2;
  -webkit-order: 2;
  flex-grow: 1;
  -webkit-flex-grow: 1;
  overflow: hidden;
}

.btn {
  height: 60px;
  line-height: 59px;
  outline: none;
  border: none;
  background: #333333;
  padding: 0 50px;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  text-transform: uppercase;
  display: inline-block;
  transition: $transition;
  font-family: 'Lato';

  &:hover {
    background-color: #878686;
  }

  &-icon {
    display: flex;
    align-items: center;

    i {
      margin-right: 12px;
      font-size: 17px;
    }
  }

  &-grey {
    background: #faf9ff;
    border: 1px solid #eeeeee;
    color: #222222;

    &:hover {
      color: #eee;
      background: #222222;
      border-color: #222222;
    }
  }
}

.saint-text {
  font-family: "Acumin Pro";
  // font-style: italic;
  color: #000000;
  display: block;
  font-size: 30px;
  text-transform: capitalize;
  padding-bottom: 3px;
}

.main-text {
  display: block;
  font-family: 'Montserrat', sans-serif;
  color: #222222;
  font-size: 100px;
  margin-bottom: 30px;
  margin-top: 4px;

  span {
    color: #878686;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 45%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 181px;
      height: 155px;
      background-image: url(../../../public/assets/img/main-text-decor.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      transition: $transition;
    }
  }
}

// main block

.main-block {
  // padding-top: 340px;
  // padding-bottom: 237px;
  // background-image: url(../../../public/assets/img/main-bg.jpg);
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;
  // position: relative;
  // z-index: 1;

  &__content {
    margin-left: -8px;
  }

  &__decor {
    position: absolute;
    left: 0;
    top: 36%;
    width: 42.4%;
    z-index: -1;
  }

  .saint-text {
    font-size: 60px;
    padding-left: 9px;
  }

  .main-text {
    line-height: normal;
  }

  p {
    max-width: 465px;
    font-size: 20px;
    line-height: 150%;
    font-family: 'Montserrat', sans-serif;
    padding-left: 10px;
  }

  .btn {
    margin-top: 60px;
    margin-left: 10px;
  }
}

.box-tab-cont {
  position: relative;
}

.hide {
  position: absolute;
  left: 0;
  top: 0;
  height: 0;
  width: 0;
  overflow: hidden;
  z-index: -1;
  opacity: 0;
}

// trending

.trending {
  overflow-x: hidden;
  padding-top: 180px;
  padding-bottom: 130px;
  background-image: url(../../../public/assets/img/bg2.jpg);
  background-repeat: no-repeat;
  background-position-x: right;

  &-top {
    text-align: center;
    margin-bottom: 59px;

    p {
      max-width: 456px;
      margin: 0 auto;
      margin-top: 29px;
    }
  }

  &-tabs {
    .tabs {
      justify-content: center;
      display: flex;
      margin-bottom: 40px;
      flex-wrap: wrap;
    }

    li {
      margin: 0 7.1px;

      a {
        padding: 0 30px;
        height: 41px;
        line-height: 40px;
        background: #faf9ff;
        border: 1px solid #eeeeee;
        font-size: 16px;
        color: #666666;
        display: block;

        &:hover {
          opacity: 0.8;
        }

        @-moz-document url-prefix() {
          line-height: 38px;
        }
      }

      &.active {
        a {
          background: #878686;
          color: #ffffff;
        }
      }
    }
  }
}

.products {
  &-items {
    padding: 0 115px;
    margin-left: -22px;
    margin-right: -22px;

    & .slick-arrow.slick-prev {
      top: 51%;
    }
  }

  &-item {
    margin: 0 15px;
    // width: calc(25% - 30px);
    position: relative;
    display: block;

    & button.addList {
      border: none;
      background: none;

      &.added i {
        background: #9a9291;
      }
    }

    &__img {
      position: relative;
      height: 570px;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
        // object-fit: cover;
      }
    }

    &__hover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #222222;
      background-color: rgba($color: #222222, $alpha: 0.15);
      padding: 30px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      opacity: 0;
      transition: $transition;

      .icon-search {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 70px;
        color: #fff;
      }

      &-options {
        display: flex;

        i {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          margin: 0 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #ffffff;
          box-shadow: 0px 4px 10px rgba(34, 34, 34, 0.15);
          color: #222222;
          cursor: pointer;
          transition: $transition;
          font-size: 20px;

          &.icon-cart {
            background: #878686;
            color: #fff;
          }

          &:hover {
            opacity: 0.8;
          }

          &.active {
            background: #878686;
            color: #fff;
          }
        }
      }
    }

    &:hover {
      .products-item__hover {
        opacity: 1;
      }
    }

    &__type {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      flex-direction: column;
    }

    &__sale,
    &__new {
      background: #b3724f;
      padding: 10px 25px;
      text-transform: uppercase;
      color: #ffffff;
      font-weight: bold;
      font-size: 14px;
      line-height: 100%;
      z-index: 1;
    }

    &__new {
      background: rgba(208, 82, 120, 0.7);
    }

    &__info {
      text-align: center;
      margin-top: 36px;
    }

    &__name {
      font-size: 24px;
      text-transform: capitalize;
      color: #222222;
      display: block;
      margin-bottom: 9px;
      font-family: 'Montserrat', sans-serif;
      padding: 0 3px;
    }

    &__cost {
      font-weight: 700;
      font-size: 18px;
      line-height: 170%;
      color: #666666;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        color: #999999;
        font-size: 16px;
        line-height: 170%;
        text-decoration-line: line-through;
        margin-right: 10px;
        font-weight: 400;
      }
    }
  }
}

.slick-arrow {
  position: absolute;
  top: 45.5%;
  transform: translateY(-50%);
  left: -48px;
  background: #faf9ff;
  border: 1px solid #eeeeee;
  height: 60px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: $transition;

  i {
    font-size: 20px;

    &:before {
      color: #999999;
    }
  }

  &:hover {
    background: #222222;
    border: 1px solid #222222;

    i {
      &:before {
        color: #fff;
      }
    }
  }



  &.slick-prev::before {
    content: '';
  }

  &.slick-next {
    // transform: rotate(180deg);
    right: -48px;
    left: auto;

    &::before {
      content: '';
    }
  }

  &.slick-disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

// main-logos

.main-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 130px;

  img {
    margin: 0 56px;
  }
}

.main-logos img {
  height: auto;
  /* width: 115px; */
  display: inline-block;
  width: 6%;
}

// discount

.discount {
  padding: 0px 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .wrapper {
    display: flex;
    justify-content: flex-end;
  }

  &-info {
    max-width: 465px;
    margin-right: 84px;

    .saint-text {
      font-size: 60px;
      padding-left: 9px;
    }

    .main-text {
      text-transform: capitalize;
    }

    p {
      font-size: 20px;
      line-height: 150%;
      font-family: 'Montserrat', sans-serif;
      padding-left: 10px;

      &.discount-info__sm {
        font-size: 16px;
        line-height: 170%;
        font-family: 'Lato';
      }
    }

    .btn {
      margin-top: 60px;
      margin-left: 10px;
    }
  }
}

// advantages

.advantages {
  padding-top: 120px;

  &-items {
    display: flex;
    justify-content: center;
    margin-left: -12px;
    margin-right: -12px;
  }

  &-item {
    margin: 0 45px;
    width: calc(33.3% - 90px);
    text-align: center;

    &__icon {
      margin-bottom: 55px;

      i {
        position: relative;
        font-size: 70px;

        &:after {
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 110.52px;
          height: 94.85px;
          background-image: url(../../../public/assets/img/main-text-decor.png);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          transition: $transition;
        }
      }
    }

    h4 {
      margin-bottom: 6px;
    }
  }
}

// top-categories

.top-categories {
  padding-top: 184px;
  overflow: hidden;

  &__text {
    max-width: 456px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 56px;
    padding-left: 10px;

    p {
      margin-top: 30px;
    }
  }

  &__items {
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
  }

  &__item {
    width: calc(33.3% - 30px);
    margin: 0 15px;
    position: relative;
    height: 610px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &-hover {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba($color: #222222, $alpha: 0.3);
      transition: $transition;
      text-align: center;
      flex-direction: column;
      opacity: 0;

      span {
        display: block;
        font-size: 14px;
        line-height: 170%;
        text-transform: uppercase;
        color: #fff;
      }

      i {
        font-size: 50px;
        margin-top: -15px;
      }

      h5 {
        position: relative;
        margin-bottom: 51px;
        z-index: 1;
        margin-top: 44px;

        &:after {
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 166px;
          height: 66px;
          background-image: url(../../../public/assets/img/top-categories-decor.png);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          transition: $transition;
          z-index: -1;
        }
      }
    }

    &:hover {
      .top-categories__item-hover {
        opacity: 1;
      }
    }
  }
}

// info-blocks

.info-blocks {
  padding-top: 0px;

  &__item {
    display: flex;
    justify-content: space-between;
    position: relative;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-color: #faf9ff;

    .wrapper {
      position: initial;
      display: flex;
      justify-content: flex-end;
      width: 100%;
      align-items: flex-end;
      max-width: 1750px;
    }

    &-reverse {
      flex-direction: row-reverse;
      background-position: left bottom;
      background-color: #ffffff;

      .info-blocks__item-text {
        padding: 237px 10px 223px 0;
      }

      .info-blocks__item-img {
        left: auto;
        right: 0;
      }

      .wrapper {
        justify-content: flex-start;
      }
    }

    &-img {
      width: 50%;
      position: absolute;
      top: 0;
      left: 15px;
      height: 100%;
      display: flex;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin-left: 50vw;
      }

      &-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba($color: #222222, $alpha: 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
        z-index: 10;

        span {
          font-size: 40px;
          line-height: 140%;
          font-family: 'Montserrat', sans-serif;
          display: block;
          color: #fff;
          margin-bottom: 45px;
          text-transform: capitalize;
          margin-top: -101px;
        }
      }

      &-play {
        cursor: pointer;
        width: 150px;
        height: 150px;
        position: relative;
        transition: $transition;

        &:after {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: calc(100% + 25px);
          height: calc(100% + 25px);
          border: 3px solid #ffffff;
          filter: blur(5px);
          content: '';
          border-radius: 50%;
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }

    &-text {
      width: 100%;
      padding: 210px 0px;
      padding-right: 0;
      z-index: 0;

      h2,
      p {
        max-width: 456px;
      }

      p {
        display: block;
        font-size: 16px;
        line-height: 170%;
        color: #666666;
        margin-top: 24px;
      }

      .btn {
        margin-top: 60px;
      }
    }

    &-descr {
      font-size: 20px;
      line-height: 150%;
      color: #666666;
      display: block;
      max-width: 456px;
      font-family: 'Montserrat', sans-serif;
      margin-top: 31px;
    }

    &-link {
      font-weight: bold;
      font-size: 14px;
      line-height: 100%;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      color: #222222;
      margin-top: 35px;

      &:hover {
        opacity: 0.8;
      }

      .icon-video {
        font-size: 24px;
        margin-right: 20px;
      }

      .icon-arrow-lg {
        font-size: 51px;
        margin-left: 19px;
        transition: $transition;

        &:before {
          color: #222222;
        }
      }

      &:hover {
        .icon-arrow-lg {
          margin-left: 25px;
        }
      }
    }

    iframe {
      z-index: 99;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}

// arrivals

.arrivals {
  overflow-x: hidden;
  padding-top: 188px;

  .trending-top {
    margin-bottom: 58px;
    padding-left: 10px;
  }
}

// latest-news

.latest-news {
  padding-top: 184px;
  background-image: url(../../../public/assets/img/bg1.jpg);
  background-repeat: no-repeat;

  &__btn {
    text-align: center;
  }

  .trending-top {
    padding-left: 10px;
  }

  .blog-items {
    margin-bottom: 0;
  }
}

.blog-items {
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  flex-wrap: wrap;
}

.blog-item {
  width: calc(50% - 30px);
  margin: 0 15px;
  margin-bottom: 60px;

  &__img {
    height: 300px;
    position: relative;
    display: block;
    transition: $transition;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__date {
    position: absolute;
    top: 23px;
    right: 35px;
    z-index: 1;

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 77px;
      height: 66px;
      background-image: url(../../../public/assets/img/blog-item__date-decor.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      transition: $transition;
      z-index: -1;
    }

    font-weight: bold;
    color: #222222;
    font-size: 24px;
    line-height: 24px;
    text-align: center;

    span {
      font-size: 14px;
      line-height: 100%;
      display: block;
    }
  }

  &__title {
    display: block;
    margin-top: 40px;
    font-size: 24px;
    line-height: 26px;
    color: #222222;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 15px;

    // display: -webkit-box;
    // -webkit-line-clamp: 1;
    // -webkit-box-orient: vertical;
    // overflow: hidden;
    // text-transform: capitalize;
    &:hover {
      color: #878686;
    }
  }

  p {
    margin-bottom: 25px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 90%;
  }

  &__link {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 170%;
    text-transform: uppercase;
    width: max-content;

    i {
      font-size: 8px;
      margin-left: 10px;
      transition: $transition;
      margin-top: 1px;
    }

    &:hover {
      opacity: 0.8;

      i {
        margin-left: 15px;
      }
    }
  }
}

// subscribe

.subscribe {
  margin-top: 180px;
  margin-bottom: 60px;
  // background-image: url(../../../public/assets/img/bg2.jpg);
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: bottom;

  &-form {
    background-color: #303030;
    display: flex;
    align-items: center;
    padding: 0 87px 0 0px;
    overflow: hidden;

    &__img {
      position: relative;
      z-index: 1;
      padding-top: 0px;
      margin-right: 29px;

      img {
        min-height: 50px;
        position: relative;
        display: block;
      }
    }

    form {
      z-index: 1;
      margin-top: -5px;
      width: calc(100% - 425px);
    }

    .box-field__row {
      margin-top: 30px;
      justify-content: space-between;
      width: 30vw;

      .box-field {
        width: calc(100% - 186px);
      }
    }
  }
}

.subscribe h3 {
  color: #fff;
  font-weight: 700;
}

.subscribe p {
  color: #b3724f;
}

.subscribe button {
  background-color: #818181 !important;
}

.box-field__row {
  display: flex;
}

.box-field {
  .form-control {
    background: #fcf8f5;
    border: 1px solid #f2e7d6;
    height: 60px;
    line-height: 60px;
    padding: 18px 29px;
    font-size: 14px;
    width: 100%;

    &::placeholder {
      color: #999999;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: #999999;
    }

    &::-ms-input-placeholder {
      color: #999999;
    }
  }
}

.safari {
  .box-field {
    .form-control {
      line-height: normal;
    }
  }
}

// insta-photos

.insta-photos {
  display: flex;
}

.insta-photo {
  width: 33.33%;
  height: 260px;
  position: relative;
  margin-top: 130px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__hover {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #222222;
    opacity: 0;
    transition: $transition;

    i {
      font-size: 70px;
    }
  }

  &:hover {
    .insta-photo__hover {
      opacity: 0.5;
    }
  }
}

// about page

.detail-block {
  padding-top: 150px;
  // margin-top: 290px;
  background-image: url(../../../public/assets/img/detail-main-bg.jpeg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  text-align: center;
  z-index: 1;

  h1 {
    margin-bottom: 8px;
  }

  &__items {
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: -57px;
    margin-bottom: -60px;
    z-index: 99;
    position: relative;
  }

  &__item {
    margin: 0 15px;
    width: calc(33.3% - 30px);
    background: #faf9ff;
    border: 1px solid #eeeeee;
    padding: 26px 56px 26px 42px;
    display: flex;
    align-items: center;
    text-align: left;

    &-icon {
      width: 80px;
      height: 67.3px;
      margin-right: 27px;
      position: relative;

      i {
        position: absolute;
        font-size: 50px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &-info {
      font-size: 16px;
      line-height: 170%;
      color: #666666;
      margin-top: 5px;

      h6 {
        display: block;
        margin-bottom: 1px;
      }
    }
  }
}

.bread-crumbs {
  display: flex;
  justify-content: center;
  align-items: center;

  li {
    margin: 0 13px;
    font-size: 16px;
    line-height: 170%;
    color: #878686;
    position: relative;

    &:after {
      position: absolute;
      top: 40%;
      transform: translateY(-40%);
      right: -18px;
      content: ">";
      color: #ffffff;
    }

    a {
      color: #666666;

      &:hover {
        color: #878686;
      }
    }

    &:last-of-type {
      &:after {
        display: none;
      }
    }
  }
}

.promo-video {
  padding-top: 125px;
  margin-bottom: 185px;
  position: relative;

  &__decor {
    position: absolute;
    left: -13px;
    top: 3.5%;
    width: 29%;
    z-index: -1;
  }

  &__block {
    position: relative;
    width: 100%;
    height: 550px;
    margin-top: -5px;

    iframe {
      z-index: 2;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .info-blocks__item-img-overlay {
      background-color: rgba($color: #222222, $alpha: 0.4);
    }
  }

  &__nums {
    margin-top: 120px;
    display: flex;
    justify-content: space-between;
    padding: 0 45px 0 22px;
  }

  &__num {
    text-align: center;

    span {
      position: relative;
      font-weight: bold;
      font-size: 54px;
      line-height: 100%;
      text-transform: capitalize;
      color: #878686;
      display: block;
      margin-bottom: 42px;
      z-index: 1;

      &:after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 110.52px;
        height: 94.85px;
        background-image: url(../../../public/assets/img/promo-video__num-decor.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        transition: all 0.3s linear;
        z-index: -1;
      }
    }
  }
}

.discount-about {
  padding: 191px 0;

  .discount-info {
    margin-right: 85px;

    p {
      padding-left: 0px;
    }

    p.discount-info__sm {
      margin-top: 23px;
    }

    .btn {
      margin-left: 0;
    }
  }

  .saint-text {
    font-size: 50px;
    padding-left: 0px;
  }

  h2 {
    margin-bottom: 22px;
  }
}

.testimonials {
  padding-top: 191px;
  margin-bottom: -5px;

  &-slider {
    padding: 0 195px;
    max-width: 93%;
    margin: 0 auto;

    .slick-arrow {
      left: -135px;
    }

    .slick-arrow.slick-next {
      right: -135px;
      left: auto;
      top: -1px !important;
    }
  }

  &-slide {
    text-align: center;

    p {
      font-size: 20px;
      line-height: 150%;
      font-family: 'Montserrat', sans-serif;
    }
  }

  &-author {
    margin-top: 52px;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      object-fit: cover;
      margin: 0 auto;
      margin-bottom: 11px;
    }
  }

  .slick-arrow {
    top: 29px;
  }
}

// contacts-info

.contacts-info {
  // padding-top: 240px;
  padding-bottom: 74px;

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__text {
    width: calc(100% - 295px);

    h4 {
      margin-bottom: 30px;
    }

    p {
      letter-spacing: 0.15px;
    }
  }

  &__social {
    margin-top: -7px;

    span {
      font-size: 16px;
      line-height: 170%;
      color: #222222;
      display: block;
      margin-bottom: 8px;
    }

    ul {
      display: flex;

      li {
        margin-right: 10px;

        &:last-of-type {
          margin-right: 0;
        }

        a {
          width: 45px;
          height: 45px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #faf9ff;
          border: 1px solid #eeeeee;

          i {
            &:before {
              color: #222222;
              transition: $transition;
            }
          }

          &:hover {
            background: #222222;
            border-color: #222222;

            i {
              &:before {
                color: #faf9ff;
              }
            }
          }
        }
      }
    }
  }
}

.main-logos_contacts {
  padding-bottom: 182px;
}

.discount {
  &-contacts {
    padding: 182px 0 176px;
    margin-top: 0;
    margin-bottom: 0;

    .saint-text {
      font-size: 26px;
      padding-left: 0px;
      font-weight: 600;
    }

    .main-text {
      margin-bottom: 22px;
      font-size: 46px;
      font-weight: 700;
    }

    .discount-info {
      max-width: 640px;
      margin-right: 82px;

      p {
        padding-left: 0;
      }
    }
  }

  form {
    margin-top: 44px;

    .box-field {
      margin-bottom: 10px;
    }

    .btn {
      margin-top: -2px;
      margin-left: 0px;
    }
  }
}

.box-field__textarea {
  textarea.form-control {
    height: 120px;
    resize: none;
    line-height: normal;
  }
}

.insta-photos_contacts {
  .insta-photo {
    margin-top: 0;
  }
}

.contacts-map {
  width: 100%;
  // height: 400px;

  #map {
    width: 100%;
    height: 100%;
    pointer-events: none;
    position: relative;

    &:after {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      content: '';
      mix-blend-mode: multiply;
      // background-color: rgba($color: #f2e7d6 , $alpha: 0.5);
      background-color: #f2e7d6;

      @supports (-ms-ime-align: auto) {
        background-color: rgba($color: #f2e7d6, $alpha: 0.5);
      }
    }
  }
}

// categories page

.detail-block_margin {
  padding-bottom: 150px;
}

.all-categories {
  padding-top: 180px;
  padding-bottom: 20px;

  .top-categories__items {
    flex-wrap: wrap;
  }

  .top-categories__item {
    margin-bottom: 30px;
  }
}

// faq page

.faq {
  padding-top: 184px;

  .promo-video__decor {
    top: 14%;
  }

  &-search {
    margin-bottom: 52px;
  }

  &-item {
    margin-bottom: 41px;
    transition: $transition;

    &:last-of-type {
      margin-bottom: 0;
    }

    &__head {
      cursor: pointer;
      transition: $transition;
      position: relative;
      font-size: 20px;
      line-height: 150%;
      color: #222222;
      font-family: 'Montserrat', sans-serif;
      padding-bottom: 18px;
      display: flex;
      align-items: center;

      &:hover {
        opacity: 0.8;
      }

      &-num {
        font-family: 'Lato';
        position: relative;
        width: 40px;
        text-align: center;
        display: inline-block;
        margin-right: 30px;
        color: #222222;
        padding-top: 2px;

        &:after {
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 40px;
          height: 34.33px;
          background-image: url(../../../public/assets/img/main-text-decor.png);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          transition: all 0.3s linear;
        }
      }

      &-btn {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #faf9ff;
        border: 1px solid #eeeeee;
        line-height: 40px;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        position: absolute;
        right: 0;
        bottom: -20px;
        z-index: 1;

        &:before,
        &:after {
          content: '';
          position: absolute;
          background: #222222;
        }

        &:before {
          left: 50%;
          top: 50%;
          width: 2px;
          height: 8px;
          transform: translate(-50%, -50%);
        }

        &:after {
          top: 50%;
          left: 50%;
          height: 2px;
          width: 8px;
          transform: translate(-50%, -50%);
        }
      }

      &:after {
        position: absolute;
        right: 0;
        bottom: 0;
        content: '';
        background-color: #eeeeee;
        width: 95.5%;
        height: 1px;
      }
    }

    &__content {
      padding-top: 0;
      margin-top: 45px;
      padding-bottom: 0;
      padding-left: 119px;
      padding-right: 122px;
      display: none;
      position: relative;

      &:after {
        position: absolute;
        right: 0;
        bottom: 0;
        content: '';
        background-color: #eeeeee;
        width: 95.5%;
        height: 1px;
      }
    }

    &.active {

      // margin-top: 50px;
      // margin-bottom: 34px;
      .faq-item__head-num {
        color: #222222;
      }

      .faq-item__head {
        color: #878686;
      }

      .faq-item__head-btn {
        background: #f2e7d6;
        border-color: #f2e7d6;

        &:before {
          display: none;
        }
      }
    }
  }

  &-more {
    font-size: 14px;
    line-height: 170%;
    text-transform: uppercase;
    margin-top: 81px;

    i {
      font-size: 8px;
      margin-left: 10px;
      transition: $transition;
    }

    a {
      display: flex;
      align-items: center;

      &:hover {
        opacity: 0.8;

        i {
          margin-left: 15px;
        }
      }
    }
  }
}

.box-field__row-search {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .box-field {
    width: calc(100% - 193px);
  }
}

// blog

.blog {
  padding-top: 180px;
  background-image: url(../../../public/assets/img/bg1.jpg);
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: top;

  &-items {
    margin-bottom: 3px;
  }

  .promo-video__decor {
    top: 13%;
  }
}

.paging-list {
  display: flex;
  justify-content: center;
  align-items: center;

  &__link {
    min-width: 40px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 170%;
    color: #666666;
    background: #faf9ff;
    border: 1px solid #eeeeee;

    i {
      font-size: 20px;

      &:before {
        color: #999999;
      }
    }

    &:hover {
      background: #222222;
      border-color: #222222;
      color: #ffffff;

      i {
        &:before {
          color: #ffffff;
        }
      }
    }
  }

  &__item {
    margin: 0 2.5px;

    &.active {
      .paging-list__link {
        background: #222222;
        border-color: #222222;
        color: #ffffff;

        i {
          &:before {
            color: #ffffff;
          }
        }
      }
    }

    &.paging-next {
      .paging-list__link {
        i {
          transform: rotate(180deg);
        }
      }
    }
  }
}

// post

.post {
  padding-top: 180px;
  padding-bottom: 48px;
  overflow-x: hidden;

  .promo-video__decor {
    top: 11.5%;
  }

  &-top {
    text-align: center;
    border-bottom: 1px solid #eeeeee;

    p {
      margin-top: 30px;
      margin-bottom: 60px;
      font-size: 20px;
      line-height: 150%;
      color: #666666;
      font-family: 'Montserrat', sans-serif;
    }

    img {
      width: 100%;
    }

    &__info {
      display: flex;
      padding: 30px 0 21px;
      padding-right: 20px;

      li {
        font-size: 14px;
        line-height: 170%;
        color: #999999;
        display: flex;
        align-items: center;

        a {
          color: #222222;

          &:hover {
            opacity: 0.8;
          }
        }

        i {
          margin-right: 10px;

          &:before {
            display: block;
          }
        }
      }
    }

    &__user {
      margin-left: 58px;
    }

    &__watch {
      margin-left: auto;

      i {
        font-size: 10px;
      }
    }

    &__comment {
      margin-left: 30px;
    }
  }

  &-content {
    padding: 60px 98px 0;
    font-size: 16px;
    line-height: 170%;
    color: #666666;

    h6 {
      border-top: 1px solid #eeeeee;
      margin-top: 52px;
      padding-top: 60px;
      margin-bottom: 6px;
    }

    blockquote {
      margin-top: 53px;
    }
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #eeeeee;
    justify-content: space-between;
    padding-top: 11px;

    li {
      width: 47%;
      margin-top: 50px;
      align-self: flex-start;

      span {
        display: block;
        margin-bottom: 6px;
        font-size: 20px;
        line-height: 120%;
        font-family: 'Montserrat', sans-serif;
        color: #222222;
        text-transform: capitalize;
      }

      p {
        font-size: 16px;
        line-height: 170%;
        color: #666666;
      }

      &:last-of-type {
        margin-top: 25px;
      }
    }
  }

  .discount {
    width: 100vw;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-bottom: 100px;
    margin-top: 95px;
    padding-top: 177px;
    padding-bottom: 165px;

    h2 {
      margin-top: 10px;
    }

    .discount-info {
      max-width: 535px;
      margin-right: 15px;
    }
  }

  &-bottom {
    margin-top: 30px;
    padding-left: 98px;
    padding-right: 98px;

    &__info {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__tags {
      display: flex;
      align-items: center;
      margin-top: -8px;

      span {
        color: #222222;
        font-size: 16px;
        margin-right: 20px;
      }

      ul {
        display: flex;
      }

      li {
        margin-right: 8px;

        a {
          border: 1px solid #eeeeee;
          padding: 10.5px 20px;
          color: #666666;
          display: block;

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }

    .contacts-info__social {
      display: flex;
      align-items: center;
      margin-top: 0;

      span {
        margin-right: 19px;
        margin-bottom: 0;
      }
    }

    &__nav {
      margin-top: 30px;
      padding-top: 30px;
      border-top: 1px solid #eeeeee;
      display: flex;
      justify-content: space-between;

      a {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 170%;
        text-transform: uppercase;
        color: #999999;

        i {
          background: #faf9ff;
          border: 1px solid #eeeeee;
          padding: 17px 12px;
          font-size: 22px;
          margin-right: 32px;
          transition: $transition;
        }

        &:hover {
          color: #000000;

          i {
            background: #222222;
            border-color: #222222;

            &:before {
              color: #fff;
            }
          }
        }

        &:last-of-type {
          i {
            transform: rotate(180deg);
            margin-right: 0;
            margin-left: 32px;
          }
        }
      }
    }
  }

  &-comments {
    margin-top: 61px;
    padding-left: 98px;
    padding-right: 98px;

    h3 {
      margin-bottom: 30px;
    }
  }

  &-comment {
    margin-bottom: 19px;
    border: 1px solid #eeeeee;
    padding: 40px 38px 34px;

    &__head {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__author {
      display: flex;
      align-items: center;

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 30px;
      }
    }

    &__name {
      font-size: 16px;
      color: #222222;
      margin-right: 40px;
      position: relative;

      &:after {
        position: absolute;
        right: -21px;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        width: 1px;
        height: 10px;
        background-color: #eee;
      }
    }

    &__date {
      color: #999999;
      font-size: 14px;
    }

    &__reply {
      display: flex;
      align-items: center;

      i {
        margin-right: 10px;
        font-size: 10px;
      }

      &:hover {
        opacity: 0.8;
      }
    }

    &__content {
      margin-top: 19px;
      color: #666666;
      font-size: 16px;
      line-height: 170%;
    }

    &__answer {
      background: #faf9ff;
      margin-left: 30px;
    }

    &__form {
      background: #f2e7d6;
      padding: 60px;
      margin-top: 79px;
      overflow: hidden;
      position: relative;

      .subscribe-form__img {
        position: absolute;
        right: 85px;
        bottom: -33px;
        padding: 0;
        margin: 0;
        width: 208px;

        &:after {
          background-image: url(../../../public/assets/img/comment-form-decor.png);
          left: 50%;
          top: 0%;
          width: 380px;
        }
      }

      form {
        z-index: 10;
        position: relative;
      }

      h3 {
        margin-bottom: 2px;
      }

      p {
        margin-bottom: 52px;
      }

      .box-field__row {
        margin-left: -5.5px;
        margin-right: -5.5px;
        display: flex;

        .box-field {
          margin-left: 5.5px;
          margin-right: 5.5px;
          width: calc(33.3% - 11px);
        }
      }

      .box-field {
        margin-bottom: 10px;
      }

      .box-field__textarea textarea.form-control {
        height: 100px;
      }
    }
  }
}

blockquote,
.blockquote {
  font-size: 20px;
  line-height: 150%;
  font-family: 'Montserrat', sans-serif;
  color: #222222;
  padding: 45px 72px;
  background: #faf9ff;
  position: relative;
  margin-bottom: 70px;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: 270px;
    height: 228px;
    background-image: url(../../../public/assets/img/blockquote-decor.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.blockquote-author {
  display: block;
  color: #878686;
  text-transform: capitalize;
  font-size: 24px;
  line-height: 100%;
  font-family: 'Montserrat', sans-serif;
  margin-top: 23px;
}

.discount {
  ul {
    margin-top: 22px;
  }

  li {
    font-size: 16px;
    line-height: 170%;
    color: #666666;
    margin-bottom: 0px;

    span {
      color: #222222;
    }
  }
}

// shop page

.shop {
  padding-top: 180px;
  position: relative;

  .promo-video__decor {
    top: 2.5%;
  }

  &-decor {
    position: absolute;
    right: 0;
    top: 57%;
    width: 32.5%;
    z-index: -1;
  }

  &-content {
    display: flex;
    justify-content: space-between;
  }

  &-aside {
    width: 252px;

    .box-field__search {
      position: relative;
      margin-bottom: 35px;

      i {
        position: absolute;
        right: 21px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 18px;
      }

      .form-control {
        padding-right: 50px;
      }
    }

    &__item {
      margin-bottom: 55px;

      &-title {
        display: block;
        font-size: 24px;
        line-height: 100%;
        text-transform: capitalize;
        padding-bottom: 10px;
        color: #222222;
        font-family: 'Montserrat', sans-serif;
        border-bottom: 2px solid #222222;
        margin-bottom: 15px;
        font-weight: 800;
      }

      ul {
        li {
          a {
            font-size: 16px;
            line-height: 170%;
            color: #666666;
            display: block;
            padding: 13px 0;
            border-bottom: 1px solid #eeeeee;

            span {
              margin-left: 8px;
              color: #999999;
              font-size: 12px;
            }

            &:hover {
              color: #878686;
              border-color: #878686;

              span {
                color: #878686;
              }
            }
          }
        }
      }

      .range-slider {
        margin-bottom: 123px;

        .rc-slider-handle {
          border: solid 2px #878686;
          background-color: #878686;

          &:hover {
            border-color: #878686;
            cursor: pointer;
          }
        }

        .rc-slider-handle:active {
          border-color: #878686;
          box-shadow: 0 0 5px #878686;
        }

        .rc-slider-track {
          background-color: #878686;
        }

        .rc-slider-tooltip-hidden {
          display: block;
        }

        .rc-slider-tooltip-inner {
          background: none;
          color: #666666;
          font-size: 14px;
          box-shadow: none;
        }
      }

      &-product {
        display: flex;
        margin-bottom: 30px;

        &:first-of-type {
          margin-top: 30px;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        &-img {
          width: 60px;
          height: 70px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        &-info {
          margin-left: 15px;
        }

        &-title {
          display: block;
          font-size: 16px;
          line-height: 170%;
          color: #222222;
          margin-bottom: -4px;
          transition: $transition;
        }

        &-price {
          color: #999999;
          font-size: 12px;
          line-height: 170%;
          margin-bottom: 5px;
          display: block;
        }

        &:hover {
          .shop-aside__item-product-title {
            color: #878686;
          }
        }
      }
    }
  }

  &-main {
    width: calc(100% - 254px);

    &__filter {
      display: flex;
      justify-content: end;
      align-items: center;
      margin-bottom: 32px;
    }

    &__items {
      display: flex;
      flex-wrap: wrap;
      margin-left: -15px;
      margin-right: -15px;
      margin-bottom: 15px;

      .products-item {
        width: calc(33.3% - 30px);
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 56px;

        & button.addList {
          border: none;
          background: none;

          &.added i {
            background: #9a9291;
          }
        }

        &__img {
          height: 370px;
        }

        &__name {
          font-size: 20px;
          line-height: 120%;
          margin-bottom: 8px;
        }

        &__cost {
          font-size: 18px;
          line-height: 100%;
          font-weight: 400;

          span {
            font-size: 16px;
            line-height: 170%;
          }
        }

        &__info {
          margin-top: 30px;
        }

        &__sale,
        &__new {
          font-size: 12px;
          padding: 10px 26px;
        }
      }
    }

    &__checkboxes {
      display: flex;
    }
  }
}

.box-field__search {

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
}

.irs {
  height: auto;
  font-family: 'Lato', sans-serif;
  margin-top: -15px;

  .irs-line {
    background-color: #eeeeee;
  }

  .irs-bar {
    height: 3px;
    background-color: #878686;
  }

  .irs-handle {
    background-color: #878686;
    box-shadow: none;
    border-color: #878686;
    width: 15px;
    height: 15px;
    top: 30px;
    cursor: pointer;

    &:hover,
    &.state_hover {
      background-color: #878686;
    }
  }

  .irs-from,
  .irs-to,
  .irs-single {
    font-size: 14px;
    color: #666666;
    padding: 0;
    background-color: transparent;
    top: auto;
    bottom: -63px;

    &:before {
      display: none;
    }
  }
}

.star-rating {
  display: flex;

  li {
    width: 13px;

    i {
      color: #cfc819;
      font-size: 13px;
    }
  }
}

.checkbox-box {
  position: relative;
  cursor: pointer;
  font-size: 14px;
  color: #666666;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #faf9ff;
  border: 1px solid #eeeeee;
  padding: 11.5px 16px;
  display: flex;
  align-items: center;
  margin-right: 19px;
}

.checkbox-box input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  width: 15px;
  height: 15px;
  border: none;
  background-color: #fff;
  margin-right: 10px;
  /* display: none; */
}

.checkbox-box:hover input~.checkmark {
  background-color: #fff;
  transition: $transition;
}

.checkbox-box input:checked~.checkmark {
  background-color: #878686;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox-box input:checked~.checkmark:after {
  display: block;
}

.jq-selectbox {
  &__select {
    box-shadow: none;
    font-family: 'Lato', sans-serif;
    border: 1px solid #eeeeee !important;
    border-radius: 0;
    background: #faf9ff;
    font-size: 16px;
    line-height: 170%;
    padding: 6px 30px;
    color: #666666;
    width: 270px;

    &:hover {
      border: 1px solid #eeeeee;
      background: #faf9ff;
    }

    &-text {
      width: 100%;
      padding-right: 25px;
    }
  }

  &.focused {
    .jq-selectbox__select {
      border: 1px solid #eeeeee;
      background: #faf9ff;
    }
  }

  &__trigger {
    top: 4px;
    right: 18px;

    &-arrow {
      border-top: 6px solid #666666;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
    }
  }

  &__dropdown {
    box-shadow: none;
    border-radius: 0;
    border: 1px solid #eeeeee;
    margin-top: -1px;

    li {
      padding: 7px 30px;
      font-size: 16px;
      line-height: 170%;
      font-family: 'Lato', sans-serif;
      transition: $transition;
      color: #666666;

      &:hover,
      &.sel {
        background-color: #222222;
        color: #fff;
      }

      &.sel {
        background-color: #fff;
        color: #878686;
      }
    }
  }
}

// product page

.product {
  padding-top: 180px;

  .promo-video__decor {
    top: 11.7%;
    width: 30%;
  }

  &-content {
    display: flex;
    justify-content: space-between;
  }

  &-slider {
    width: 570px;

    &__main {
      width: 100%;
      margin-bottom: 10px;

      img {
        width: 100%;
        height: 570px;
        object-fit: cover;
      }
    }

    &__nav {
      margin-left: -5px;
      margin-right: -5px;

      &-item {
        height: 135px;
        // margin-left: 5px;
        margin-right: 5px;
        cursor: pointer;
        transition: $transition;
        position: relative;
        border: 2px solid transparent;

        &:after {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          content: '';
          // background-color: rgba(34, 34, 34, 0.15);
          opacity: 0;
          transition: $transition;
        }

        img {
          width: 40%;
          height: 100%;
          object-fit: contain;
        }

        &:hover {
          &:after {
            opacity: 1;
          }
        }

        &.slick-current {
          border: 2px solid #878686;

          &:hover {
            &:after {
              opacity: 0;
            }
          }
        }
      }

      & .slick-current {
        & img {
          border: 2px solid #878686;
        }
      }
    }
  }

  &-stock {
    display: block;
    margin-top: 15px;
    font-size: 12px;
    line-height: 170%;
    text-transform: uppercase;
    color: #12a33b;
  }

  &-num {
    font-size: 12px;
    line-height: 170%;
    color: #666666;
    display: block;
  }

  &-price {
    margin-top: 30px;
    color: #222222;
    font-size: 32px;
    line-height: 130%;
    text-transform: capitalize;
    display: block;
    margin-bottom: 31px;

    span {
      margin-right: 20px;
      font-size: 20px;
      line-height: 100%;
      text-decoration-line: line-through;
      color: #999999;
    }
  }

  &-options {
    margin-top: 46px;
    padding-top: 45px;
    border-top: 1px solid #eee;
    display: flex;
    justify-content: space-between;
  }

  &-info {
    width: calc(100% - 630px);

    .contacts-info__social {
      margin-top: 39px;
      display: flex;
      align-items: center;

      span {
        display: inline-block;
        margin-bottom: 0;
        margin-right: 30px;
      }
    }

    &__color {
      span {
        display: block;
        color: #222222;
        margin-bottom: 30px;
      }

      ul {
        display: block;
      }

      li {
        width: 25px;
        height: 25px;
        margin-right: 5px;
        border: 2px solid transparent;
        transition: $transition;
        cursor: pointer;
        display: inline-block;

        &:hover,
        &.active {
          border: 2px solid #878686;
        }
      }
    }

    &__quantity {
      &-title {
        display: block;
        color: #222222;
        margin-bottom: 15px;
      }
    }
  }

  &-buttons {
    margin-top: 60px;
    display: flex;

    .btn {
      margin-right: 11px;
      padding: 0 49px;

      i {
        margin-right: 10px;
      }
    }
  }

  &-detail {
    margin-top: 60px;
    padding-bottom: 60px;
    border-bottom: 1px solid #eee;

    &__form {
      margin-top: 0;
      width: 410px;
      padding: 68px 60px 65px;
      align-self: flex-start;

      h4 {
        margin-bottom: 11px;
        margin-left: 3px;
      }

      p {
        margin-bottom: 38px;
        margin-left: 3px;
      }

      .rating {
        margin-bottom: 21px;
      }

      .box-field__textarea textarea.form-control {
        height: 148px;
      }

      .subscribe-form__img {
        position: absolute;
        right: 14px;
        bottom: -10px;
        padding: 0;
        margin: 0;
        width: 160px;
      }

      .subscribe-form__img:after {
        top: 30%;
        width: 225px;
      }
    }

    &__items {
      width: calc(100% - 470px);

      .blog-item__link {
        margin-top: 62px;
      }
    }
  }

  &-reviews {
    display: flex;
    justify-content: space-between;
  }

  &-viewed {
    margin-bottom: 50px;
  }
}

.nav-tab-list {
  display: flex;
  margin-bottom: 30px;

  li {
    background: #faf9ff;
    border: 1px solid #eeeeee;
    padding: 6px 29px;
    font-size: 16px;
    line-height: 170%;
    color: #666666;
    cursor: pointer;

    &:hover {
      opacity: 0.85;
    }

    &.active {
      // a {
      background: #878686;
      border-color: #878686;
      color: #ffffff;
    }
  }

  &.pd-tab {
    gap: 15px;
  }
}

.review-item {
  margin-bottom: 19px;
  border: 1px solid #eeeeee;
  padding: 40px 38px 34px;
  padding-bottom: 39px;

  &__head {
    display: flex;
    align-items: center;
  }

  &__author {
    display: flex;
    align-items: center;

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 30px;
    }
  }

  &__name {
    font-size: 16px;
    color: #222222;
    margin-right: 40px;
    position: relative;

    &:after {
      position: absolute;
      right: -21px;
      top: 50%;
      transform: translateY(-50%);
      content: '';
      width: 1px;
      height: 10px;
      background-color: #eee;
    }
  }

  &__date {
    color: #999999;
    font-size: 14px;
    position: relative;

    &:after {
      position: absolute;
      right: -29px;
      top: 50%;
      transform: translateY(-50%);
      content: '';
      width: 1px;
      height: 10px;
      background-color: #eee;
    }
  }

  &__rating {
    margin-left: 49px;
  }

  &__content {
    margin-top: 19px;
    color: #666666;
    font-size: 16px;
    line-height: 170%;
  }
}

.counter {
  &-box {
    display: flex;
    align-items: center;
  }

  &-link {
    background: #faf9ff;
    border: 1px solid #eeeeee;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: $transition;
    width: 40px;
    height: 40px;
    cursor: pointer;

    i {
      font-size: 14px;

      &:before {
        color: #999999;
      }
    }

    &:hover {
      background: #222222;
      border: 1px solid #222222;

      i {
        &:before {
          color: #fff;
        }
      }
    }

    &.counter-link__next {
      transform: rotate(180deg);
    }
  }

  &-input {
    width: 60px;
    height: 40px;
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    color: #222222;
    text-align: center;
  }
}

.rating {
  display: flex;
  align-items: center;

  i {
    font-size: 18px;
    margin-right: -1px;
    display: block;
    position: relative;
    color: #fff;
    cursor: pointer;
    transition: $transition;
    font-family: 'icomoon';

    &.active {
      color: #cfc819;
    }
  }

  .count-star {
    display: none;
  }
}

// cart page

.cart {
  padding-top: 185px;

  .promo-video__decor {
    top: 17%;
  }

  &-table {
    width: 100%;

    &__row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 29px 0;
      border: 1px solid #eeeeee;
      margin-bottom: 20px;

      &-head {
        font-size: 20px;
        line-height: 120%;
        font-family: 'Montserrat', sans-serif;
        color: #222222;
        text-transform: capitalize;
        border: none;
        border-bottom: 2px solid #222222;
        padding: 0;
        padding-bottom: 9px;
        margin-bottom: 30px;
      }
    }

    &__col {
      width: 12%;

      &:first-of-type {
        width: 50%;
        padding-left: 30px;
        display: flex;
      }

      &:nth-of-type(3) {
        width: 23%;
        padding-left: 34px;
      }
    }

    &__img {
      width: 110px;
      height: 115px;
      margin-right: 28px;
      display: inline-block;
      vertical-align: top;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &:hover {
        opacity: 0.8;
      }
    }

    &__info {
      display: inline-block;
      vertical-align: top;
      margin-top: 10px;

      .title5 {
        &:hover {
          color: #878686;
        }
      }

      &-stock {
        display: block;
        font-size: 12px;
        line-height: 170%;
        text-transform: uppercase;
        color: #12a33b;
        margin-top: 10px;
      }

      &-num {
        display: block;
        font-size: 12px;
        line-height: 170%;
        color: #666666;
      }
    }

    &__price {
      color: #666666;
      font-size: 18px;
      line-height: 100%;

      span {
        display: block;
        margin-bottom: 10px;
        color: #999999;
        font-size: 14px;
        line-height: 100%;
        text-decoration-line: line-through;
        text-transform: capitalize;
      }
    }

    &__total {
      font-weight: 600;
      font-size: 20px;
      line-height: 150%;
      color: #222222;
    }
  }

  &-bottom {
    margin-top: 60px;
    display: flex;
    margin-bottom: 49px;
    justify-content: space-between;

    &__promo {
      width: calc(100% - 600px);

      &-form {
        margin-bottom: 46px;

        .box-field__row {
          justify-content: space-between;

          .box-field {
            width: calc(100% - 196px);
          }
        }

        .btn {
          padding: 0 49px;
        }
      }

      p {
        max-width: 480px;
        margin-top: 11px;
      }

      .contacts-info__social {
        margin-top: 32px;
      }
    }

    &__total {
      width: 100%;
      background: #faf9ff;
      border: 1px solid #eeeeee;
      padding: 60px;

      .btn {
        width: 100%;
        text-align: center;
      }

      &-goods {
        display: flex;
        justify-content: space-between;
        padding-bottom: 17px;
        border-bottom: 1px solid #eeeeee;
        margin-bottom: 24px;
        font-size: 16px;
        line-height: 170%;
        color: #222222;
        align-items: flex-end;

        span {
          font-size: 18px;
          line-height: 100%;
          color: #666666;
        }
      }

      &-promo {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        span {
          font-size: 18px;
          line-height: 100%;
          text-transform: capitalize;
          color: #666666;
        }
      }

      &-num {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        font-size: 24px;
        line-height: 100%;
        text-transform: capitalize;
        align-items: flex-end;
        padding-top: 29px;
        border-top: 1px solid #222222;
        margin-top: 23px;

        span {
          font-size: 24px;
          line-height: 100%;
          color: #222222;
          text-transform: capitalize;
        }
      }

      &-delivery {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 24px;
        padding-top: 20px;
        border-top: 1px solid #eeeeee;
        font-size: 16px;
        line-height: 170%;
        color: #222222;

        &-date {
          margin-right: auto;
          margin-left: 1px;
          color: #999999;
          font-size: 16px;
        }

        span {
          font-size: 18px;

          &.cart-bottom__total-delivery-date {
            font-size: 16px;
          }
        }
      }
    }
  }
}

// wishlist page

.wishlist {
  padding-top: 185px;

  .promo-video__decor {
    left: -18px;
    top: 13%;
    width: 25%;
  }

  &-stock {
    display: block;
    font-size: 12px;
    line-height: 170%;
    text-transform: uppercase;
    color: #12a33b;
  }

  &-available {
    display: block;
    font-size: 12px;
    line-height: 170%;
    text-decoration-line: line-through;
    text-transform: uppercase;
    color: #999999;
  }

  .cart-table__info-num {
    margin-top: 11px;
  }

  &-buttons {
    margin-top: 59px;
    margin-left: -3px;

    .btn {
      margin-right: 7px;
    }
  }

  .cart-table__col {
    width: 15%;
  }

  .cart-table__col:nth-of-type(3) {
    width: 17%;
    padding-left: 0px;
  }

  .cart-table__col:first-of-type {
    width: 50%;
  }
}

// checkout page
.detail-block__item-icon {
  i {
    &:before {
      color: #222222;
    }
  }
}

.detail-block__item-inactive {
  .detail-block__item-info {
    color: #999999;

    h6 {
      color: #999999;
    }
  }

  .detail-block__item-icon {
    i {
      &:before {
        color: #999999;
      }
    }
  }
}

.checkout {
  padding-top: 240px;
  padding-bottom: 50px;

  .promo-video__decor {
    top: 18%;
  }

  &-content {
    display: flex;
    justify-content: space-between;
  }

  &-form {
    width: calc(100% - 600px);

    &__item {
      margin-bottom: 21px;

      h4 {
        margin-bottom: 15px;
      }

      .box-field {
        margin-bottom: 10px;

        &:last-of-type {
          margin-bottom: 0;
        }

        &__row {
          display: flex;
          justify-content: space-between;

          .box-field {
            width: calc(50% - 5px);
          }
        }
      }

      .jq-selectbox__select {
        height: 60px;
        background: #ffffff;
        border: 1px solid #f2e7d6;
        color: #999999;
        font-size: 14px;
        margin-bottom: 10px;
        width: 100%;
        padding: 17px 30px;
      }

      .jq-selectbox {
        &.focused {
          .jq-selectbox__select {
            background: #ffffff;
            border: 1px solid #f2e7d6;
          }
        }
      }

      .jq-selectbox__trigger {
        top: 13px;
        right: 9px;
      }

      .jq-selectbox__dropdown {
        top: calc(100% - 10px) !important;
        bottom: auto !important;

        li {
          font-size: 14px;
        }
      }

      textarea {
        height: 120px;
      }

      .checkbox-box {
        margin-top: 34px;
      }
    }

    & .react-dropdown {
      & .Dropdown-control {
        background-color: #fff;
        border: 1px solid #f2e7d6 !important;
        color: #a5a0a0;
        padding: 22px 30px;
        font-size: 15px;
        margin-bottom: 10px;
      }

      & .Dropdown-arrow {
        top: 30px;
      }
    }
  }

  &-buttons {
    display: flex;
    margin-top: 65px;

    .btn {
      margin-right: 10px;
      padding: 0 49px;

      i {
        font-size: 11px;
        font-weight: 700;
        transition: $transition;
        margin-right: 10px;
      }

      &-next {
        padding: 0 49px;

        i {
          margin-right: 0;

          &:before {
            color: #fff;
          }
        }
      }

      &-grey {
        i {
          margin-top: 2px;

          &:before {
            color: #222222;
          }
        }

        &:hover {
          i {
            &:before {
              color: #fff;
            }
          }
        }
      }
    }
  }

  &-info {
    width: 540px;
    padding-top: 55px;

    .cart-bottom__total-num {
      margin-bottom: -2px;
      margin-top: 18px;
    }
  }

  &-order {
    margin-bottom: 30px;

    h5 {
      padding-bottom: 11px;
      border-bottom: 2px solid #222222;
      margin-bottom: 30px;
    }

    &__item {
      border: 1px solid #eeeeee;
      padding: 20px 20px;
      display: flex;
      margin-bottom: 10px;
      padding-bottom: 13px;

      &:last-of-type {
        margin-bottom: 0;
      }

      &-img {
        width: 70px;
        height: 70px;
        margin-right: 19px;

        &:hover {
          opacity: 0.8;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-info {

        h6,
        .title6 {
          margin-bottom: 10px;
          display: block;

          span {
            font-size: 16px;
            line-height: 100%;
            margin-left: 3px;
            display: inline-block;
            color: #999999;
            text-transform: initial;
            font-family: 'Lato', sans-serif;
          }

          &:hover {
            color: #878686;
          }
        }
      }

      &-price {
        display: block;
        color: #222222;
        font-size: 14px;
        line-height: 100%;
        margin-bottom: 10px;
      }

      &-num {
        display: block;
        color: #666666;
        font-size: 10px;
        line-height: 170%;
      }
    }
  }
}

.checkbox-box__sm {
  padding: 0;
  border: none;
  background: none;
  font-size: 16px;
  color: #666666;

  .checkmark {
    margin-right: 20px;
  }
}

.btn-next {
  i {
    margin-left: 10px;
    margin-right: 0px;
    transform: rotate(180deg);
  }
}

.checkout-step2 {
  padding-bottom: 10px;

  .promo-video__decor {
    top: 18.5%;
  }
}

.checkout-payment {
  h4 {
    margin-bottom: 15px;
  }

  &__item {
    margin-bottom: 30px;
    border: 1px solid #eeeeee;
    padding: 42px 39px;
    padding-bottom: 36px;

    &-content {
      display: none;
      padding-top: 40px;
      border-top: 1px solid #eeeeee;
      margin-top: 16px;

      .box-field {
        span {
          display: block;
          margin-bottom: 10px;
          font-size: 14px;
          line-height: 170%;
          color: #666666;
        }

        &__row {
          margin-top: 30px;
          align-items: flex-end;
          margin-left: -15.5px;
          margin-right: -15.5px;

          .box-field {
            margin-left: 15.5px;
            margin-right: 15.5px;
            width: calc(33.3% - 31px);
          }
        }
      }
    }

    &.active {
      background: #faf9ff;
      padding-bottom: 42px;

      .checkout-payment__item-content {
        display: block;
      }
    }
  }

  .checkout-buttons {
    margin-top: 58px;
  }
}

.radio-box {
  position: relative;
  padding-left: 0px;
  cursor: pointer;
  color: #222222;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
  text-transform: capitalize;

  &__info {
    margin-left: 8px;
    position: relative;

    i {
      font-size: 16px;
    }

    &-content {
      position: absolute;
      opacity: 0;
      visibility: hidden;
      transition: $transition;
      padding: 27px 29px;
      padding-left: 30px;
      background: #f2e7d6;
      font-size: 12px;
      line-height: 145%;
      text-transform: initial;
      font-family: 'Lato', sans-serif;
      color: #999999;
      width: 250px;
      left: 43px;
      bottom: 15px;

      &:after {
        position: absolute;
        left: -8px;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #f2e7d6;
        transform: rotate(180deg);
        content: '';
      }
    }

    &:hover {
      .radio-box__info-content {
        opacity: 1;
        visibility: visible;
        z-index: 11;
      }
    }
  }
}

.radio-box input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.radio-box div {
  top: 0px;
  left: 0px;
  width: 25px;
  height: 25px;
  background-color: #fff;
  border-radius: 100%;
  border: 2px solid #999999;
  padding: 0;
  position: relative;
  margin-right: 10px;
}

.radio-box .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.radio-box input:checked~.checkmark:after {
  display: block;
}

.radio-box .checkmark:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #878686;
}

.checkout-purchase {
  h4 {
    margin-bottom: 15px;
  }

  &__list {
    margin-top: 40px;

    li {
      display: flex;
      font-size: 16px;
      line-height: 170%;
      color: #999999;
      padding: 5.3px 29px;
      border: 1px solid #faf9ff;
      background: #fff;

      &:nth-of-type(odd) {
        background: #faf9ff;
      }

      span {
        color: #222222;
        width: 290px;
      }
    }
  }

  &__link {
    margin-top: 60px;
    font-size: 14px;
    line-height: 170%;
    text-transform: uppercase;
    color: #878686;
    display: block;

    &:hover {
      opacity: 0.8;
    }
  }
}

.checkout-step3 {
  .promo-video__decor {
    top: 17.3%;
  }

  .cart-bottom__total .btn {
    margin-top: 62px;
  }

  .checkout-info {
    padding-top: 97px;
  }
}

// login page

.login {
  padding-top: 60px;

  .promo-video__decor {
    top: 17.5%;
  }

  &-form {
    margin: auto;
    // max-width: 570px;
    padding: 60px 100px;
    background-color: #fcf2e8;
    border: 1px solid #eeeeee;

    h3 {
      text-align: center;
      margin-bottom: 6px;
      font-weight: 700;
    }

    &__social {
      display: flex;
      justify-content: center;
      margin-bottom: 29px;

      li {
        margin: 0 5px;

        a {
          display: block;
          width: 45px;
          height: 45px;

          &:hover {
            opacity: 0.7;
          }

          i {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;

            &:before {
              color: #fff;
            }

            &.icon-facebook {
              background: #3b5999;
            }

            &.icon-twitter {
              background: #55acee;
            }

            &.icon-insta {
              background: #e4405f;
            }

            &.icon-google {
              background: #dd4b39;
            }
          }
        }
      }
    }

    .box-field {
      margin-bottom: 10px;
    }

    .checkbox-box {
      margin-top: 35px;
      margin-bottom: 35px;
    }

    .btn {
      // width: 100%;
      text-align: center;
    }

    &__bottom {
      // display: flex;
      justify-content: space-between;
      margin-top: 6px;
      font-size: 15px;
      line-height: 170%;

      span {
        color: #666666;
      }

      a {
        color: #878686;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

.registration {
  .promo-video__decor {
    top: 16.7%;
  }

  .login-form {
    max-width: 900px;
  }

  .box-field__row {
    display: flex;
    margin-left: -5px;
    margin-right: -5px;
    flex-wrap: wrap;

    span {
      margin-top: 20px;
      display: block;
      width: 100%;
      margin-left: 5px;
      font-size: 18px;
      line-height: 120%;
      font-family: "Montserrat", sans-serif;
      text-transform: capitalize;
      margin-bottom: 11px;
      font-weight: 600;
    }

    .box-field {
      margin: 0 5px;
      margin-bottom: 10px;
      width: calc(50% - 10px);
    }
  }

  .login-form .checkbox-box {
    margin-top: 25px;
    margin-bottom: 35px;
  }
}

// 404 page

.error-page {
  padding-top: 148px;

  &__content {
    display: flex;
    justify-content: space-between;
  }

  &__info {
    max-width: 606px;
    width: 100%;
    margin-right: 84px;
    margin-top: 33px;
  }

  &__title {
    font-size: 40px;
    line-height: 100%;
    font-family: 'Montserrat', sans-serif;
    color: #2f3237;
    margin-bottom: 20px;
    text-transform: capitalize;

    span {
      font-size: 150px;
      line-height: 100%;
      margin-right: 23px;
    }
  }

  &__subtitle {
    color: #666666;
    font-size: 16px;
    line-height: 170%;
    margin-bottom: 24px;

    a {
      &:hover {
        opacity: 0.8;
      }
    }
  }

  .box-field__row-search {
    .btn {
      padding: 0 22px;
      width: 60px;

      i {
        margin-right: 0;
      }
    }

    .box-field {
      width: calc(100% - 60px);
    }
  }
}

.error-descr {
  margin-top: 8px;
  display: block;
  font-size: 16px;
  line-height: 170%;
  color: #666666;
}

input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

// profile

.profile {
  padding-top: 180px;
  padding-bottom: 50px;
  position: relative;

  &-content {
    display: flex;
    justify-content: space-between;
  }

  &-aside {
    width: 360px;

    &__subscribe {
      background: #f2e7d6;
      width: 100%;
      padding: 40px 40px 48px;
      position: relative;
      z-index: 1;

      h3 {
        margin-bottom: 30px;
        text-align: center;
      }

      .box-field {
        margin-bottom: 10px;
      }

      .btn {
        width: 100%;
      }

      img {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: -1;
        width: 77%;
      }
    }

    &__viewed {
      margin-top: 55px;

      h5 {
        padding-bottom: 10px;
        border-bottom: 2px solid #222222;
      }

      &-item {
        margin-top: 28px;
        display: flex;
        align-items: center;

        &-img {
          width: 60px;
          height: 72px;
          margin-right: 20px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &-title {
          font-size: 20px;
          line-height: 150%;
          display: block;
          font-family: 'Montserrat', sans-serif;
          margin-bottom: 5px;
          color: #222222;

          &:hover {
            color: #878686;
          }
        }

        &-price {
          font-size: 18px;
          line-height: 100%;
          color: #999999;
        }
      }
    }

    &__discount {
      margin-top: 49px;
      padding: 62px 40px 40px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      &-title {
        font-size: 40px;
        line-height: 110%;
        color: #222222;
        text-align: right;
        font-family: 'Montserrat', sans-serif;
        margin-bottom: 38px;

        span {
          font-size: 60px;
          color: #878686;
          position: relative;
          display: inline-block;
          margin-top: 16px;

          &:before {
            content: '';
            position: absolute;
            left: 65%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 87px;
            height: 75px;
            background-image: url(../../../public/assets/img/main-text-decor.png);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            transition: all 0.3s linear;
          }
        }
      }

      .btn {
        width: 100%;
        text-align: center;
      }
    }
  }

  &-main {
    width: calc(100% - 420px);

    .nav-tab-list li {
      margin-right: 15px;
    }
  }

  &-orders {
    &__row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-head {
        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        line-height: 120%;
        text-transform: capitalize;
        color: #222222;
        border-bottom: 2px solid #222222;
        padding-bottom: 10px;
        margin-bottom: 30px;

        .profile-orders__col {
          &:nth-of-type(2) {
            padding-left: 23px;
          }

          &:nth-of-type(3) {
            padding-left: 12px;
          }

          &:nth-of-type(4) {
            padding-left: 35px;
          }
        }
      }
    }

    &__col {
      padding-left: 30px;

      &:nth-of-type(1) {
        width: 111px;
      }

      &:nth-of-type(2) {
        width: 294px;
      }

      &:nth-of-type(3) {
        text-align: center;
        padding: 0;
        width: 120px;
      }

      &:nth-of-type(4) {
        width: 195px;
      }
    }

    &__item {
      border: 1px solid #eeeeee;
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }

      .profile-orders__row {
        padding: 17px 0;
        padding-right: 0;
        transition: $transition;
        background-color: #fff;
      }

      &-date {
        color: #999999;
        font-size: 14px;
        line-height: 170%;
      }

      &-addr {
        color: #666666;
        font-size: 14px;
        line-height: 170%;
      }

      &-price {
        font-size: 16px;
        line-height: 170%;
        color: #666666;
      }

      .profile-orders__col {
        padding: 0 30px;
        position: relative;

        &:after {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          content: '';
          height: 35px;
          width: 1px;
          background-color: #eeeeee;
        }

        &:last-of-type {
          &:after {
            display: none;
          }
        }

        &:nth-of-type(3) {
          padding: 0;
        }

        &-btn {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: #faf9ff;
          border: 1px solid #eeeeee;
          line-height: 40px;
          text-align: center;
          font-size: 14px;
          font-weight: 700;
          position: absolute;
          right: 25px;
          top: 50%;
          transform: translateY(-50%);
          z-index: 1;
          cursor: pointer;
          transition: $transition;

          &:hover {
            opacity: 0.8;
          }

          &:before,
          &:after {
            content: '';
            position: absolute;
            background: #222222;
          }

          &:before {
            left: 50%;
            top: 50%;
            width: 2px;
            height: 8px;
            transform: translate(-50%, -50%);
          }

          &:after {
            top: 50%;
            left: 50%;
            height: 2px;
            width: 8px;
            transform: translate(-50%, -50%);
          }
        }

        &-onway {
          font-size: 12px;
          line-height: 170%;
          text-transform: uppercase;
          color: #878686;
        }

        &-delivered {
          font-size: 12px;
          line-height: 170%;
          text-transform: uppercase;
          color: #12a33b;
        }
      }

      &.active {
        .profile-orders__content {
          display: block;
        }

        .profile-orders__row {
          background: #faf9ff;
        }

        .profile-orders__col-btn {
          background: #f2e7d6;
          border-color: #f2e7d6;

          &:before {
            display: none;
          }

          &:after {
            background: #878686;
          }
        }
      }
    }

    &__content {
      display: none;

      ul {
        li {
          border-top: 1px solid #eeeeee;
          font-size: 14px;
          line-height: 170%;
          justify-content: space-between;
          display: flex;
          align-items: center;
          color: #666666;
          padding: 8px 30px;
          background-color: #fff;

          &:nth-of-type(even) {
            background-color: #faf9ff;
          }

          &:last-of-type {
            font-family: 'Montserrat', sans-serif;
            font-size: 20px;
            line-height: 120%;
            text-transform: capitalize;
            color: #222222;
            padding: 26px 29px;
            padding-right: 36px;

            span {
              font-size: 16px;
              line-height: 170%;
              font-family: 'Lato';
              color: #666666;
              text-transform: initial;
            }
          }
        }
      }
    }
  }
}

.profile-orders__col-mob {
  display: none;
}

.links-page {
  padding-top: 130px;

  li {
    margin-bottom: 10px;
  }
}

.main-block {
  // min-height: 100vh;
  // display: flex;
  // align-items: center;
  // justify-content: flex-start;
  // width: 100%;
  // height: 100%;
  // padding-top: 110px;
  // padding-bottom: 90px;

  .wrapper {
    width: 100%;
  }
}

.react-dropdown {
  .Dropdown-arrow {
    top: 21px;
    right: 18px;
  }

  .Dropdown-control {
    background-color: #faf9ff;
    border: 1px solid #eeeeee !important;
    color: #666666;
    cursor: pointer;
    outline: none;
    padding: 14px 45px 12px 40px;
    font-size: 15px;
  }

  .Dropdown-menu {
    background-color: white;
    border: 1px solid #eeeeee !important;
    box-shadow: none;
  }

  .Dropdown-option {
    font-size: 15px;
    padding: 10px 30px;

    &.is-selected {
      background-color: #fff;
      color: #878686;
    }

    &:hover {
      background-color: #222222;
      color: #fff;
    }
  }
}

.experience {
  background-image: url('../../../public/assets/img/counterbg.png');
  padding: 120px 210px;
  background-position: center left;
  background-repeat: no-repeat;
  // background-size: contain;
  // margin-top: 60px;
}

.experience .row {
  display: flex;
}

.experience .column {
  flex: 50%;
}

.experience h1 {
  margin-bottom: 30px;
}

.experience p {
  font-size: 20px;
  line-height: 18px;
  color: #000000;
  font-weight: 400;
  line-height: 170%;
  margin-bottom: 10px;
}

.info-blocks__item-text h2 {
  max-width: 546px !important;
}

.info-blocks-video .info-blocks__item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-left: 0vw;
  z-index: 9;
}

.info-blocks__item-reverse .info-blocks__item-text {
  padding: 237px 10px 223px 270px;
}

.img-sec {
  margin: 15px 0 0px 0;
}

.img-sec .row {
  display: flex;
}

.img-sec .column {
  flex: 50%;
}

.img-sec .img-col-1 {
  // background-image: url('../../../public/assets/img/m.jfif');
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.44), rgba(0, 0, 0, 0.244)), url('../../../public/assets/img/m.jfif');
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 15px;
  padding: 25vh 0;
}

.img-sec .img-col-2 {
  background-image: url('../../../public/assets/img/bg4.jpg');
  text-align: center;
  margin-right: 15px;
  padding: 14vh 0;
}

.img-sec .img-col-2 h2 {
  margin-bottom: 30px;
  font-family: 'Montserrat', sans-serif;
  font-size: 77px;
}

.img-sec .img-col-2 a {
  margin-top: 30px;
}

.img-sec .img-col-3 {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.44), rgba(0, 0, 0, 0.244)), url('../../../public/assets/img/m1.jfif');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 25vh 0;
}

#countdown li {
  display: inline-block;
  font-size: 1em;
  list-style-type: none;
  padding: 1em;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  margin-right: 20px;
}

#countdown li span {
  display: block;
  font-size: 2rem;
  background-color: #fff;
  padding: 20px 10px;
  text-align: center;
  width: 80px;
  border: 1px solid #000;
  margin-bottom: 10px;
}

.img-sec h3 {
  color: #fff;
  text-align: center;
  font-size: 36px;
}

.img-sec img {
  text-align: center;
}

.img-sec .img {
  display: flex;
  justify-content: center;
}

// .social-overlay {
//   position: absolute;
//   top: 508.4vh;
//   bottom: 0;
//   left: 0px;
//   right: 0px;
//   height: 77.5%;
//   width: 50%;
//   opacity: 0;
//   -webkit-transition: 0.8s ease;
//   transition: 0.8s ease;
//   background-color: #0000006b;
//   display: -webkit-flex;
//   display: flex;
//   -webkit-justify-content: center;
//   justify-content: center;
//   -webkit-align-items: center;
//   align-items: center;
// }

// .img-col-1:hover .social-overlay {
//   opacity: 1;
// }
#root,
#root2 {
  width: 100vw;
  height: 95vh;
}

.carousel {
  display: flex;
  overflow: hidden;
  height: 100%;
}

.carousel-prev,
.carousel-next {
  position: absolute;
  cursor: pointer;
  z-index: 1;
  height: 100%;
  width: 100px;
}

.carousel-prev {
  left: 0;
}

.carousel-next {
  right: 0;
}

.carousel-item {
  position: relative;
  display: inline-block;
  flex: 0;
  width: 100%;
  height: 100%;
  // text-align: center;
  overflow: hidden;

  transition: flex 1s ease;

  img {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.current {
    flex: 1;
  }
}

.carousel-heading h2 {
  font-size: 90px;
  margin-bottom: 40px;
}

.carousel-heading h3 {
  margin-left: 8px;
  margin-bottom: 30px;
}

.carousel-heading p {
  margin-left: 8px;
  width: 60%;
  font-size: 18px;
}

.carousel-heading {
  display: inline-block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding: 180px;

  h2,
  h3 {
    padding: 0 0px;
  }
}

@media (min-width: 768px) {
  .carousel-prev::before {
    position: relative;
    top: 50%;
    left: 20px;
    content: '';
    display: inline-block;
    width: 3em;
    height: 3em;
    border-right: .7em solid white;
    border-top: .7em solid white;
    transform: rotate(225deg);
  }

  .carousel-next::before {
    position: relative;
    top: 50%;
    right: -20px;
    content: '';
    display: inline-block;
    width: 3em;
    height: 3em;
    border-right: .7em solid white;
    border-top: .7em solid white;
    transform: rotate(45deg);
  }
}


// .info-blocks-video2 

.info-blocks-video2 .info-blocks__item-text {
  padding: 237px 10px 223px 30px;
}

.advantages .wrapper {
  min-width: 320px;
  max-width: 1530px;
  padding: 0 40px 0 40px;
  margin: 0 auto;
  position: relative;
}

.advantages-item {
  margin: 0 25px;
  width: -webkit-calc(33.3% - 90px);
  width: calc(33.3% - 90px);
  text-align: center;
}

.advantages h4 {
  font-size: 31px;
}

.how-works-sec {
  padding: 100px 0;
  background-color: #fcf7f5;
}

.how-works-sec .row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.how-works-sec .how-head .col {
  flex: 100%;
  text-align: center;
}

.how-works-sec .how-head {
  margin-bottom: 60px;
}

.how-works-sec .how-head p {
  padding: 20px 30px;
}

.how-works-sec .how-cont {
  margin-bottom: 50px;
}

.how-works-sec .how-cont .col {
  flex: 50%;
  text-align: left;
}

.how-works-sec .how-cont h6 {
  margin-bottom: 15px;
  color: #878686;
}

.how-works-sec .how-cont h3 {
  margin-bottom: 15px;
  font-size: 31px;
  font-weight: 600;
  padding-left: 25px;
  line-height: 40px;
}

.how-works-sec a {
  color: #000000;
  text-decoration: underline;
  font-weight: 600;
}

.how-works-sec .how-cont p {
  margin-bottom: 15px;
  padding: 0 30px;
}

.how-works-sec .how-cont .start-price {
  color: #000;
  font-size: 18px;
}

.desire-sec {
  padding-top: 150px;
}

.desire-sec .row {
  display: flex;
}

.desire-sec .des-head .col {
  flex: 100%;
  text-align: center;
}

.desire-sec .des-head p {
  padding: 20px 650px;
}

.desire-sec .des-main .col {
  flex: 16.66%;
  text-align: center;
}

.desire-sec .des-main {
  padding-top: 60px;
}

.desire-sec .des-main h3 {
  font-size: 36px;
}

.promise-sec {
  background-color: #303030;
}

.promise-sec .container {
  padding: 90px 180px;
}

.promise-sec h2 {
  color: #fff;
  margin-bottom: 15px;
}

.promise-sec p {
  color: #fff;
}

.promise-sec .promise-head p {
  margin-bottom: 10px;
  padding: 0 120px 0 0;
}

.promise-main {
  margin-top: 60px;
}

.promise-main h5 {
  color: #fff;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 6px;
}

.promise-main .row {
  display: flex;
  margin-bottom: 36px;
}

.promise-main p {
  padding-right: 270px;
}

.promise-main .col1 {
  flex: 13%;
  width: 30%;
  padding-left: 90px;
}

.promise-main .col2 {
  flex: 77%;
  width: 70%;
  padding-top: 30px;
}

.promise-main img {
  border-radius: 100%;
}

.responsible-sec {
  padding: 120px 120px;
  background-image: url('../../../public/assets/img/corporatebg.png');
  background-size: cover;
}

.responsible-sec h2 {
  margin-bottom: 20px;
  font-size: 44px;
}

.responsible-sec p {
  margin-bottom: 16px;
  font-weight: 600;
}

.responsible-sec .row {
  display: flex;
}

.responsible-sec .col1 {
  flex: 40%;
  width: 40%;
}

.responsible-sec .col2 {
  flex: 60%;
  width: 60%;
}

.sustain-sec {
  padding: 80px 120px;
  background-image: url('../../../public/assets/img/sustainbg.png');
}

.sustain-sec h2 {
  margin-bottom: 20px;
  font-size: 44px;
}

.sustain-sec p {
  margin-bottom: 16px;
}

.sustain-sec .row {
  display: flex;
}

.sustain-sec .col2 {
  flex: 40%;
  width: 40%;
}

.sustain-sec .col1 {
  flex: 45%;
  width: 60%;
}

.corporate-sec {
  padding: 90px 120px;
  background-image: url('../../../public/assets/img/responsiblebg.png');
  background-size: cover;
}

.corporate-sec h2 {
  margin-bottom: 20px;
  font-size: 44px;
}

.corporate-sec p {
  margin-bottom: 16px;
}

.corporate-sec .row {
  display: flex;
}

.corporate-sec .col2 {
  flex: 40%;
  width: 40%;
}

.corporate-sec .col1 {
  flex: 45%;
  width: 60%;
}



// Carousel slides
.carousel__slide {
  margin-right: auto;
  margin-left: auto;
  display: none;
  max-width: 900px;
  list-style-type: none;

  @media (max-width: 991px) {
    padding-right: 60px;
    padding-left: 60px;
  }

  &--active {
    display: block;
  }
}

// Content of slides


.carousel-slide__author,
.carousel-slide__source {
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14px;

}

.carousel-slide__source {
  font-style: italic;
  color: #888;
}

// Carousel arrows
.carousel__arrow {
  position: absolute;
  top: 50%;
  display: block;
  color: #111;
  cursor: pointer;
  opacity: .75;
  transform: translateY(-50%);
  transition: opacity .15s cubic-bezier(.4, 0, 1, 1);

  &:focus {
    outline: 0;
  }

  &:hover {
    opacity: .5;
  }

  &--left {
    left: 32px;
  }

  &--right {
    right: 32px;
  }
}

// Carousel indicators
.carousel__indicators {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  align-items: flex-end;
  transform: translateX(-735px);

  li {
    &:nth-of-type(n + 2) {
      margin-left: 9px;
    }
  }
}


.carousel__slides {
  margin-bottom: 17px;
}

.carousel__indicator {
  display: block;
  width: 24px;
  height: 3px;
  background-color: #111;
  cursor: pointer;
  opacity: .15;
  transition: opacity .15s cubic-bezier(.4, 0, 1, 1);

  &:hover {
    opacity: .5;
  }

  &--active {

    &,
    &:hover {
      opacity: .75;
    }
  }
}

.carousel__indicator {
  display: block;
  width: 10px;
  height: 9px;
  background-color: #111;
  cursor: pointer;
  border-radius: 90%;
  opacity: 0.15;
  -webkit-transition: opacity 0.15s cubic-bezier(0.4, 0, 1, 1);
  transition: opacity 0.15s cubic-bezier(0.4, 0, 1, 1);
}

.carousel-slide__content {
  margin-bottom: 19px;
  font-family: "Open Sans", "Trebuchet MS", sans-serif;
  font-size: 23px;
}

.promise-sec .promise-main .col-car {
  padding: 70px 45px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 10px 40px;
  margin-top: 15px;
}


.info-blocks-video3 .info-blocks__item-text {
  padding: 237px 0px 223px 0px;
}

.info-blocks-video3 .info-blocks__item-descr {
  font-size: 17px;
  line-height: 150%;
  color: #1c1c1c;
  display: block;
  max-width: 656px;
  font-family: "Montserrat";
  margin-top: 31px;
}

.info-blocks-video3 .info-blocks__item-text p {
  display: block;
  font-size: 16px;
  line-height: 170%;
  color: #000000;
  margin-top: 7px;
}

.info-blocks-video3 .info-blocks__item-text p {
  max-width: 700px;
}

.video2-dets {
  margin-top: 30px;
}

.two .row {
  display: flex;
}

.two .col {
  flex: 50%;
}

.two .col2 {
  background-image: url('../../../public/assets/img/bg8.jpg');
  padding: 220px 100px;
}

.two .info-blocks__item-descr {
  font-size: 20px;
  line-height: 150%;
  color: #666666;
  display: block;
  max-width: 596px;
  font-family: "Montserrat";
  margin-top: 31px;
}

.two a {
  margin-top: 50px;
}

.desire-two-sec {
  padding-top: 180px;
  margin-bottom: 0px;
  background-image: url(../../../public/assets/img/bg1.jpg);
  background-repeat: no-repeat;
}

.desire-two-sec .row {
  display: flex;
}

.desire-two-sec .des-two-head .col {
  flex: 100%;
  text-align: center;
}

.desire-two-sec .des-two-head p {
  padding: 15px 460px;
}

.des-two-main {
  margin-top: 60px;
}

.desire-two-sec .des-two-main .col {
  flex: 45%;
  padding: 170px 0;
  margin-bottom: 20px;
}

.desire-two-sec .des-two-main img {
  width: 19%;
}

.desire-two-sec .des-two-main h2 {
  font-weight: 800;
  color: #fff;
  padding-top: 15px;
}

.des-two-main-one {
  margin-right: 20px;
  // background: linear-gradient(45deg, #00000091, #00000091), url(../../../public/assets/img/des1.png);
  text-align: center;
  background-size: cover;
}

.des-two-main-two {
  background: linear-gradient(45deg, #00000091, #00000091), url(../../../public/assets/img/des2.png);
  text-align: center;
  background-size: cover;
}

.des-two-main-three {
  background: linear-gradient(45deg, #00000091, #00000091), url(../../../public/assets/img/des3.png);
  text-align: center;
  background-size: cover;
  margin-right: 20px;
}


.des-two-main-four {
  background: linear-gradient(45deg, #00000091, #00000091), url(../../../public/assets/img/des4.png);
  text-align: center;
  background-size: cover;
}



.aboutsec .container {
  padding: 120px 220px;
}

.aboutsec .row {
  display: flex;
}

.aboutsec .col {
  flex: 50%;
}

.aboutsec img {
  width: 100%;
}

.aboutsec p {
  font-size: 20px;
}

.aboutsec h2 {
  margin-bottom: 15px;
  margin-top: 10px;
  line-height: 65px;
}

.aboutsec .corner {
  border-left: 4px solid #878686;
  padding: 0 20px;
  margin: 20px 0;
}

.aboutsec .count {
  padding: 25px 0;
}

.aboutsec .count .row {
  display: flex;
  justify-content: center;
}

.aboutsec .count .col {
  flex: 50%;
}

.aboutsec .count h3 {
  color: #878686;
}

.header-content {
  position: relative;
}

.header-nav li ul {
  width: 100vw;
  left: 520px;
}

.header-nav li ul li {
  padding: 0;
  margin: 0;
  display: inline-block;
}

.header-nav li:hover ul {
  opacity: 1;
  visibility: visible;
  display: flex;
  justify-content: space-around;
}

.header-nav li ul li a {
  text-transform: none;
  text-transform: initial;
  font-size: 16px;
  line-height: 170%;
  color: #000000;
  padding: 7.6px 40px;
  font-weight: 600;
  display: block;
  // background-color: #f2e7d6;
  background: linear-gradient(45deg, #f4eadc 38%, #e7d5c4);
}

.img-col-2 p {
  padding: 0 30px;
  margin-top: 15px;
}

.des-two-main .col {
  height: 659px;
}

.des-two-main .cont1 {
  display: none;
  transition: display 2s ease-out;
}

.des-two-main .des-two-main-one:hover .cont1 {
  display: block;
}

.des-two-main .cont2 {
  display: none;
  transition: display 2s ease-out;
}

.des-two-main .des-two-main-two:hover .cont2 {
  display: block;
}

.des-two-main .cont3 {
  display: none;
  transition: display 2s ease-out;
}

.des-two-main .des-two-main-three:hover .cont3 {
  display: block;
}

.des-two-main .cont4 {
  display: none;
  transition: display 2s ease-out;
}

.des-two-main .des-two-main-four:hover .cont4 {
  display: block;
}

#root2 .carousel-item:nth-child(4) h2 {
  color: #fff !important;
}

#root2 .carousel-item:nth-child(4) p {
  color: #fff !important;
}

#root2 .carousel-item p {
  font-weight: 600;
}

.discount {
  padding: 120px 0;
}

.des-main .col:hover img {
  transform: scale(1.2);
}

.des-main .col img {
  transition: all .2s ease-in-out;
}

.disc-video {
  padding: 20px 0;
}

.cbd {
  margin-left: -20px;
}

#main-banner .container {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 30px;

}

#main-banner .row {
  display: flex;
  margin-right: -3px;
}

#main-banner .col1 {
  background-image: url(../../../public/assets/img/bannerbg1.png);
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 0px;
  padding: 150px 70px;
  border-radius: 0px;
  flex: 50% 1;
  background-position-x: right;
}

#main-banner .col2 {
  background-image: url(../../../public/assets/img/bannerbg2.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 150px 70px;
  border-radius: 0px;
  flex: 50%;
}

#main-banner .display-4 {
  margin-bottom: 10px;
  letter-spacing: 5px;
}

#main-banner p {
  margin-bottom: 20px;
  color: #000;
  width: 75%;
}

#root2 a {
  margin-top: 30px;
}

body {
  overflow-x: hidden;
}

.main-logos {
  padding-bottom: 90px;
}

.discount-about .discount-info__sm {
  width: 150%;
}

.mission-sec {
  margin-top: 0px;
  background-image: url(../../../public/assets/img/mission.png);
  background-repeat: no-repeat;
  background-color: #FFECE7;
  background-size: cover;
  padding: 100px 0;
}

.mission-sec h2 {
  padding: 10px 180px;
}

.mission-sec p {
  padding: 20px 180px 0px 180px;
  width: 48%;
  font-size: 18px;
}

.mission-sec .quote {
  font-weight: 600;
  color: black;
  margin-bottom: 30px;
}

.develop-sec {
  margin: 120px 0 0px 0;
}

.develop-sec .row {
  display: flex;
}

.develop-sec .column {
  flex: 50%;
}

.develop-sec .img-col-1 {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.44), rgba(0, 0, 0, 0.44)), url('../../../public/assets/img/privatelabel.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 15px;
  padding: 25vh 0;
}

.develop-sec .img-col-2 {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.44), rgba(0, 0, 0, 0.44)), url('../../../public/assets/img/contractmanufacture.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 15px;
  padding: 25vh 0;
}

.develop-sec .img-col-3 {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.44), rgba(0, 0, 0, 0.44)), url('../../../public/assets/img/productdevelopment.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 0px;
  padding: 25vh 0;
}

.develop-sec .img-col-2 h2 {
  margin-bottom: 30px;
  font-family: 'Montserrat', sans-serif;
  font-size: 77px;
}

.develop-sec .img-col-2 a {
  margin-top: 30px;
}

.develop-sec h3 {
  color: #fff;
  text-align: center;
  font-size: 36px;
}

.develop-sec img {
  text-align: center;
}

.develop-sec .img {
  display: flex;
  justify-content: center;
}

.column {
  position: relative;
  width: 50%;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f2e7d6 25;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .5s ease;
}

.develop-sec .column:hover .overlay {
  height: 100%;
}

.develop-sec .column:hover .img {
  display: none;
}

.develop-sec .column:hover h3 {
  display: none;
}

.develop-sec .row-1 .col {
  flex: 100%;
  text-align: center;
  margin-bottom: 30px;
}

.develop-sec .row-1 p {
  padding: 15px 550px;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  width: 80%;
  line-height: 1.3;
}

.certs {
  padding-top: 40px;
  padding-bottom: 30px;
}

.about-profile {
  margin-bottom: 30px;
}

#search {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #eddfcdf5;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform: translate(0px, -100%) scale(0, 0);
  -moz-transform: translate(0px, -100%) scale(0, 0);
  -ms-transform: translate(0px, -100%) scale(0, 0);
  -o-transform: translate(0px, -100%) scale(0, 0);
  transform: translate(0px, -100%) scale(0, 0);
  opacity: 0;
  display: none;
}

#search.open {
  -webkit-transform: translate(0px, 0px) scale(1, 1);
  -moz-transform: translate(0px, 0px) scale(1, 1);
  -ms-transform: translate(0px, 0px) scale(1, 1);
  -o-transform: translate(0px, 0px) scale(1, 1);
  transform: translate(0px, 0px) scale(1, 1);
  opacity: 1;
  z-index: 106;
  display: block;
}

#search input[type="search"] {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -51px;
  width: 60%;
  margin-left: 20%;
  color: #878686;
  background: transparent;
  border-top: 2px solid #878686;
  border-bottom: 2px solid #878686;
  border-left: 0px solid transparent;
  border-right: 0px solid transparent;
  font-size: 40px;
  font-family: Roboto;
  font-weight: 300;
  text-align: center;
  outline: none;
  padding: 10px;
}

#search .close {
  position: fixed;
  top: 15px;
  right: 15px;
  opacity: 1;
  font-size: 27px;
  color: #878686;
}

#search .close:hover {
  color: #fc2121;
  cursor: pointer;
}

#search ::placeholder {
  color: #878686;
  opacity: 1;
}

.login .promo-video__decor {
  position: absolute;
  left: -13px;
  top: 3.5%;
  width: 23%;
  z-index: -1;
}

.post .promo-video__decor {
  position: absolute;
  left: -13px;
  top: 40%;
  width: 16%;
  z-index: -1;
}

.custom-info {
  padding: 150px 200px;
  padding-left: 60px;
  background-image: url(../../../public/assets/img/productsbg.jpeg);
  background-repeat: no-repeat;
  background-position-x: 83vw;
  background-size: 430px;
  background-position-y: 190px;
}

.custom-info .row {
  display: flex;
}

.custom-info .column {
  flex: 50%;
}

.custom-info .col-one {
  width: 100%;
  flex: 30%;
}

.custom-info p {
  font-size: 17px;
  margin-bottom: 15px;
  width: 65%;
}

.custom-info h2 {
  margin-bottom: 25px;
}

.custom-slide .row {
  display: flex;
}

.custom-slide {
  padding-bottom: 180px;
}

.custom-slide .column {
  flex: 33.33%;
}

.custom-slide .row-head {
  text-align: center;
  display: flex;
  margin-bottom: 50px;
}

.custom-slide .col-1 {
  background-image: url(../../../public/assets/img/order.png);
  text-align: center;
  margin-right: 10px;
  background-size: cover;
  height: 500px;
  display: flex;
  align-items: flex-end;
}

.custom-slide .col-2 {
  background-image: url(../../../public/assets/img/minimum.png);
  text-align: center;
  margin-right: 10px;
  background-size: cover;
  height: 500px;
  display: flex;
  align-items: flex-end;
}

.custom-slide .col-3 {
  background-image: url(../../../public/assets/img/cost.png);
  text-align: center;
  margin-right: 10px;
  background-size: cover;
  height: 500px;
  display: flex;
  align-items: flex-end;
}

.custom-slide .bottom {
  background-color: #878686;
  width: 100%;
  padding: 15px 20px;
}

.custom-slide .bottom h4 {
  display: inline;
  color: #fff;
}

.custom-slide .bottom img {
  width: 15%;
}


.custom-slide .overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #0000008f;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .5s ease;
}

.custom-slide .column:hover .overlay {
  height: 100%;
}

.custom-slide .column:hover .bottom {
  display: none;
}

.custom-faq {
  background-repeat: no-repeat;
  background-position-y: top;
}

.custom-faq .row {
  display: flex;
  margin-bottom: 70px;
}

.custom-faq .col {
  flex: 100%;
  text-align: center;
}

.advantage-head {
  text-align: center;
  margin-bottom: 90px;
}

.advantage-head p {
  padding: 15px 280px;
}

.terms {
  padding: 60px 120px;
}

.terms p {
  padding-top: 10px;
}

.terms h4 {
  margin-top: 30px;
}

.contract {
  padding-top: 150px;
}

.contract .wrapper {
  max-width: 1620px;
}

.contract .row-head p {
  padding: 10px 180px;
}


.desire-three-sec {
  padding-top: 180px;
  margin-bottom: 0px;
  background-image: url(../../../public/assets/img/bg1.jpg);
  background-repeat: no-repeat;
}

.desire-three-sec .row {
  display: flex;
}

.desire-three-sec .des-three-head .col {
  flex: 100%;
  text-align: center;
}

.desire-three-sec .des-three-head p {
  padding: 15px 460px;
}

.des-three-main {
  margin-top: 60px;
}

.desire-three-sec .des-three-main .col {
  flex: 45%;
  padding: 250px 0;
  margin-bottom: 20px;
}

.desire-three-sec .des-three-main img {
  width: 19%;
}

.desire-three-sec .des-three-main h2 {
  font-weight: 800;
  color: #fff;
  padding-top: 15px;
}

.des-three-main-one {
  margin-right: 20px;
  // background: linear-gradient(45deg, #00000091, #00000091), url(../../../public/assets/img/des1.png);
  text-align: center;
  background-size: cover;
}

.des-three-main-three {
  background: linear-gradient(45deg, #00000091, #00000091), url(../../../public/assets/img/des2.png);
  text-align: center;
  background-size: cover;
}

.des-three-main-three {
  background: linear-gradient(45deg, #00000091, #00000091), url(../../../public/assets/img/des3.png);
  text-align: center;
  background-size: cover;
  margin-right: 20px;
}


.des-three-main-four {
  background: linear-gradient(45deg, #00000091, #00000091), url(../../../public/assets/img/des4.png);
  text-align: center;
  background-size: cover;
}

.desire-three-sec .overlay {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0);
  width: 100%;
  height: 0;
  transition: .5s ease;
}

.desire-three-sec .overlay p {
  color: #fff;
  padding: 0 30px;
}

.desire-three-sec .col:hover .overlay {
  height: 100%;
}

.desire-three-sec .col:hover h2 {
  display: none;
}

.advantages .head {
  text-align: center;
  margin-bottom: 90px;
}

.advantages .head p {
  padding: 15px 0;
}

.exp-prod .how-head p {
  padding: 15px 170px;
}

.header2 .header-content {
  background-color: rgba(0, 0, 0, 0) !important;
}

#email {
  color: #878686;
  margin-left: 0;
}

.icon-natural:hover {
  transform: scale(1.2);
}

#hours p,
#seconds p,
#minutes p {
  display: none;
}


.facility {
  background-image: url(../../../public/assets/img/facility.jpg);
  padding: 80px 00px;
  // margin-bottom: 90px;
}

.facility .row {
  display: flex;
}

.facility .col {
  flex: 50%;
}

.facility h2 {
  padding: 7px 0;
}

.facility p {
  padding-bottom: 20px;
  font-size: 17px;
}

.facility .fac-col-1 {
  margin-top: 60px;
  padding: 0 500px;
}

.turnkey h2 {
  max-width: 705px !important;
}

.turnkey .info-blocks__item-text {
  padding: 100px 0px 70px 0px;
}

.img-sec a {
  margin-right: 5px;
}

.help {
  background-color: #f2e7d6;
  padding: 90px 0px;
  margin-bottom: 0px;
}

.help .row {
  text-align: center;
}

.help p {
  padding: 10px 530px;
  margin-bottom: 10px;
}

.booking {
  background-color: #e8e7e6;
  margin-bottom: 0;
  margin-top: 0;
}


.booking .discount-info {
  max-width: 837px;
  margin-right: 82px;
}

.booking .main-text {
  font-size: 46px;
  margin-bottom: 35px;
}

.private-faq {
  padding-top: 90px;
  padding-bottom: 90px;
}

.header2 .fixed {
  background-color: #f2e7d6 !important;
}

// .started {
//   margin: 60px 0;
// }

.aboutsec .container {
  padding-bottom: 30px;
}

#main-banner .banner-cont {
  background-color: #ffffff96;
  text-align: center;
  padding: 60px 0px;
  width: 720px;
  margin-left: 45px;
}

#main-banner .saint-text {
  font-family: "Montserrat";
  // font-style: italic;
  color: #b3724f;
  display: block;
  font-size: 28px;
  font-weight: 600;
  text-transform: capitalize;
  padding-bottom: 10px;
}



.facility .row {
  background-image: url('../../../public/assets/img/facility.png');
  padding: 120px 0;
  background-size: cover;
}

.fac-cont {
  background-color: #ffffffcb;
  text-align: center;
  padding: 70px 160px;
}

.know-more {
  padding: 0 90px;
  padding-top: 60px;
}

.know-more .row {
  display: flex;
  align-items: center;
}

.know-more .col1 {
  flex: 50%;
  padding-top: 25px;
}

.know-more .col2 {
  flex: 50%;
}

.know-more h2 {
  padding-bottom: 30px;
}

.know-more p {
  width: 82%;
  padding-bottom: 30px;
  font-size: 17px;
}

.commitment .row {
  display: flex;
}

.commitment .col1 {
  flex: 75%;
  background-image: url(../../../public/assets/img/commit.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  padding: 120px 190px;
}

.commitment .col2 {
  flex: 25%;
  background-image: url(../../../public/assets/img/commit2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.commitment a {
  margin-bottom: 20px;
}

.commitment .cont {
  padding: 0 190px;
}

.commitment .cont2 {
  padding-bottom: 40px;
}

.commitment h2 {
  padding-bottom: 30px;
}

#main-banner h1 {
  line-height: 78px;
  font-weight: 800;
  letter-spacing: 5px;
  font-size: 77px;
  margin-bottom: 18px;
  // margin-top: 220px;
}

.corporate .info-blocks__item-text {
  padding: 117px 0px 143px 0px;
}

.overlay .text p {
  color: #fff;
  text-align: left;
  font-size: 15px;
  // padding-top: 5px;
}

.develop-sec .overlay .text {
  font-size: 15px;
}

.help-cont {
  margin-top: 60px;
  margin-bottom: 30px;
}

.promo-video p {
  max-width: 1000px;
}

.subscribe .small {
  font-size: 13px;
}

.hlXQaj .buttons button {
  background: #00000000;
  border: none;
  width: 60px !important;
  height: 60px !important;
  cursor: pointer;
}

.carousel-root .carousel {
  justify-content: center;
}

.carousel .slide img {
  width: 40% !important;
  vertical-align: top;
  border: 0;
}

.trending-top {
  text-align: center;
  margin-bottom: 0px;
}

.points p {
  margin-top: 10px !important;
}

.turnkey {
  margin-bottom: 30px;
}

.info-blocks-2 .info-blocks__item-text {
  padding: 87px 10px 193px 30px;
}

.info-blocks-2 h5 {
  margin-top: 30px;
}

.our-location {
  margin-top: 20px;
}

.discount-contacts .wrapper {
  min-width: 320px;
  max-width: 1580px;
  padding: 0 40px 0 40px;
  margin: 0 auto;
  position: relative;
}

.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  &+label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  // Box.
  &+label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
  }

  // Box focus
  &:focus+label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  // Box checked
  &:checked+label:before {
    background: #000000;
  }

  // Disabled state label.
  &:disabled+label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled+label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked+label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow:
      2px 0 0 white,
      4px 0 0 white,
      4px -2px 0 white,
      4px -4px 0 white,
      4px -6px 0 white,
      4px -8px 0 white;
    transform: rotate(45deg);
  }
}

.check {
  display: inline-block;
  width: 50%;
  /* margin-right: 99px; */
  line-height: 30px;
}

.check2 {
  margin-right: 0;
  margin-left: 0px;
}

.box-field2 {
  width: 100% !important;
}

.again .info-blocks__item-text p {
  max-width: 615px;
}

.again .info-blocks__item-text {
  padding: 107px 10px 203px 30px;
}

.branding a {
  margin-right: 20px;
}

.branding .cont {
  padding: 0 290px;
}

.branding {
  margin-top: 90px;
}

.private-faq .faq-item__content p {
  white-space: pre;
}

.started-sec {
  margin-top: 60px;
  background-image: url(../../../public/assets/img/mission.png) !important;
  background-repeat: no-repeat;
  background-color: #FFECE7;
  background-size: cover;
  text-align: center;
  padding: 120px 0;
}

.started-sec a {
  margin-top: 20px !important;
}

.started-sec p {
  padding: 20px 120px 0px 120px;
}

.private-faq .faq-head p {
  padding: 0 8px;
}

.pagination-outer a,
.pagination-outer span {
  min-width: 40px;
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  font-size: 16px;
  line-height: 170%;
  color: #666666;
  background: #faf9ff;
  border: 1px solid #eeeeee;
}

.pagination-outer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}

.pagination-outer .current {
  background: #222222;
  border-color: #222222;
  color: #ffffff;
}

.pagination-outer .next,
.pagination-outer .prev {
  min-width: 40px;
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  font-size: 16px;
  line-height: 170%;
  color: #666666;
  background: #faf9ff;
  border: 1px solid #eeeeee;
}

[data-tab-content] {
  display: none;
}

.active[data-tab-content] {
  display: block;
}

body {
  padding: 0;
  margin: 0;
}

.tabs {
  display: flex;
  justify-content: center;
  list-style-type: none;
}

.packaging-div {
  margin-top: 30px;
}

.prod-faq {
  padding-top: 55px;
}

.prod-faq .wrapper {
  padding-left: 0;
}


.prod-faq .faq-item__content {
  padding-top: 0;
  margin-top: 30px;
  padding-bottom: 30px;
  padding-left: 5px;
  padding-right: 20px;
  display: none;
  position: relative;
}

.pricing-div {
  margin-bottom: 20px;
}

.over .info-blocks__item-reverse {
  background-color: #fff !important;
}

.over h2 {
  padding-bottom: 30px;
}

.over .points {
  margin-top: 40px;
}

.over p {
  color: #000 !important;
}

.over img {
  margin-top: 40px;
}

.advantages-item__icon {
  margin-bottom: 55px;
  // display: none;
}

[data-aos^=fade][data-aos^=fade] {
  opacity: 1;
  transition-property: opacity, transform;
}


.detail-block__content h1 {
  color: white;

}

.branding {
  background-color: #fcece3;
  padding: 60px 150px;
}

.branding .brand-head p {
  padding-top: 20px;
  color: #000;
}

.brand-main {
  padding-top: 60px;
}

.branding .brand-main .row {
  display: flex;
}

.brand-main .row {
  display: flex;
}

.brand-main .col {
  flex: 30%;
  width: 30%;
  margin-right: 30px;
  padding: 90px 0;
  text-align: center;
}

.started {
  background-color: #333333;
  padding: 110px 0;
}

.started h2 {
  color: #fff;
}

.started h5 {
  color: #b3724f;
  padding-bottom: 20px;
  font-size: 25px;
}

.started {
  text-align: center;
}

.started .btn-two {
  background-color: #808080;
}

.started .row {
  margin-top: 50px;
}

.private-faq {
  background-color: #f2f2f2;
  padding-left: 180px;
  padding-right: 180px;
}

.private-faq .faq-head {
  text-align: left;
}

.private-faq .wrapper {
  min-width: 320px;
  max-width: 1550px;
  padding: 0 40px 0 0;
}

.private-faq h2 {
  margin-bottom: 20px;
}

.private-help {
  background-color: #fff;
}

#product-dev h1,
#contact-bread {
  color: #000 !important;
}

ul.points {
  padding-left: 60px;
}

.points li {
  margin-bottom: 15px;
  list-style-type: disc;
}

.prod-info {
  background-image: none !important;
}

.prod-info .col-one {
  flex: 50% !important;
}

.prod-info p {
  font-size: 17px;
  margin-bottom: 15px;
  width: 95%;
}

.support {
  background-image: url(../../../public/assets/img/supportbg.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 110px 150px;
}

.support .points {
  margin-top: 15px;
}

.support .support-head p {
  margin-top: 20px;
  width: 35%;
}

.support .points li {
  width: 50%;
  line-height: 1.6;
  margin-bottom: 7px;
}

.support .support-main {
  margin-top: 30px;
}

.support-main p {
  font-weight: 600;
}

.started h6 {
  color: #b3724f;
  padding-bottom: 10px;
  font-size: 22px;
}

.started p {
  color: #fff;
  font-size: 19px;
  margin-bottom: 35px;
}

.powder {
  background-image: url(../../../public/assets/img/powder.png);
  padding: 180px 0;
  background-size: cover;
  margin-top: 30px;
  color: #fff;
}

.powder h2 {
  color: #fff;
  margin-bottom: 30px;
}

.powder p {
  color: #fff;
  margin-bottom: 30px;
  padding-right: 250px;
}

.powder .row {
  display: flex;
}

.powder .col {
  flex: 50%;
  width: 50%;
}

.powder #head {
  color: #fff;
  margin-bottom: 15px;
  font-weight: 600;
}

.liquid {
  padding: 60px 0;
}

.liquid .liq-cont {
  padding: 0 120px;
}

.liquid p {
  width: 63%;
}

.liquid #liq-head {
  margin-bottom: 15px;
  font-weight: 600;
}

.liquid #liq-img {
  margin-top: -370px;
}

.product-info__quantity {
  margin-top: 30px;
}

.product-options {
  display: block !important;
}

.slick-prev:before,
.slick-next:before {
  color: #000;
  font-weight: 600;
}

.slick-prev:hover::before,
.slick-next:hover::before {
  color: #fff;
}

.shop-main__select select {
  background-color: #faf9ff;
  border: 1px solid #eeeeee !important;
  color: #666666;
  cursor: pointer;
  outline: none;
  padding: 14px 45px 12px 40px;
  font-size: 15px;
  width: 100%;
  margin-bottom: 10px;
}

.product-content .slick-slide {
  opacity: 1 !important;
}

.qty {
  width: 160px;
  height: 40px;
  background-color: transparent;
  border: 1px solid #a6a5a6;
  outline: none;
  box-shadow: none;
  color: #222222;
  text-align: center;
}



.link-cat {
  color: #000 !important;
  font-weight: 600;
}

.link-cat li span {
  color: grey;
  font-weight: 400;
}

.prods-overview {
  padding: 90px 0 10px 0;
  text-align: center;
}

.prods-overview p {
  padding: 0 470px;
  margin-bottom: 15px;
  color: #000;
  font-weight: 500;
  font-size: 17px;
}

.products-cat {
  padding: 60px 210px;
}

.products-cat h2 {
  color: #fff;
  padding-bottom: 15px;
  font-weight: 600;
  letter-spacing: 7px;
}

.products-cat p {
  font-size: 19px;
}

.products-cat .skin-care {
  background-image: url('../../../public/assets/img/skincarebg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 150px 90px;
  margin-bottom: 60px;
}

.products-cat .cbd-cat {
  background-image: url(../../../public/assets/img/cbdbg.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 166px 90px;
  margin-bottom: 60px;
}

.products-cat .cbd-cat .content,
.products-cat .organic .content {
  margin-left: 40%;
}

.products-cat .otc {
  background-image: url(../../../public/assets/img/otc.png);
  background-repeat: no-repeat;
  padding: 166px 90px;
  margin-bottom: 50px;
  background-size: cover;
}

.products-cat .organic {
  background-image: url(../../../public/assets/img/organic.png);
  background-repeat: no-repeat;
  padding: 182px 90px;
  margin-bottom: 60px;
  background-size: cover;
}

.products-cat .makeup {
  background-image: url(../../../public/assets/img/makeupbg.png);
  background-repeat: no-repeat;
  padding: 135px 90px;
  margin-bottom: 60px;
  background-size: cover;
}

.facility-overview {
  background-image: url(../../../public/assets/img/facility-main.jpg);
  height: 90vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.facility-overview .fac-content {
  width: 25%;
  background-color: #f2f2f2a1;
  margin-left: 5vw;
  text-align: center;
  padding: 60px 30px;
}

.facility-gallery .row {
  display: flex;
}

.facility-gallery .container {
  padding: 90px 120px;
}

.facility-gallery .col {
  flex: 15.66%;
  width: 15.66%;
  margin-right: 15px;
}

.facility-gallery img {
  width: 277px;
  height: 170px;
  object-fit: cover;
}

.capabilities {
  background-color: #f2f2f2;
  padding: 70px 0;
}

.capabilities .container-fluid {
  background-image: url(../../../public/assets/img/facility/capa.jpg);
  background-size: cover;
  padding: 80px 0 80px 180px;
}

.capabilities .capa-content-main {
  background-color: #f2f2f2bb;
  width: 680px;
  text-align: center;
  padding: 100px 30px;
}

.capabilities p#main-capa-cont {
  padding: 0 80px;
  margin-top: 20px;
}

.capabilities .btn-1 {
  padding: 0 75px;
  margin-top: 50px;
}

.capabilities .btn-2 {
  padding: 0 68px;
  margin-top: 50px;
}


.capabilities .btn-3 {
  padding: 0 25px;
  margin-top: 50px;
}

.our-location-capability {
  background-color: #333333;
  padding: 60px 0;
  text-align: center;
}

.our-location-capability h2 {
  color: #fff !important;
  padding-bottom: 20px;
}

.our-location-capability p {
  color: #fff !important;
  padding: 0 0px 60px 0px;
}

.maps {
  background-color: #fcf7f5;
  padding: 70px 60px;
}

.maps .row {
  display: flex;
}

.maps .col {
  flex: 45%;
  width: 45%;
  text-align: center;
}

.maps .col1 {
  margin-right: 30px;
}

.maps p {
  font-weight: 600;
  font-size: 17px;
  margin-top: 15px;
}















.question {
  font-size: 1.1rem;
  font-weight: 600;
  padding: 20px 80px 20px 0px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #cac9c9;
}

// .question::after {
//   content: "-";
//   font-size: 1.4rem;
//   position: absolute;
//   right: 20px;
//   transition: 0.2s;
//   color: #000000;
//   border: 1px solid #eeeeee;
//   height: 40px;
//   width: 40px;
//   border-radius: 50%;
//   padding: 5px;
//   padding-left: 15px;
// }

// .question.active::after {
//   content: "+";
//   font-size: 1.4rem;
//   position: absolute;
//   right: 20px;
//   transition: 0.2s;
//   color: #000000;
//   border: 1px solid #eeeeee;
//   height: 40px;
//   width: 40px;
//   border-radius: 50%;
//   padding: 6px;
//   padding-left: 11px;
// }

.question::after {
  content: "\002B";
  font-size: 1.4rem;
  position: absolute;
  right: 20px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  color: #000000;
  border: 1px solid #eeeeee;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  padding: 5px;
}

.question.active::after {
  content: "\2212";
  font-size: 1.4rem;
  position: absolute;
  right: 20px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  color: #000000;
  border: 1px solid #eeeeee;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  padding: 5px;
}



.prices {
  // max-height: 0;
  overflow: hidden;
  transition: 0.3s;
}

.question.active+.answercont {}


.pricing-table .container {
  background-color: rgba(255, 255, 255, 0);
  color: black;
  margin: 10px 0;
}

.information {
  margin-left: 20%;
  padding: 60px 0;
}

.answer {
  color: #646464;
}

.pricing-table .prices {
  display: flex;
  margin-left: 15px;
}

.pricing-table .prices .col {
  flex: 50%;
  padding: 20px 0;
}

.pricing-table .prices .col h6 {
  padding-bottom: 15px;
  font-size: 17px;
  font-weight: 600;
}

.pricing-table .prices .col li {
  margin-bottom: 15px;
}

.cart-div {
  margin-top: 30px;
}

.cart-div .unit-price {
  font-size: 27px;
  font-weight: 600;
  margin-bottom: 15px;
}

// .checkout-form {
//     width: -webkit-calc(100% - 600px);
//     width: calc(100% - 90px);
// }

.checkout-form {
  width: 100%;
}

.checkout .checkout-content {
  display: flex;
}

.checkout .checkout-content .col {
  flex: 50%;
  width: 50%;
}

.checkout .checkout-content .col:nth-child(1) {
  margin-right: 20px;
}

.checkout .wrapper {
  max-width: 1300px;
}

.checkout textarea {
  height: auto !important;
  line-height: 25px !important;
}

.other-address .box-field {
  margin-top: 15px;
}

.checkout .cart-bottom__total {
  margin-top: 15px;
}

.profile-main {
  width: 100%;
}

#profile-tab_3 .box-field {
  margin-bottom: 30px;
}

#update-information {
  padding-top: 30px !important;
}

#update-information .login-form {
  max-width: inherit !important;
}

#profile-tab_3 h3 {
  padding: 30px 0 !important;
  text-align: center;
}

.profile {
  padding-top: 120px !important;
  padding-bottom: 50px;
  position: relative;
}

.answer {
  color: #646464;
}

.pricing-table .prices {
  display: flex;
  margin-left: 15px;
}

.pricing-table .prices .col {
  flex: 50%;
  padding: 20px 0;
}

.pricing-table .prices .col h6 {
  padding-bottom: 15px;
  font-size: 17px;
  font-weight: 600;
}

.pricing-table .prices .col li {
  margin-bottom: 15px;
}

.cart-div {
  margin-top: 30px;
}

.cart-div .unit-price {
  font-size: 27px;
  font-weight: 600;
  margin-bottom: 15px;
}

// .product-info__color {
//   display: none;
// }

.addtocart {
  display: none;
}

.bread-crumbs li a {
  color: #fff6f6;
}

.product-info__quantity-title {
  margin-top: 20px !important;
}


.question2 {
  font-size: 1.1rem;
  font-weight: 600;
  padding: 20px 80px 20px 0px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #cac9c9;
}

.question2::after {
  content: "\002B";
  font-size: 1.4rem;
  position: absolute;
  right: 20px;
  transition: 0.2s;
  color: #000000;
  border: 1px solid #eeeeee;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  padding: 10px;
}

.question2.active::after {
  transform: rotate(45deg);
}

.benifits .prices {
  display: flex;
  margin-left: 15px;
}

.benifits .prices .col {
  flex: 50%;
  padding: 20px 0;
}

.benifits .prices .col h6 {
  padding-bottom: 15px;
  font-size: 17px;
  font-weight: 600;
}

.benifits .prices .col li {
  margin-bottom: 15px;
}

.benifits .container {
  background-color: rgba(255, 255, 255, 0);
  color: black;
  margin: 10px 0;
}

.head {
  font-size: 1.1rem;
  font-weight: 600;
  padding: 20px 80px 20px 0px;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #cac9c9;
}

.head::after {
  content: "\002B";
  font-size: 1.4rem;
  position: absolute;
  right: 20px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  color: #000000;
  border: 1px solid #eeeeee;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  padding: 11px;
}

.head.active::after {
  content: "\2212";
  font-size: 1.4rem;
  position: absolute;
  right: 20px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  color: #000000;
  border: 1px solid #eeeeee;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  padding: 11px;
}

.item-det2,
.item-det3 {
  display: none;
}

.dashboard-page {
  padding: 110PX 150PX;
}

.login-page {
  padding: 180px 680px;
}

.login-page .form-control {
  background: #ffffff;
  border: 1px solid #f2e7d6;
  height: 60px;
  line-height: 60px;
  padding: 18px 29px;
  font-size: 14px;
  width: 100%;
}

.login-page .form-group {
  margin-top: 30px;
}

.login-btn {
  margin-top: 30px;
}

.register-page {
  padding: 180px 680px;
}

.register-page .form-control {
  background: #ffffff;
  border: 1px solid #f2e7d6;
  height: 60px;
  line-height: 60px;
  padding: 18px 29px;
  font-size: 14px;
  width: 100%;
}

.register-page .form-group {
  margin-top: 30px;
}

.register-btn {
  margin-top: 30px;
}

.logout-buttons ul,
.login-buttons ul {
  display: inline-flex;
}

.hidden {
  display: none;
}

.StripeElement {
  background: #ffffff;
  border: 1px solid #f2e7d6;
  height: 60px;
  line-height: 60px;
  padding: 18px 29px;
  font-size: 14px;
  width: 100%;
  margin-top: 0;
}

.checkout button {
  height: 60px;
  line-height: 59px;
  outline: none;
  border: none;
  background: #333333;
  padding: 0 50px;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  text-transform: uppercase;
  display: inline-block;
  transition: all 0.3s linear;
  font-family: "Lato";
  margin-top: 15px;
}

.cart .row {
  display: flex;
}

.cart .row .col1 {
  flex: 65%;
  width: 65%;
  margin-right: 40px;
}

.cart .row .col2 {
  flex: 35%;
  width: 35%;
}

.cart-bottom {
  margin-top: 30px;
  display: flex;
  margin-bottom: 49px;
  justify-content: space-between;
}

.cart .wrapper {
  max-width: 1450px;
}

.checkout-form {
  width: 100%;
}

.checkout .checkout-content {
  display: flex;
}

.checkout .checkout-content .col {
  flex: 50%;
  width: 50%;
}

.checkout .checkout-content .col:nth-child(1) {
  margin-right: 20px;
}


.checkout .wrapper {
  max-width: 1300px;
}

.checkout textarea {
  height: auto !important;
  line-height: 25px !important;
}

.other-address .box-field {
  margin-top: 15px;
}

.checkout .cart-bottom__total {
  margin-top: 15px;
}

.checkout .cart-bottom__total {
  width: 100%;
  background: #faf9ff;
  border: 1px solid #eeeeee;
  padding: 55px;
  padding-bottom: 0;
  padding-top: 27px;
}


.checkout {
  padding-bottom: 0;
}

.btn-quote {
  display: none;
  padding: 22px 33px;
}

.checkout-payment {
  padding-top: 0 !important;
  padding-bottom: 90px !important;
}

.dollar {
  font-size: 27px;
  font-weight: 600;
  margin-bottom: 15px;

}

.registration h2,
.login h2 {
  margin-bottom: 10px;
}

#login-form {
  padding-bottom: 100px;
  padding-top: 100px;
  background-color: #fcf2e8;
}

.registration {
  padding-bottom: 120px;
  padding-top: 120px;
}

#login-form input {
  margin-top: 15px;
}

.profile-main {
  width: 100%;
}


#profile-tab_3 .box-field {
  margin-bottom: 30px;
}

#update-information {
  padding-top: 30px !important;
}

#update-information .login-form {
  max-width: inherit !important;
}

#profile-tab_3 h3 {
  padding: 30px 0 !important;
  text-align: center;
}

.profile {
  padding-top: 120px !important;
  padding-bottom: 50px;
}

.bread-crumbs li {
  color: #ffffff;
}

.response {
  margin-top: 11px;
  color: #D05278;
  font-style: italic;
}

.error {
  margin-top: 11px;
  color: red;
  font-style: italic;
}

.note {
  margin-top: 50px;
  color: black;
  font-style: italic;
}

.priceandqty {
  display: none;
}

.product-info__quantity {
  display: none;

}

.filling .row {
  display: flex;

}

.filling .row .col {
  flex: 50%;
  width: 50%;
}

.filling img {
  width: 100%;
  height: 860px;
}

#filling-col {
  padding: 70px;
}

#filling-col p {
  margin-bottom: 30px;
  margin-top: 30px;
  width: 80%;
}

.cart-table__info {
  display: inline-block;
  vertical-align: top;
  margin-top: 45px;
}


.weight {
  margin-bottom: 20px;
  font-size: 20px;
}

.prod-certs {
  margin-top: 20px;
}

.btn-signin {
  margin-top: 20px;
}

.prod-retail {
  margin-top: 20px;
}

.prod-detals .item {
  margin-top: 15px;
}

.details-cart {
  font-size: 13px;
}




.menu {
  margin-left: auto;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
}

/* Required Menu CSS */
.js-menu__context {
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: opacity 0.5s, visibility 0.5s;
  visibility: hidden;
  width: 100%;
  height: 100%;
  z-index: 40000;
}

.js-menu {
  color: #000;
  position: absolute;
  height: 100%;
  width: 30vw;
  max-width: 85vw;
  padding: 70px 10px;
  margin: 0;
  background: #fff;
  z-index: 99;
  overflow-y: auto;

  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  transition: background 0.5s, transform 0.5s;
}

.js-menu--left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.js-menu--right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  position: absolute;
  right: 0;
}

.js-menu__open {
  cursor: pointer;
}

.js-menu__close {
  cursor: pointer;
  position: absolute;
  top: 16px;
}

.js-menu__expanded {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  visibility: visible;
  opacity: 1;
}

.js-menu__context .cart-table__col:first-of-type {
  width: 100%;
  padding-left: 0px;
  display: flex;
}

.product .product_name {
  font-weight: 600;
}

.js-menu__context h6 {
  margin-bottom: 20px;
}

.js-menu__context .button {
  position: absolute;
  // bottom: 10px;
}

#shopping {
  margin-right: 15px;
}

.cart-table__info .title5 {
  font-size: 23px;
}

.cart .fa-xmark {
  margin-left: 20px;
  margin-top: 25px;
}

.slick-arrow {
  display: none !important;
}

.js-menu__context .dets {
  width: 110%;
  margin-top: 8px;
}

.js-menu__context .title5 {
  padding-bottom: 15px;
}

#description {
  margin-bottom: 10px;
}

.quote-btn {
  margin-top: 50px;
  margin-bottom: 10px;
}

// .active {
//   width: 200px!important;
// }

.white {
  color: #fff;
}
















.slider {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: 0 auto 30px auto;
  max-width: 100% !important;
  height: 690px;
}

.slider ul {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  height: 100%;
  display: inline-block;
  list-style: none;
  margin-left: 0 !important;
  // background:#458;
}


.slider ul li {
  position: relative;
  float: left;
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  // text-align:center;
  line-height: 30px;
  color: #333;
}


.control {
  position: absolute;
  top: 40%;
  z-index: 1;
  display: block;
  padding: 20px 17px;
  width: auto;
  height: auto;
  background: #e2cdc9;
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
  opacity: 0.8;
  cursor: pointer;
}

.prev {
  left: 20px;
}

.next {
  right: 20px;
}

.c.c {
  background-image: url(../../../public/assets/img/shop-banner2.png);
}

.r.r {
  background: red;
  color: #fff;
}

.g.g {
  background: #999;
  color: #fff;
}

.b.b {
  background-image: url(../../../public/assets/img/shop-banner1.png);
}

.shop-banner {
  margin-left: 26%;
  margin-top: 6.5%;
}

.shop-banner h1 {
  font-size: 73px;
  line-height: 70px;
  color: #222222;
  font-family: "Montserrat", sans-serif;
  text-transform: capitalize;
  font-weight: 900;
  letter-spacing: 3px;
}

.shop-banner .saint-text {
  font-family: "Acumin Pro";
  color: #000000;
  display: block;
  font-size: 44px;
  color: #fff;
  text-transform: capitalize;
  padding-bottom: 5px;
  margin-bottom: 5px;
  font-weight: 600;
  letter-spacing: 6px;
}

.shop-banner .btn {
  margin-top: 35px;
  padding: 0 25px;
  font-size: 18px;
  letter-spacing: 3px;
}

.container-shop {
  padding: 0 120px;
}

.category-1 {
  background-image: url(../../../public/assets/img/category-1.jpeg);
  background-position: center;
  padding-top: 440px;
  padding-bottom: 25px;
  display: flex;
  justify-content: center;
  align-items: baseline;
  background-size: cover;
}

.category-2 {
  background-image: url(../../../public/assets/img/category-2.png);
  background-position: center;
  padding-top: 440px;
  padding-bottom: 25px;
  display: flex;
  justify-content: center;
  align-items: baseline;
  background-size: cover;
}

.category-3 {
  background-image: url(../../../public/assets/img/category-3.png);
  background-position: center;
  padding-top: 440px;
  padding-bottom: 25px;
  display: flex;
  justify-content: center;
  align-items: baseline;
  background-size: cover;
}

.category-4 {
  background-image: url(../../../public/assets/img/category-4.png);
  background-position: center;
  padding-top: 440px;
  padding-bottom: 25px;
  display: flex;
  justify-content: center;
  align-items: baseline;
  background-size: cover;
}

.category-1 h5,
.category-2 h5,
.category-3 h5,
.category-4 h5 {
  font-size: 26px;
  font-weight: 600;
}

.carousel-item-padding-40-px {
  margin-right: 20px;
}

.react-multi-carousel-list {
  position: unset !important;
}

.react-multiple-carousel__arrow {
  border-radius: 0px !important;
  background: #f7f7f7 !important;
  min-height: 53px !important;
}

.react-multiple-carousel__arrow::before {
  font-size: 26px !important;
  color: #000 !important;
  font-weight: 800 !important;
}

.react-multiple-carousel__arrow--right {
  right: calc(3% + -5px) !important;
}

.react-multi-carousel-dot-list {
  bottom: -760px !important;
}

.custom-slide {
  padding-top: 70px;
  padding-bottom: 100px !important;
}

.butt-shop {
  text-align: center;
  margin-top: 80px;
}

.discover-top {
  background-color: #000;
  padding: 20px 0;
}

.discover-top h2 {
  color: #fff;
  text-align: center;
}

.discover-main {
  background-color: #f1f1f1;
  padding: 0 120px;
}

.all-category {
  background-color: #f2f2f2;
  padding-top: 90px;
  padding-bottom: 90px;
}

.all-category .makeup-slide {
  background-color: #fff;
  text-align: center;
  height: 50vh;
}

.all-category .makeup-slide h5 {
  margin-top: -30px;
  font-weight: 600;
}

.all-category .btn {
  padding: 0 34px;
  font-size: 18px;
}

#all-category-1 .react-multi-carousel-dot-list {
  bottom: -2490px !important;
}

#all-category-2 .react-multi-carousel-dot-list {
  bottom: -3245px !important
}

.shop-private {
  background-color: #fde7e8;
}


.shop-private .container {
  padding: 60px 60px;
}

.shop-private .row {
  display: flex;
}

.shop-private .row .col {
  flex: 50%;
  width: 50%;
  text-align: center;
}

.private-button {
  padding: 70px 0;
}

#shop-private-1 {
  padding-top: 80px;
}

#shop-private-1 a {
  font-size: 21px;
}

.button-1 a {
  padding: 0 80px;
  margin-bottom: 50px;
}

.button-2 a {
  padding: 0 75px;
  margin-bottom: 50px;
}

.button-3 a {
  padding: 0 65px;
}

.gallery {
  text-align: center;
  margin-top: 90px;
}

.gallery .container {
  padding: 60px 300px;
}

.gallery .row {
  display: flex;
}

.gallery .row .col {
  flex: 20%;
  width: 20%;
  margin-right: 10px;
}

.discover-sec .all-category {
  background-color: #f1f1f1 !important;
}

.discover-sec .container-shop {
  padding: 0 90px;
}

.react-tabs__tab-list {
  border-bottom: none !important;
  margin: 0 0 35px !important;
  ;
}

.react-tabs__tab {
  border: none !important;
  font-size: 22px;
  font-weight: 600;
  margin-right: 25px;
}

.react-tabs__tab--selected {
  background: transparent !important;
  border-color: transparent !important;
  color: black;
  border-radius: 5px 5px 0 0;
  border-bottom: 2px solid black !important;
}

.react-tabs__tab:focus:after {
  background-color: transparent !important;
}

.hidden-menu5 {
  display: none;
}

.prod-col:hover {
  border: 2px solid #000;
  border-radius: 100px;
}

.active-color {
  border: 2px solid #000;
  border-radius: 100px;
}

.discover-main #all-category-1 .react-multi-carousel-dot-list {
  bottom: -1710px !important;
  display: none;
}

.discover-main img {
  width: 416px;
  height: 416px;
  object-fit: scale-down;
}

.discover-main h5,
.all-category h5 {
  font-size: 20px;
}


#pd-overview {
  background-image: url(../../../public/assets/img/pd-bg.jpg);
  background-position-x: 0;
  padding: 100px 90px;
}

#cm-overview {
  background-image: url(../../../public/assets/img/cm-bg.png);
  background-position-x: 0;
  padding: 100px 90px;
  background-size: cover;
  background-position-y: 0;
}

#pl-overview {
  background-image: url(../../../public/assets/img/pl-bg.png);
  padding: 110px 90px;
}

#cm-overview .content {
  margin-left: 45%;
}

#pd-overview h2,
#cm-overview h2,
#pl-overview h2 {
  font-size: 61px;
}

#work .saint-text {
  color: #b27150;
  font-size: 24px;
  margin-top: 35px;
}



.promo-video .promo-video__block {
  margin-top: 20px;
}

.about-main .corporate .info-blocks__item-text {
  padding: 97px 0px 143px 0px;
}

.header-nav li .shop-menu ul {
  width: 100vw;
  left: 0px;
}

.custom-info img {
  margin-top: -10%;
}

@media only screen and (min-width: 768px) {
  .header-box .fa-xmark {
    display: none;
  }

  .mob-sub-1 {
    display: none;
  }
}

.mob-sub-1 {
  display: none;
  margin-left: 10px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.prod-detals {
  display: none;
}

.product-info__color .prod-col {
  margin: 0px 2px;
}

.hideonwty {
  display: none !important;
}

.responsoe-twelve {
  color: red;
  margin-top: 10px;
  font-style: italic;
}

.search-form {
  display: flex;
}

.icon-search {
  background-color: transparent;
  // border-right: 1px solid black;
  // border-top: 1px solid black;
  // border-bottom: 1px solid black;
  border-left: none;
  padding-right: 0px;
}

.about-main-section {
  background-image: url('../../../public/assets/img/about-section-main.jpg');
  background-size: cover;
  margin-top: 60px;
}

.about-main-section #about-col-2 {
  padding: 60px 120px;
}

.about-content {
  background-color: #f2eeedd1;
}

.about-main-section .row {
  display: flex;
}

.about-main-section .col {
  flex: 0 50%;
  width: 50%;
}

.about-main-section .about-content {
  padding: 120px 80px;
}

.about-main-section .about-content p {
  padding-bottom: 15px;
}

.about-certs .container {
  padding: 90px 210px;
}

.about-certs .row {
  display: flex;
}

.about-certs .col {
  flex: 50%;
  width: 50%;
}

.about-certs .col:nth-child(2) {
  padding: 0 280px;
}

.about-certs h2 {
  margin-bottom: 30px;
}

.about-certs p {
  padding-right: 160px;
}

.about-certs img {
  display: block;
}






.products-cat a {
  margin-top: 16px;
}

.location-para {
  padding-bottom: 0 !important;
}

.journey-banner {
  // margin-bottom: -3px;
  padding: 15px 0;
  background-color: #343434;
}

.journey-banner h3 {
  font-family: "Autography";
  text-transform: inherit;
  font-size: 58px;
  text-align: center;
  color: #fff;
}

.mobile-header-bar {
  background-color: #eddfcc;
  padding: 15px;
}

.mobile-header-bar-items {
  display: flex;
  justify-content: space-between;
}

.mobile-header-bar-items .mobile-main-menu a {
  padding-left: 15px;
  color: #000;
}

.count-cart {
  background-color: #878787;
  color: #fff;
  border-radius: 100%;
  font-size: 10px;
  padding: 1px 4px;
}

// .mobile-left-menu {
//   height: 30vh;
//   background-color: #000;
// }

.mobile-left-menu {
  left: -306px;
  position: fixed;
  top: 0;
  width: 292px;
  height: 100%;
  flex-direction: column;
  background-color: #f2e6d4;
  color: #2a2a2a;
  z-index: 101;
  justify-content: start;
  align-items: flex-start;
  padding: 0px 0px 14px 16px;
  transition: 0.3s ease-in;
  overflow: none;
  flex-direction: column;
}

.act-head {
  left: -16px;
}

.mobile-left-menu-top {
  background-color: #00000021;
  padding: 38px 12px;
}

.mobile-left-menu-top svg {
  background-color: #000;
  color: #fff;
  border-radius: 100%;
  padding: 2px 5px;
  font-size: 12px;
  position: absolute;
  right: 6px;
  top: 10px;
}

.mobile-left-menu-top .fa-xmark:hover {
  cursor: pointer;
}

.mobile-left-menu h5 {
  font-family: "Autography";
  font-size: 28px;
  text-transform: inherit;
}

.mobile-left-menu .search-form {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.mobile-left-menu input {
  background-color: #f7f1e8;
  border: none;
  padding: 14px;
}

.mobile-left-menu button {
  background: #f7f1e8;
  border: none;
}

.main-menu-mobile {
  text-align: center;
  margin-top: 40px;
}

.main-menu-mobile ul {
  padding: 0 22px;
}

.main-menu-mobile ul li {
  border-bottom: 1px solid #000;
  padding: 8px 0;
  font-weight: 600;
  font-size: 14px;
  text-align: left;
  margin: 12px 0;
}

.main-menu-mobile ul .brown-menu a {
  color: #b3724f;
}

.main-menu-mobile ul li a {
  color: #000;
}

.main-menu-mobile .brown-menu i {
  color: #b3724f;
  padding-right: 6px;
}

.main-menu-mobile i {
  float: right;
}

.main-menu-mobile ul li:nth-child(1) i {
  float: left;
}

.social-icons {
  float: right;
  padding: 10px 30px;
}

.social-icons i {
  margin-left: 8px;
  background-color: #000;
  color: #fff;
  padding: 5px 6px;
  border-radius: 100%;
  font-size: 14px;
}

.social-icons i:nth-child(1) {
  padding: 5px 8px;
}

.mobile-address,
.mobile-contact {
  float: right;
  text-align: right;
  padding: 6px 30px;
}

.mobile-address p,
.mobile-contact p {
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
}

// mobile sub-menu shop
.mobile-left-menu-shop {
  left: -306px;
  position: fixed;
  top: 0;
  width: 292px;
  height: 100%;
  flex-direction: column;
  background-color: #f2e6d4;
  color: #2a2a2a;
  z-index: 101;
  justify-content: start;
  align-items: flex-start;
  padding: 0px 0px 14px 16px;
  transition: 0.3s ease-in;
  overflow: none;
  flex-direction: column;
}

.act-head {
  left: -16px;
}

// mobile sub-menu services
.mobile-left-menu-services {
  left: -306px;
  position: fixed;
  top: 0;
  width: 292px;
  height: 100%;
  flex-direction: column;
  background-color: #f2e6d4;
  color: #2a2a2a;
  z-index: 101;
  justify-content: start;
  align-items: flex-start;
  padding: 0px 0px 14px 16px;
  transition: 0.3s ease-in;
  overflow: none;
  flex-direction: column;
}

.act-head {
  left: -16px;
}

// mobile sub-menu makeup
.mobile-left-menu-makeup {
  left: -306px;
  position: fixed;
  top: 0;
  width: 292px;
  height: 100%;
  flex-direction: column;
  background-color: #f2e6d4;
  color: #2a2a2a;
  z-index: 101;
  justify-content: start;
  align-items: flex-start;
  padding: 0px 0px 14px 16px;
  transition: 0.3s ease-in;
  overflow: none;
  flex-direction: column;
  overflow-y: scroll;
}

.act-head {
  left: -16px;
}

// mobile sub-menu skincare
.mobile-left-menu-skincare {
  left: -306px;
  position: fixed;
  top: 0;
  width: 292px;
  height: 100%;
  flex-direction: column;
  background-color: #f2e6d4;
  color: #2a2a2a;
  z-index: 101;
  justify-content: start;
  align-items: flex-start;
  padding: 0px 0px 14px 16px;
  transition: 0.3s ease-in;
  overflow: none;
  flex-direction: column;
}

.act-head {
  left: -16px;
}

// mobile sub-menu skincare
.mobile-left-menu-cbd {
  left: -306px;
  position: fixed;
  top: 0;
  width: 292px;
  height: 100%;
  flex-direction: column;
  background-color: #f2e6d4;
  color: #2a2a2a;
  z-index: 101;
  justify-content: start;
  align-items: flex-start;
  padding: 0px 0px 14px 16px;
  transition: 0.3s ease-in;
  overflow: none;
  flex-direction: column;
}

.act-head {
  left: -16px;
}

// mobile sub-menu accessories
.mobile-left-menu-accessories {
  left: -306px;
  position: fixed;
  top: 0;
  width: 292px;
  height: 100%;
  flex-direction: column;
  background-color: #f2e6d4;
  color: #2a2a2a;
  z-index: 101;
  justify-content: start;
  align-items: flex-start;
  padding: 0px 0px 14px 16px;
  transition: 0.3s ease-in;
  overflow: none;
  flex-direction: column;
}

.act-head {
  left: -16px;
}

// mobile sub-menu essentials
.mobile-left-menu-inner {
  left: -306px;
  position: fixed;
  top: 0;
  width: 292px;
  height: 100%;
  flex-direction: column;
  background-color: #f2e6d4;
  color: #2a2a2a;
  z-index: 101;
  justify-content: start;
  align-items: flex-start;
  padding: 0px 0px 14px 16px;
  transition: 0.3s ease-in;
  overflow: none;
  flex-direction: column;
}

.act-head {
  left: -16px;
}



/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

// .qty-select,
// .cart-div,
// .pricing-table,
// .btn-signin {
//   display: none !important;
// }

.login-note {
  margin-top: 15px;
  font-size: 14px;
}

.FormGroup {
  margin-bottom: 30px;
}

.capabilities h2 {
  font-size: 35px;
}

.gallery h2 {
  text-transform: inherit;
}

.promotion-col {
  background-image: url(../../../public/assets/img/menu-img.png);
  background-size: cover;
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.promotion-col a {
  background: #333333cf;
  color: #fff !important;
  padding: 20px 50px;
}

#checkout-cjeck .check {
  display: block;
  margin-top: 10px;
}

#checkout-cjeck .check2 {
  margin-left: 0;
}

#checkout-cjeck .styled-checkbox+label:before {
  background: #eee1ce;
}

.login-bottom {
  text-align: center;
}

.login-list {
  margin-left: 40px;
}

.login-list li {
  list-style: disc;
}

.register-checkboxes .check {
  margin-right: 0;
}

#register-detail-row {
  margin-left: 2px;
  padding: 0 16px;
}

.advantages-item img {
  width: 70px;
  height: 70px
}

.registration {
  background-image: url('../../../public/assets/img/register-bg.png');
  background-size: cover;
}

.register-text {
  color: #8c6239;
  text-align: center;
  font-weight: 700;
  margin-bottom: 40px;
  font-size: 17px;
}

.register-checkboxes .styled-checkbox+label {
  display: flex;
}

.register-checkboxes .styled-checkbox:checked+label:after {
  top: 14px;
}

.register-checkboxes .styled-checkbox:checked+label:before {
  margin-top: 5px;
}

p.login-form__bottom {
  font-size: 13px;
  font-weight: 600;
}

p.login-form__bottom a {
  color: #000;
}

.login-form .btn {
  /* width: 100%; */
  text-align: center;
  background-color: #e0cab1;
  padding: 26px 90px;
  line-height: 0;
  height: 0;
}

.submit-btn-div {
  text-align: center;
}

.registration .fa-xmark {
  position: absolute;
  top: 25px;
  right: 190px;
}

#login-form .login-form {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 0;
  border: none;
}

#login-form .row {
  display: flex;
}

#login-form .col {
  flex: 50%;
  width: 50%;
  margin-right: 30px;
}

#login-form #login-form-col {
  padding: 52px 40px;
}

#login-form .wrapper {
  max-width: 1080px;
}

#login-form-col h2 {
  font-size: 38px;
  font-weight: 800;
  margin-bottom: 0;
}

#login-form-col .welcome-line {
  color: #90673f;
  font-weight: 700;
  margin-bottom: 15px;
}

#login-form-col input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #90673f;
  padding: 0 0;
  height: 40px;
}

#login-form-col .btn {
  width: 100%;
}

#login-form-col a {
  color: #000;
  text-decoration: underline;
}

.new-customer {
  font-size: 14px;
  margin-top: 25px;
}

#login-form .fa-xmark {
  position: absolute;
  top: 18px;
  right: 60px;
}

#login-form .col:nth-child(1) {
  background-image: url('../../../public/assets/img/signin-bg.png');
}



.contact-details {
  background-color: #333333;
  text-align: center;
}

.contact-details .row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-details .col {
  flex: 33.33%;
  width: 33.33%;
}

.contact-details .col:nth-child(2) {
  padding-top: 40px;
}

.contact-details .container {
  padding: 70px 320px;
}

.contact-details i {
  color: #fff;
}

.contact-details h4 {
  color: #e0cab1;
  font-weight: 600;
  margin: 5px 0;
}

.contact-details a,
.contact-details p {
  color: #fff;
}

.discount-contacts .box-field .form-control {
  height: 55px;
  margin-bottom: 10px;
}

.discount-info p {
  color: #000;
  font-size: 16px;
}

.main-checks {
  margin-left: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.discount-contacts .box-field textarea.form-control {
  height: auto;
}

.booking .container {
  padding: 0 120px;
}

.booking .row {
  display: flex;
}

.booking .col {
  flex: 50%;
  width: 50%;
}

.col-img {
  text-align: center;
}

.booking form .btn {
  margin-top: 10px;
}

#contact-help {
  background-color: #333333;
}

#contact-help p {
  margin-bottom: 0;
  padding: 3px 530px;
  color: #fff;
}

#contact-help h3 {
  color: #e0cab1;
  font-weight: 700;
}

.thankyou-main-content h5 {
  color: #895e2e;
  font-weight: 600;
  margin-bottom: 10px;
}

.bank-info .col {
  margin-bottom: 5px;
}

// **********************
// MEDIA QUERIES
// **********************

// media

@media screen and (max-width: 1680px) {
  .about-certs p {
    padding-right: 0px;
  }

  .products-cat {
    padding: 60px 60px 20px 60px !important;
  }

  #main-banner .col2,
  #main-banner .col1 {
    padding: 70px 70px;
  }

  .about-certs h2 {
    font-size: 43px;
  }

  .about-certs .col:nth-child(2) {
    padding: 0 250px;
  }

  .mission-sec p {
    padding: 20px 100px 0px 100px;
  }

  .mission-sec h2 {
    padding: 10px 100px;
  }

  .header-nav li {
    margin: 0 20px !important;
  }

  .commitment .col1 {
    padding: 60px 190px !important;
  }

  .commitment .cont {
    padding: 0 130px !important;
  }

  .custom-info p {
    width: 75% !important;
  }

  .fac-cont {
    padding: 70px 50px !important;
  }

  .facility .fac-col-1 {
    margin-top: 0 !important;
  }

  .know-more p {
    width: 95% !important;
  }

  .promise-sec .promise-head p {
    padding: 6px 0px;
  }

  .promise-main .col1 {
    padding-left: 60px;
  }

  .promise-main p {
    padding-right: 90px;
  }

  .aboutsec .container {
    padding: 120px 60px !important;
  }

  .cont-info img {
    height: 80% !important;
    width: 77% !important;
    transform: translateX(110px);
  }

  .info-blocks-video .info-blocks__item-img img {
    height: 60% !important;
  }

  .over img {
    margin-top: 200px !important;
  }

  .header-nav .sub-menu li {
    margin: 0 10px !important;
  }

  .header-nav li .sub-menu ul li a {
    padding: 7.6px 20px !important;
  }

  .header-nav li .sub-menu ul {
    margin-left: 20px;
  }

  .corporate #corp-img {
    height: 100% !important;
  }

  #about-promo .promo-video__block {
    margin-top: 25px !important;
  }

  .promise-sec .promise-main .col-car {
    padding: 40px 45px !important;
  }

  // #main-banner h1 {
  //   font-size: 44px;
  //   line-height: 50px;
  // }

  #main-banner .banner-cont {
    width: 630px !important;
  }

  .header-nav li {
    margin: 0 16px !important;
  }

  .shop-banner {
    margin-left: 56vw !important;
    margin-top: 12% !important;
  }

  .shop-banner h1 {
    font-size: 66px !important;
  }

  .shop-banner .saint-text {
    font-size: 38px !important;
  }

  .react-multi-carousel-dot-list {
    bottom: -675px !important;
  }

  .discover-sec .container-shop {
    padding: 0 55px;
  }

  .all-category .makeup-slide h5 {
    margin-top: -20px;
    font-weight: 600;
  }

  .discover-main h5,
  .all-category h5 {
    font-size: 17px;
  }

  .all-category .makeup-slide {
    background-color: #fff;
    text-align: center;
    height: 44vh;
  }

  .discover-main #all-category-1 .react-multi-carousel-dot-list {
    bottom: -1620px !important;
  }

  #all-category-1 .react-multi-carousel-dot-list {
    bottom: -2370px !important;
  }

  #all-category-2 .react-multi-carousel-dot-list {
    bottom: -3095px !important;
  }

  .products-cat {
    padding: 60px 80px;
  }
}

@media screen and (max-width: 1600px) {

  .corporate-sec {
    background-position-x: -520px;
  }

  .about-main-section .about-content {
    padding: 60px 60px;
  }

  .promise-main .col1 {
    padding-left: 30px;
  }

  .about-certs .col:nth-child(2) {
    padding: 40px 230px;
  }

  .main-block {
    padding-top: 0;
    padding-bottom: 0;
  }

  .header-nav li .sub-menu ul {
    margin-left: -20px;
  }

  .react-multi-carousel-dot-list {
    bottom: -825px !important;
  }

  #all-category-1 .react-multi-carousel-dot-list {
    bottom: -2385px !important;
  }

  #all-category-2 .react-multi-carousel-dot-list {
    bottom: -3045px !important;
  }

  .support {
    background-image: url(../../../public/assets/img/supportbg2.jpg) !important;
  }

  .turnkey .info-blocks__item-reverse .info-blocks__item-text {
    padding: 90px 10px 90px 50px !important;
  }

  .main-text {
    margin-bottom: 25px;
    margin-top: 0px;
  }

  .header-nav li {
    margin: 0 24px;
    position: relative;
  }

  .header-nav li {
    margin: 0 20px;
    position: relative;
  }

  #main-banner p {
    width: 100% !important;
  }

  #main-banner .col1 {
    // background-size: auto !important;
    width: 50vw;
  }

  // #main-banner h1 {
  //   line-height: 50px;
  // }

  #main-banner .col2 {
    width: 50vw;
  }

  .info-blocks__item-reverse .info-blocks__item-text {
    padding: 237px 10px 223px 50px !important;
  }

  .experience .row {
    width: 1140px;
  }

  .promise-sec .promise-head p {
    padding: 0px 0px !important;
  }

  .desire-sec .des-head p {
    padding: 20px 360px !important;
  }

  .promise-sec .promise-main .col-car {
    padding: 25px 45px !important;
  }

  .carousel-slide__content {
    font-size: 18px !important;
  }

  .aboutsec .container {
    padding: 120px 25px !important;
  }

  .facility .fac-col-1 {
    margin-top: 60px;
    padding: 0 300px !important;
  }

  .commitment .col1 {
    padding: 120px 80px !important;
  }

  .fac-cont {
    padding: 70px 210px !important;
  }

  .know-more .col1 {
    flex: 50% 1;
    width: 50%;
  }

  .know-more .col2 {
    flex: 50% 1;
    width: 50%;
  }

  .know-more iframe {
    width: 770px;
  }

  .info-blocks-video .info-blocks__item-img img {
    height: 53% !important;
  }

  .turnkey img {
    margin-top: 220px !important;
    width: 91% !important;
  }

  .custom-info p {
    width: 87% !important;
  }

  .powder {
    padding: 140px 0 !important;
  }

  .promise-sec .promise-main .col-car {
    padding: 65px 45px !important;
  }

  .about-certs .container {
    padding: 90px 150px;
  }
}

@media only screen and (max-width: 1440px) {
  .booking .container {
    padding: 0 80px;
  }

  .subscribe-form .box-field__row {
    width: 48vw;
  }

  .booking .main-text {
    font-size: 36px;
    margin-bottom: 35px;
  }

  .booking .discount-info {
    max-width: 837px;
    margin-right: 44px;
  }

  .contact-details .container {
    padding: 70px 150px;
  }

  .about-main-section .about-content {
    padding: 40px 40px;
  }

  .promise-sec .container {
    padding: 90px 120px;
  }

  .sustain-sec {
    padding: 60px 90px;
    background-size: contain;
  }

  .responsible-sec {
    padding: 90px 90px;
  }

  .responsible-sec h2 {
    margin-bottom: 6px;
    font-size: 33px;
  }

  .corporate-sec {
    padding: 90px 90px;
  }

  .corporate-sec h2 {
    margin-bottom: 8px;
    font-size: 33px;
  }

  .about-certs .col:nth-child(2) {
    padding: 30px 180px;
  }

  .about-certs .container {
    padding: 90px 150px;
  }

  .about-certs h2 {
    font-size: 36px;
    margin-bottom: 14px;
  }

  .fac-cont {
    padding: 70px 110px !important;
  }

  .header-nav li .sub-menu ul {
    margin-left: -30px;
  }

  .know-more iframe {
    width: 690px;
  }

  #cm-overview .content {
    margin-left: 50%;
  }

  #pd-overview h2,
  #cm-overview h2,
  #pl-overview h2 {
    font-size: 55px;
  }

  .prods-overview p {
    padding: 0 390px;
  }

  .products-cat .otc {
    background-position-x: -165px;
  }

  .facility-overview .fac-content {
    width: 32%;
  }

  .header-nav li a {
    font-size: 14px;
  }

  .header-nav li {
    margin: 0 13px !important;
  }

  #main-banner .banner-cont {
    width: 530px !important;
  }

  #main-banner h1 {
    font-size: 60px;
    line-height: 56px;
  }

  .help p {
    padding: 10px 450px;
    margin-bottom: 10px;
  }

  .b.b {
    background-image: url(../../../public/assets/img/shop-banner1.png);
    background-position-x: -110px;
  }

  .shop-banner h1 {
    font-size: 57px !important;
  }

  .shop-banner .saint-text {
    font-size: 31px !important;
  }

  .shop-banner .btn {
    margin-top: 20px;
  }

  .gallery .container {
    padding: 60px 120px;
  }
}

@media screen and (max-width: 1400px) {
  .products-item__img {
    height: 435px;
  }

  .top-categories__item {
    height: 600px;
  }

  .info-blocks__item-img-overlay span {
    margin-top: 0;
  }

  .header-nav li {
    margin: 0 20px;
    position: relative;
  }

  #main-banner p {
    width: 100% !important;
  }

  #main-banner .col1 {
    background-size: auto !important;
  }

  .info-blocks__item-reverse .info-blocks__item-text {
    padding: 237px 10px 223px 50px !important;
  }

  .experience .row {
    width: 1140px;
  }

  .promise-sec .promise-head p {
    padding: 20px 440px !important;
  }

  .desire-sec .des-head p {
    padding: 20px 360px !important;
  }

  .promise-sec .promise-main .col-car {
    padding: 25px 45px !important;
  }

  .carousel-slide__content {
    font-size: 18px !important;
  }

  .aboutsec .container {
    padding: 120px 100px !important;
  }
}

@media screen and (max-width: 1366px) {
  .corporate-sec {
    padding: 80px 80px;
  }

  .corporate-sec h2 {
    margin-bottom: 8px;
    font-size: 32px;
  }

  .promise-main .col1 {
    padding-left: 10px;
  }

  .sustain-sec h2 {
    margin-bottom: 8px;
    font-size: 34px;
  }

  .about-certs h2 {
    font-size: 33px;
    margin-bottom: 14px;
  }

  .mission-sec p {
    width: 60%;
  }

  #pd-overview h2,
  #cm-overview h2,
  #pl-overview h2 {
    font-size: 48px;
  }

  .about-main-section .about-content p {
    font-size: 14px;
  }

  #cm-overview .content {
    margin-left: 53%;
  }

  .products-cat p {
    font-size: 18px;
  }

  .header-nav li {
    margin: 0 15px !important;
    position: relative;
  }

  .react-multi-carousel-dot-list {
    bottom: -958px !important;
  }

  #all-category-2 .react-multi-carousel-dot-list {
    bottom: -3005px !important;
  }

  #all-category-1 .react-multi-carousel-dot-list {
    bottom: -2400px !important;
  }

  .header-nav li .sub-menu ul {
    margin-left: -30px !important;
  }

  .develop-sec .row-1 p {
    padding: 15px 360px;
  }

  .prods-overview p {
    padding: 0 220px;
  }

  .fac-cont {
    padding: 70px 90px !important;
  }

  .know-more iframe {
    width: 660px !important;
  }

  .header-nav li a {
    font-size: 12px;
  }

  #main-banner p {
    width: 100% !important;
  }

  #main-banner .col1 {
    background-size: cover !important;
  }

  #main-banner .banner-cont {
    padding: 60px 0px !important;
    width: 620px !important;
    margin-left: -35px !important;
  }

  #main-banner h1 {
    font-size: 46px;
  }

  .info-blocks__item-reverse .info-blocks__item-text {
    padding: 237px 10px 223px 50px !important;
  }

  .experience .row {
    width: 1140px;
  }

  .promise-sec .promise-head p {
    padding: 0px !important;
  }

  .desire-sec .des-head p {
    padding: 20px 360px !important;
  }

  .promise-sec .promise-main .col-car {
    padding: 25px 45px !important;
  }

  .carousel-slide__content {
    font-size: 18px !important;
  }

  .aboutsec .container {
    padding: 120px 100px !important;
  }
}

@media screen and (max-width: 1280px) {
  .know-more iframe {
    width: 610px !important;
  }

  .corporate-sec h2 {
    margin-bottom: 8px;
    font-size: 30px;
  }

  .responsible-sec h2 {
    margin-bottom: 6px;
    font-size: 30px;
  }

  .sustain-sec h2 {
    margin-bottom: 8px;
    font-size: 30px;
  }

  .header-nav li .sub-menu ul {
    margin-left: -20px !important;
  }

  #pd-overview h2,
  #cm-overview h2,
  #pl-overview h2 {
    font-size: 40px;
  }

  #cm-overview .content {
    margin-left: 57%;
  }

  .products-cat .skin-care {
    background-position-x: -200px;
  }

  .organic .content {
    margin-left: 50% !important;
  }

  .help p {
    padding: 10px 240px;
  }

  .trending,
  .discount {
    padding-top: 110px;
    padding-bottom: 110px;
  }

  .info-blocks-video .info-blocks__item-img img {
    height: 42% !important;
  }

  .login {
    padding-top: 280px;
  }

  .header-nav li {
    margin: 0 10px !important;
    position: relative;
  }

  #main-banner .banner-cont {
    padding: 60px 0px !important;
    width: 580px !important;
    margin-left: -35px !important;
  }

  #main-banner h1 {
    font-size: 50px;
    line-height: 57px;
  }

  .turnkey img {
    height: 45% !important;
    margin-top: 330px !important;
    margin-left: 0px !important;
  }

  .custom-info p {
    width: 98% !important;
  }

  .saint-text {
    font-size: 29px !important;
  }

  .fac-cont {
    padding: 70px 60px !important;
  }

  .main-logos {
    padding-bottom: 110px;
  }

  .main-logos img {
    margin: 0 49px;
  }

  .advantages,
  .top-categories,
  .arrivals,
  .latest-news,
  .testimonials,
  .all-categories,
  .faq,
  .post,
  .shop,
  .product,
  .cart,
  .wishlist,
  .login,
  .error-page {
    padding-top: 110px;
  }

  .subscribe,
  .insta-photos,
  .blog {
    margin-top: 110px;
  }

  .promo-video {
    padding-top: 200px;
    margin-bottom: 110px;
  }

  .detail-block_margin {
    padding-bottom: 110px;
  }

  .post {
    padding-bottom: 0;
  }

  .cart-bottom {
    margin-bottom: 0;
  }

  .checkout {
    padding-top: 170px;
    padding-bottom: 0;
  }

  .profile {
    padding-top: 110px;
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 1280px) and (max-height: 780px) {
  .header-nav li ul {
    padding: 10px 120px 14px 180px !important;
  }

  .header-nav .saint-text {
    font-size: 24px !important;
  }

  .shop-menu .subsub {
    margin-bottom: 14px !important;
  }

  .shop-menu a {
    margin-bottom: 7px !important;
    margin-top: 7px !important;
  }

  .shop-menu .mens {
    margin-bottom: 39px !important;
  }
}


@media screen and (max-width: 1200px) {
  .commitment .cont {
    padding: 0 80px !important;
  }

  .info-blocks-video .info-blocks__item-img {
    display: none !important;
  }

  .info-blocks__item-reverse .info-blocks__item-text {
    padding: 137px 10px 133px 50px !important;
  }

  .custom-info p {
    font-size: 17px;
    margin-bottom: 15px;
    width: 107% !important;
  }

  .fac-cont {
    background-color: #ffffffcb;
    text-align: center;
    padding: 70px 0px !important;
  }

  .know-more .row {
    display: block !important;
  }

  .help p {
    padding: 10px 0px !important;
    margin-bottom: 10px;
  }

  .subscribe {
    margin-top: 40px !important;
  }

  .main-block__content {
    max-width: 685px;
  }

  .main-logos img {
    margin: 0 25px;
    max-width: 110px;
  }

  .discount-info {
    margin-right: 0;
  }

  .top-categories__item {
    height: 500px;
  }

  .info-blocks__item-text {
    padding: 186px 40px;
  }

  .subscribe-form {
    padding: 0 40px;
  }

  .insta-photo__hover i {
    font-size: 50px;
  }

  .insta-photos {
    margin-top: 110px;
    flex-wrap: wrap;
  }

  .insta-photo {
    width: 33.3%;
    margin-top: 0;
    height: 250px;
  }

  .detail-block__item {
    padding: 25px 15px 26px 15px;
  }

  .discount-about .discount-info {
    margin-right: 0;
  }

  .testimonials-slider {
    width: 100%;
    max-width: 100%;
  }

  .insta-photos_contacts {
    margin-top: 0;
  }

  .discount-contacts .discount-info {
    margin-right: 0;
  }

  .post-content {
    padding: 60px 40px 0;
  }

  .post-bottom,
  .post-comments {
    padding-left: 40px;
    padding-right: 40px;
  }

  .post .discount .discount-info {
    max-width: 460px;
    margin-right: 0;
  }

  .shop-aside {
    width: 210px;
  }

  .shop-main {
    width: calc(100% - 240px);
  }

  .product-slider {
    width: 480px;
  }

  .product-info {
    width: calc(100% - 520px);
  }

  .product-detail__form {
    width: 360px;
    padding: 50px 30px 50px;
  }

  .product-detail__items {
    width: calc(100% - 390px);
  }

  .cart-bottom__total {
    width: 430px;
  }

  .cart-bottom__promo {
    width: calc(100% - 450px);
  }

  .checkout-info {
    width: 390px;

    .cart-bottom__total {
      width: 100%;
    }
  }

  .checkout-form {
    // width: calc(100% - 410px);
  }

  .checkout-order__item-info h6,
  .checkout-order__item-info .title6 {
    font-size: 16px;
  }

  .checkout-order__item-price {
    font-size: 13px;
  }

  .cart-table__img {
    min-width: 110px;
  }

  .error-page__info {
    max-width: 420px;
    margin-right: 65px;
    margin-top: 0;
  }

  .error-page__title span {
    display: block;
  }

  .profile-aside__subscribe {
    padding: 20px 20px 28px;
  }

  .profile-aside {
    width: 240px;
  }

  .profile-main {
    width: calc(100% - 260px);
  }

  .profile-aside__discount {
    padding: 90px 20px 20px;
  }

  .profile-aside__viewed-item-info {
    width: 60%;
  }

  .profile-aside__viewed-item-title {
    font-size: 18px;
    line-height: 125%;
  }

  .profile-aside__subscribe h3 {
    margin-bottom: 20px;
  }

  .profile-orders__item .profile-orders__col:after {
    display: none;
  }

  .profile-orders__item-addr,
  .profile-orders__item-date {
    font-size: 12px;
  }

  .shop-main__items .products-item__img {
    height: 315px;
  }
}

@media screen and (max-width: 1024px) {
  .header-nav {
    display: flex;
    margin-right: auto;
    margin-left: -100px;
  }

  .header-nav li ul {
    padding: 25px 30px 25px 30px !important;
  }

  .header-nav .saint-text {
    font-size: 24px !important;
  }

  .products-cat {
    padding: 60px 30px;
  }

  .products-cat .cbd-cat .content {
    margin-left: 30%;
  }

  .header-nav li {
    margin: 0 7px !important;
    position: relative;
  }

  #main-banner .row {
    display: block;
  }

  #main-banner .col1 {
    width: 100vw;
  }

  #main-banner .col2 {
    width: 100vw;
    padding: 90px 70px;
  }

  .facility .fac-col-1 {
    padding: 0 150px !important;
  }

  .fac-cont {
    padding: 70px 30px !important;
  }

  .know-more {
    display: none;
  }

  .main-logos img {
    margin: 0 25px;
    max-width: 90px;
  }

  .facility-overview .fac-content {
    width: 47%;
  }

  .facility-gallery .container {
    padding: 90px 40px;
  }

  .contact-details .container {
    padding: 70px 40px;
  }

  .discount .wrapper {
    display: flex;
    justify-content: center;
  }

  .booking .row {
    display: block;
  }

  .booking .col {
    width: 100%;
  }

  .col-img {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .wrapper {
    padding: 0 20px 0 20px;
  }

  p {
    font-size: 15px;
  }

  h2 {
    font-size: 51px;
  }

  .main-text {
    font-size: 83px;
  }

  .main-block__content {
    max-width: 575px;
  }

  .trending-tabs li {
    margin-bottom: 15px;
  }

  .trending {
    padding-top: 110px;
    padding-bottom: 110px;
  }

  .main-block {
    position: relative;
    z-index: 1;

    // &:after {
    // 	z-index: -1;
    // 	position: absolute;
    // 	top: 0;
    // 	left: 0;
    // 	width: 100%;
    // 	height: 100%;
    // 	content: '';
    // 	background-color: rgba(34, 34, 34, 0.15);
    // }
    p {
      color: #000;
    }
  }

  .main-logos {
    padding-bottom: 110px;

    img {
      max-width: 85px;
    }
  }

  .discount-info {
    max-width: 380px;
  }

  .discount-info .saint-text {
    font-size: 50px;
  }

  .discount {
    padding: 110px 0;
  }

  .advantages {
    padding-top: 110px;
  }

  .advantages-items {
    margin-left: 0px;
    margin-right: 0px;
  }

  .advantages .advantages-item {
    margin: 0 10px;
  }

  .advantages-item {
    margin: 0 0px;
    width: calc(33.3% - 0px);
  }

  .top-categories {
    padding-top: 110px;
  }

  .top-categories__items {
    margin-left: -10px;
    margin-right: -10px;
  }

  .top-categories__item {
    width: calc(33.3% - 20px);
    margin: 0 10px;
    height: 355px;
  }

  .info-blocks__item-text,
  .info-blocks__item-reverse .info-blocks__item-text {
    padding: 50px 0 40px;
    width: 100%;
  }

  .info-blocks__item-img {
    position: relative;
    width: 100%;
  }

  .info-blocks__item .wrapper {
    flex-direction: column;
    padding-top: 40px;
  }

  .info-blocks__item {
    background-image: none !important;
  }

  .info-blocks__item-text h2,
  .info-blocks__item-text p {
    max-width: 100%;
  }

  .info-blocks {
    padding-top: 40px;
  }

  .arrivals {
    padding-top: 110px;
  }

  .arrivals .trending-top {
    padding-left: 0;
    margin-bottom: 40px;
  }

  .latest-news {
    padding-top: 110px;
  }

  .latest-news .trending-top {
    padding-left: 0;
    margin-bottom: 40px;
  }

  .blog-item__img {
    height: 220px;
  }

  .subscribe {
    margin-top: 110px;
  }

  .subscribe-form form {
    width: calc(100% - 100px);
  }

  .subscribe-form {
    padding: 20px 40px 0;
  }

  h3 {
    font-size: 35px;
    line-height: 40px;
  }

  .subscribe-form .box-field__row {
    margin-top: 20px;
  }

  .subscribe-form__img {
    width: 350px;
  }

  .subscribe-form form {
    width: 100%;
  }

  .insta-photos {
    flex-wrap: wrap;
    margin-top: 110px;
  }

  .subscribe-form form {
    padding-bottom: 20px;
  }

  .detail-block__item-icon {
    width: 55px;
    height: 45px;
    margin-right: 5px;

    i {
      font-size: 34px;
    }
  }

  .detail-block__item-info {
    margin-top: 0;
    font-size: 14px;
    line-height: 16px;

    h6 {
      font-size: 16px;
    }
  }

  .detail-block__item {
    padding: 15px;
    margin: 0 6px;
    width: calc(33.3% - 12px);
  }

  .detail-block__items {
    margin-left: -6px;
    margin-right: -6px;
    margin-bottom: -35px;
  }

  .promo-video {
    padding-top: 110px;
    margin-bottom: 110px;
  }

  .promo-video__nums {
    margin-top: 110px;
  }

  .promo-video__num span {
    font-size: 44px;
    margin-bottom: 30px;
  }

  .testimonials {
    padding-top: 110px;
    margin-bottom: 0px;
  }

  .testimonials-slider {
    padding: 0 65px;
  }

  .testimonials .slick-arrow {
    left: 0;
  }

  .testimonials .slick-arrow.slick-next {
    left: auto;
    right: 0;
  }

  .insta-photos_contacts {
    margin-top: 0;
  }

  .contacts-info {
    padding-top: 110px;
  }

  .all-categories {
    padding-top: 110px;
    padding-bottom: 0;
    margin-bottom: -30px;
  }

  .detail-block_margin {
    padding-bottom: 110px;
  }

  .faq {
    padding-top: 110px;
  }

  .faq-item__content {
    padding-left: 70px;
    padding-right: 70px;
  }

  .blog {
    margin-top: 110px;
  }

  .post {
    padding-top: 110px;
    padding-bottom: 0;
  }

  .post-list li:last-of-type {
    margin-top: 50px;
  }

  .post .discount {
    padding: 110px 0;
  }

  .post .discount .discount-info {
    max-width: 400px;
  }

  .post-bottom__info {
    flex-direction: column;
    align-items: flex-start;
  }

  .post-bottom__tags {
    margin-bottom: 15px;
  }

  .post-bottom__tags span,
  .contacts-info__social span {
    width: 50px;
  }

  .post-bottom,
  .post-comments,
  .post-content {
    padding-left: 15px;
    padding-right: 15px;
  }

  .shop {
    padding-top: 110px;
  }

  .shop-main__filter {
    flex-wrap: wrap;
  }

  .shop-main__select {
    margin-top: 20px;
    width: 100%;

    .jq-selectbox__select {
      width: 100%;
    }
  }

  .shop-main__checkboxes {
    width: 100%;
    justify-content: space-between;

    .checkbox-box {
      margin-right: 0;
      width: calc(50% - 10px);
    }
  }

  .shop-main__items .products-item {
    // width: calc(50% - 30px);
  }

  .product {
    padding-top: 110px;
  }

  .product-content {
    flex-direction: column;
  }

  .product-slider {
    width: 100%;
  }

  .product-info {
    width: 100%;
    margin-top: 40px;
  }

  .product-reviews {
    flex-direction: column;
  }

  .product-detail__items {
    width: 100%;
  }

  .product-detail__form {
    width: 100%;
    margin-top: 40px;
  }

  .product-detail__items .blog-item__link {
    margin-top: 40px;
  }

  .cart-bottom {
    flex-direction: column;
    margin-bottom: 0;
  }

  .cart-bottom__promo,
  .cart-bottom__total {
    width: 100%;
  }

  .cart-bottom__total {
    margin-top: 40px;
  }

  .cart-bottom__promo p {
    max-width: 100%;
  }

  .cart-bottom__promo .contacts-info__social {
    span {
      width: 100%;
    }
  }

  .cart-table {
    overflow-x: scroll;
    margin-left: -40px;
    margin-right: -40px;
    width: calc(100% + 80px);

    &__box {
      min-width: 900px;
    }
  }

  .cart {
    padding-top: 110px;
  }

  .wishlist-buttons {
    margin-left: 0;
  }

  .wishlist {
    padding-top: 110px;
  }

  .checkout {
    padding-top: 110px;
    padding-bottom: 0;
  }

  .checkout-form,
  .checkout-info {
    width: 100%;
  }

  .checkout-info {
    margin-top: 40px;
    padding-top: 0;
  }

  .checkout-content {
    flex-wrap: wrap;
  }

  .checkout-buttons {
    margin-top: 40px;
  }

  .login {
    padding-top: 110px;
  }

  .error-page {
    padding-top: 110px;
  }

  .error-page__content {
    flex-direction: column;
  }

  .error-page__info {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .error-page__title span {
    margin-right: 0;
  }

  .error-page__img {
    text-align: center;
  }

  .profile-content {
    flex-direction: column-reverse;
  }

  .profile-main,
  .profile-aside {
    width: 100%;
  }

  .profile {
    padding-top: 110px;
    padding-bottom: 0;
  }

  .profile-orders__row-head .profile-orders__col:nth-of-type(3),
  .profile-orders__row-head .profile-orders__col:nth-of-type(2) {
    padding-left: 30px;
  }

  .profile-aside {
    margin-top: 40px;
  }

  .profile-aside__subscribe img {
    width: 140px;
  }

  .profile-aside__discount-title {
    max-width: 230px;
    margin-left: auto;
  }

  .products-item__name,
  .blog-item__title {
    font-size: 22px;
  }

  h4 {
    font-size: 30px;
  }

  .nav-tab-list li a {
    padding: 6px 25px;
  }

  .products-item {
    // width: calc(50% - 30px);
  }

  .arrivals {
    padding-top: 40px;
  }

  .detail-block {
    padding-top: 180px;
  }

  .contacts-info__social span {
    width: 100%;
  }

  .cart-table__box {
    min-width: 790px;
  }

  .cart-table__col:first-of-type {
    width: 48%;
  }

  .wishlist .cart-table__col:first-of-type {
    width: 48%;
  }

  .cart-table__col:nth-of-type(3) {
    padding-left: 0;
  }

  .cart-table__col {
    width: 15%;
  }

  .wishlist .cart-table__col {
    width: 19%;
  }

  .checkout-step3 .checkout-info {
    padding-top: 0px;
  }
}

@media screen and (max-width: 767px) {
  .wishlist .cart-table__col {
    width: 16%;
  }

  .header-content {
    display: none !important;
  }

  .scroll-off {

    // overflow: hidden;
    body {
      overflow: hidden;
    }

    .btn-menu {
      // position: fixed;
      // top: 13%;
    }

    .content {
      position: relative;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        background-color: rgba($color: #222222, $alpha: 0.15);
        z-index: 10;
      }
    }
  }

  .wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }

  .main-block {
    // padding-top: 130px;
    // padding-bottom: 60px;
  }

  .main-block__decor {
    display: none;
  }

  .main-block .saint-text {
    font-size: 45px;
  }

  .main-text {
    font-size: 58px;
  }

  .main-block p {
    font-size: 16px;
  }

  .main-block .btn {
    margin-top: 30px;
  }

  .trending-tabs li {
    margin-left: 15px;
    margin-right: 15px;
    width: 100%;
    text-align: center;
    margin-bottom: -1px;
  }

  .products-items {
    margin-left: -15px;
    margin-right: -15px;
    padding: 0 15px;
  }

  .slick-arrow {
    top: 40.5%;
    left: 30px;
    z-index: 1;
  }

  .slick-arrow.slick-next {
    right: 30px;
  }

  .products-item__hover-options i {
    width: 45px;
    height: 45px;
  }

  .products-item__hover .icon-search {
    font-size: 50px;
  }

  .products-item__name {
    font-size: 20px;
    margin-bottom: 0px;
  }

  .products-item__cost {
    font-size: 16px;
  }

  .saint-text {
    font-size: 40px;
  }

  h2 {
    font-size: 36px;
    line-height: 42px;
  }

  .trending {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .trending-top p,
  .products-item__info {
    margin-top: 20px;
  }

  .trending-top {
    margin-bottom: 20px;
  }

  .trending-tabs .tabs {
    margin-bottom: 20px;
  }

  .main-logos {
    flex-wrap: wrap;
    padding-bottom: 35px;
  }

  .main-logos img {
    max-width: 100px;
    margin-bottom: 25px;
  }

  .discount {
    padding: 60px 0;
  }

  .discount-info .saint-text {
    font-size: 45px;
    padding-right: 20px;
  }

  .discount-info p {
    font-size: 16px;
  }

  .discount-info .btn {
    margin-top: 30px;
  }

  .discount-info {
    max-width: 290px;
    text-align: right;
  }

  .post .discount .discount-info {
    text-align: left;
  }

  .discount-contacts .discount-info {
    text-align: left;
  }

  .advantages {
    padding-top: 60px;
  }

  .advantages-items {
    flex-wrap: wrap;
    margin-left: 0;
    margin-right: 0;
  }

  .advantages-item {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 35px;
    width: 100%;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .advantages-item__icon {
    margin-bottom: 25px;
  }

  h4 {
    font-size: 28px;
  }

  .top-categories {
    padding-top: 60px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .top-categories__text {
    padding-left: 0;
    margin-bottom: 20px;
  }

  .top-categories__item {
    width: calc(50% - 20px);
  }

  .info-blocks__item-img {
    height: 335px;
  }

  .info-blocks__item-descr,
  .info-blocks__item-text p {
    margin-top: 15px;
  }

  .info-blocks__item-text .btn {
    margin-top: 30px;
  }

  .info-blocks__item-img-overlay span {
    font-size: 28px;
  }

  .info-blocks__item-img-play {
    width: 85px;
    height: 85px;
  }

  .arrivals {
    padding-top: 60px;
  }

  .arrivals .trending-top {
    margin-bottom: 20px;
  }

  .latest-news {
    padding-top: 60px;
  }

  .latest-news .trending-top {
    margin-bottom: 20px;
  }

  .blog-items,
  .blog-item {
    margin-left: 0;
    margin-right: 0;
  }

  .blog-item {
    width: 100%;
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .latest-news__btn {
    margin-top: 40px;
  }

  .blog-item__title {
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .blog-item p {
    width: 100%;
    margin-bottom: 20px;
  }

  .subscribe {
    margin-top: 60px;
  }

  .subscribe .wrapper {
    padding-left: 0px;
    padding-right: 0px;
  }

  .subscribe-form {
    padding: 55px 15px 0px;
    flex-direction: column-reverse;
  }

  .subscribe-form .box-field__row {
    flex-direction: column;

    .box-field {
      margin-bottom: 10px;
    }

    .box-field,
    .btn {
      width: 100%;
    }
  }

  .subscribe-form form {
    margin-top: 0;
  }

  .box-field .form-control {
    padding: 18px 15px;
  }

  .subscribe-form__img {
    width: 100%;
    text-align: center;
    margin-right: 0;
    margin-bottom: -3px;
  }

  .subscribe-form__img:after {
    left: 34%;
  }

  .subscribe-form form {
    padding-bottom: 0;
  }

  .insta-photos {
    margin-top: 60px;
  }

  .insta-photo {
    width: 50%;
    height: 155px;
  }

  .subscribe-form__img {
    img {
      position: relative;
      min-height: 1px;
    }
  }

  .detail-block {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  h1 {
    font-size: 55px;
    line-height: 58px;
  }

  .detail-block__items {
    margin-bottom: 0;
  }

  .bread-crumbs li {
    font-size: 14px;
  }

  .detail-block__items {
    margin-left: 0;
    margin-right: 0;
    margin-top: 30px;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .detail-block__item {
    margin: 0 auto;
    margin-bottom: 15px;
    width: 100%;
    max-width: 320px;
    justify-content: flex-start;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .detail-block__item-icon {
    margin-right: 25px;
  }

  .detail-block__item-info {
    font-size: 16px;
    line-height: 22px;
  }

  .detail-block__item-info h6 {
    font-size: 18px;
  }

  .promo-video__decor {
    display: none;
  }

  .promo-video {
    padding: 60px 0;
    margin-bottom: 0;
  }

  .promo-video__block {
    height: 355px;
  }

  .promo-video__nums {
    margin-top: 10px;
    padding: 0;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .promo-video__num {
    width: 45%;
    margin-top: 50px;
  }

  .testimonials {
    padding-top: 60px;
  }

  .testimonials-slide {
    display: flex !important;
    flex-direction: column-reverse;
  }

  .testimonials-author {
    max-width: 160px;
    margin: auto;
    margin-top: 0;
    margin-bottom: 30px;
  }

  .testimonials-slider {
    padding: 0;
  }

  .insta-photos_contacts {
    margin-top: 0;
  }

  .contacts-info {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .contacts-info__content {
    flex-direction: column;
    align-items: flex-start;
  }

  .contacts-info__text {
    width: 100%;
    margin-bottom: 20px;
  }

  .contacts-info__text h4 {
    margin-bottom: 20px;
  }

  .contacts-info__social {
    margin-top: 0;
  }

  .discount-contacts .discount-info {
    max-width: 400px;
  }

  .all-categories {
    padding-top: 60px;
    padding-bottom: 0;
    margin-bottom: 0px;
  }

  .all-categories .top-categories__item {
    margin-bottom: 20px;
  }

  .faq {
    padding-top: 60px;
  }

  .faq-more {
    margin-top: 60px;
  }

  .box-field__row-search {
    flex-direction: column;
  }

  .box-field__row-search .box-field {
    width: 100%;
    margin-bottom: 10px;
  }

  .btn-icon {
    justify-content: center;
  }

  .faq-search {
    margin-bottom: 40px;
  }

  .faq-item__head {
    font-size: 16px;
    padding-bottom: 20px;
  }

  .faq-item {
    margin-bottom: 30px;
  }

  .faq-item__head-num {
    margin-right: 15px;
    min-width: 40px;
  }

  .faq-item__content {
    padding: 20px 0;
    padding-top: 0;
    margin-top: 20px;
  }

  .blog {
    margin-top: 60px;

    &-items {
      margin-bottom: 60px;
    }
  }

  .paging-list__link {
    min-width: 35px;
    height: 50px;

    i {
      font-size: 15px;
    }
  }

  .post-bottom,
  .post-comments,
  .post-content {
    padding-left: 0;
    padding-right: 0;
  }

  .bread-crumbs {
    flex-wrap: wrap;
  }

  .post {
    padding-top: 60px;
  }

  .post-top p {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 18px;
  }

  .post-top__info {
    padding: 20px 0 20px;
    flex-wrap: wrap;

    li {
      margin-left: 0;
      width: 50%;
      line-height: 14px;
      margin-bottom: 10px;

      &:nth-of-type(even) {
        text-align: right;
        justify-content: flex-end;
      }
    }
  }

  .post-top__info li i {
    width: 20px;
  }

  .post-content {
    padding-top: 20px;
  }

  .post-content h6 {
    margin-top: 20px;
    padding-top: 20px;
    margin-bottom: 0;
  }

  .post-content blockquote {
    margin-top: 20px;
  }

  blockquote,
  .blockquote {
    padding: 20px;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 134%;
  }

  .blockquote-author {
    font-size: 20px;
    margin-top: 15px;
  }

  .post-list li {
    margin-top: 20px;
    width: 100%;
  }

  .post-list {
    padding-top: 0;
  }

  .post-list li:last-of-type {
    margin-top: 20px;
  }

  .post .discount {
    padding: 60px 0;
    margin-bottom: 60px;
    margin-top: 60px;
  }

  .discount li {
    font-size: 14px;
    line-height: 150%;
  }

  .post .discount {
    position: relative;
    z-index: 1;

    &:after {
      width: 100%;
      height: 100%;
      z-index: -1;
      content: '';
      background-color: rgba(255, 255, 255, 0.5);
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .post-bottom__tags {
    margin-top: 0;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  .post-bottom__tags span {
    margin-bottom: 10px;
  }

  .post-bottom__tags ul {
    flex-wrap: wrap;

    li {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }

  .post-bottom__nav a {
    font-size: 12px;
  }

  .post-bottom__nav a i {
    margin-right: 10px;
    font-size: 14px;
  }

  .post-bottom__nav a:last-of-type i {
    margin-right: 0;
    margin-left: 10px;
  }

  .post-comments {
    margin-top: 60px;
  }

  .post-comments h3 {
    margin-bottom: 10px;
  }

  .post-comment {
    padding: 15px;
    margin-bottom: 15px;
  }

  .post-comment__head {
    flex-wrap: wrap;
  }

  .post-comment__author {
    width: 100%;
  }

  .post-comment__author img {
    margin-right: 15px;
  }

  .post-comment__name {
    margin-right: 0;
  }

  .post-comment__date {
    margin-left: auto;
    width: 150px;
    text-align: right;
  }

  .post-comment__reply {
    margin-top: 10px;
    text-align: right;
    width: 100%;
    justify-content: flex-end;
  }

  .post-comment__content {
    margin-top: 15px;
  }

  .post-comment {
    margin-left: 0;
  }

  .post-comment__form {
    padding: 55px 15px;
    margin-top: 60px;
    margin-left: -15px;
    margin-right: -15px;

    .btn {
      width: 100%;
    }
  }

  .post-comments h3 {
    line-height: 40px;
  }

  .post-comment__form p {
    margin-bottom: 20px;
  }

  .post-comment__form .box-field__row {
    margin-left: 0;
    margin-right: 0;
    flex-wrap: wrap;
  }

  .post-comment__form .box-field__row .box-field {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .shop {
    padding-top: 60px;
  }

  .shop-content {
    flex-wrap: wrap;
  }

  .shop-aside,
  .shop-main {
    width: 100%;
  }

  .shop-aside .box-field__search {
    margin-bottom: 20px;
  }

  .shop-aside__item-title {
    margin-bottom: 10px;
  }

  .shop-aside__item ul li a {
    padding: 5px 0;
  }

  // .shop-aside__item ul li span{
  //   padding: 5px 0 !important;
  // }


  .shop-aside__item {
    margin-bottom: 20px;
  }

  .shop-aside__item .range-slider {
    margin-bottom: 90px;
  }

  .shop-aside__item-product:first-of-type {
    margin-top: 20px;
  }

  .shop-aside__item-product {
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .shop-main {
    margin-top: 40px;
  }

  .shop-main__filter {
    margin-bottom: 20px;
  }

  .shop-main__items {
    .products-item {
      // width: calc(50% - 30px);
    }
  }

  .shop-main__items .products-item__info {
    margin-top: 10px;
  }

  .shop-main__items .products-item__img {
    height: 440px;
  }

  .shop-decor {
    display: none;
  }

  .discount li {
    font-size: 16px;
  }

  .post-comment__name:after {
    display: none;
  }

  .product {
    padding-top: 60px;
  }

  .product-stock {
    margin-top: 10px;
  }

  .product-price {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 25px;

    span {
      font-size: 16px;
    }
  }

  .product-info .contacts-info__social {
    margin-top: 20px;
    flex-direction: column;
    align-items: flex-start;

    span {
      display: block;
      margin-right: 0;
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .product-options {
    margin-top: 25px;
    padding-top: 20px;
    flex-direction: column;
  }

  .product-info__color {
    margin-bottom: 20px;

    span {
      margin-bottom: 10px;
    }
  }

  .product-info__quantity-title {
    margin-bottom: 10px;
  }

  .product-buttons {
    margin-top: 40px;
    flex-wrap: wrap;

    .btn {
      width: 100%;

      &:first-of-type {
        margin-bottom: 10px;
      }
    }
  }

  .review-item {
    padding: 15px;
    margin-bottom: 15px;
  }

  .review-item__head {
    flex-wrap: wrap;
  }

  .review-item__author {
    width: 100%;
  }

  .review-item__name {
    margin-right: 0;
  }

  .review-item__author img {
    margin-right: 15px;
  }

  .review-item__date {
    margin-left: auto;
    width: 150px;
    text-align: right;
  }

  .review-item__rating {
    margin-top: 0;
    text-align: right;
    width: 100%;
    justify-content: flex-end;
    display: flex;
  }

  .review-item__content {
    margin-top: 10px;
  }

  .review-item__name:after,
  .review-item__date:after {
    display: none;
  }

  .product-detail__items .blog-item__link {
    margin-top: 20px;
  }

  .product-detail__form {
    margin-top: 30px;
    width: calc(100% + 30px);
  }

  .cart {
    padding-top: 60px;

    &-table {
      margin-left: -15px;
      margin-right: -15px;
      width: calc(100% + 30px);
    }
  }

  .cart-bottom__total {
    padding: 15px;
    margin-top: 30px;
  }

  .cart-bottom__promo .contacts-info__social {
    margin-top: 20px;
  }

  .cart-bottom__promo-form .box-field__row {
    flex-direction: column;
  }

  .cart-bottom__promo-form .box-field__row .box-field {
    width: 100%;
    margin-bottom: 10px;
  }

  .cart-bottom__promo-form {
    margin-bottom: 30px;
  }

  .cart-bottom {
    margin-top: 30px;
  }

  .cart-table__row {
    margin-bottom: 10px;
    padding: 10px 0;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .cart-table__row-head {
    font-size: 18px;
  }

  .cart-table__col:first-of-type {
    padding-left: 15px;
  }

  .wishlist {
    padding-top: 60px;
  }

  .wishlist-buttons {
    margin-top: 30px;

    .btn {
      width: 100%;
      text-align: center;
      margin-right: 0;
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .checkout {
    padding-top: 60px;
  }

  .checkout-form__item .box-field__row {
    flex-direction: column;
  }

  .checkout-form__item .box-field__row .box-field {
    width: 100%;
    margin-bottom: 10px;
  }

  .checkout-form__item .jq-selectbox__select {
    padding: 17px 15px;
  }

  .checkout-form__item .jq-selectbox__trigger {
    right: 3px;
  }

  .checkout-form__item .checkbox-box {
    margin-top: 20px;
  }

  .checkout-buttons {
    flex-wrap: wrap;

    .btn {
      width: 100%;
      text-align: center;
      margin-right: 0;
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .cart-bottom__total-delivery span.cart-bottom__total-delivery-date {
    font-size: 14px;
  }

  .checkout-order__item-img {
    min-width: 95px;
    width: 95px;
    height: auto;
  }

  .checkout-order__item {
    align-items: center;
  }

  .checkout-payment__item {
    padding: 20px 10px;
  }

  .checkout-payment__item-content {
    padding-top: 13px;
  }

  .checkout-payment .checkout-buttons {
    margin-top: 40px;
  }

  .checkout-payment__item-content .box-field__row {
    margin-left: 0;
    margin-right: 0;
    flex-wrap: wrap;
    margin-top: 10px;
  }

  .checkout-payment__item-content .box-field__row .box-field {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    margin-bottom: 10px;
  }

  .checkout-payment__item.active {
    padding-bottom: 10px;
  }

  .radio-box__info-content {
    bottom: auto;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .radio-box__info-content:after {
    display: none;
  }

  .checkout-purchase__list li span {
    width: 50%;
  }

  .checkout-step3 .checkout-info {
    padding-top: 0;
  }

  .checkout-purchase__link {
    margin-top: 20px;
  }

  .checkout-purchase__list {
    margin-top: 20px;

    li {
      padding: 7px 15px;
      align-items: center;
      line-height: 120%;
    }
  }

  .checkout-step3 .cart-bottom__total .btn {
    margin-top: 60px;
  }

  .login {
    padding-top: 60px;

    &-form {
      padding: 35px 15px;

      &__bottom {
        flex-direction: column;
        margin-top: 10px;
      }
    }
  }

  .login-form .checkbox-box {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .login-form h3,
  .login-form__social {
    margin-bottom: 20px;
  }

  .registration .box-field__row {
    margin-left: 0;
    margin-right: 0;
  }

  .registration .box-field__row .box-field {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .registration .box-field__row span {
    margin-top: 5px;
    margin-left: 0;
  }

  .registration .login-form .checkbox-box {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .error-page {
    padding-top: 60px;

    .box-field__row-search {
      flex-direction: row;
    }
  }

  .error-page__title span {
    font-size: 100px;
  }

  .error-page__title {
    font-size: 30px;
  }

  .error-page__subtitle {
    font-size: 14px;
  }

  .profile {
    padding-top: 60px;

    .nav-tab-list {
      flex-wrap: wrap;
      margin-bottom: 10px;

      li {
        margin-right: 0;
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }

  .profile-orders__row-head {
    display: none;
  }

  .profile-aside {
    margin-top: 20px;
  }

  .profile-aside__subscribe {
    padding: 15px;
  }

  .profile-aside__viewed {
    margin-top: 20px;
  }

  .profile-aside__viewed-item {
    margin-top: 20px;
  }

  .profile-aside__discount {
    padding: 50px 15px 15px;
    margin-top: 20px;
  }

  .profile-orders__item .profile-orders__row {
    padding: 15px 0;
    flex-direction: column;
    align-items: flex-start;
  }

  .profile-orders__content ul li,
  .profile-orders__content ul li:last-of-type {
    padding: 8px 15px;
  }

  .profile-orders__content ul li:last-of-type {
    flex-direction: column;
    align-items: flex-start;
  }

  .profile-orders__col:nth-of-type(1),
  .profile-orders__col:nth-of-type(2),
  .profile-orders__col:nth-of-type(3),
  .profile-orders__col:nth-of-type(4) {
    width: 100%;
    text-align: left;
    padding: 5px 15px;

    br {
      display: none;
    }
  }

  .profile-orders__item .profile-orders__col:nth-of-type(3) {
    padding: 5px 15px;
  }

  .profile-orders__item-addr,
  .profile-orders__item-date {
    font-size: 14px;
    line-height: 120%;
  }

  .profile-orders__item .profile-orders__col-btn {
    right: 15px;
  }

  .profile-orders__col-mob {
    display: block;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 5px 0;
  }

  // .products-item {
  //   // width: calc(100% - 30px);
  // }
  .info-blocks__item-img {
    height: 370px;
  }

  .info-blocks__item-img-overlay span {
    margin-bottom: 20px;
  }

  .arrivals {
    .trending-top {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .insta-photo {
    height: 285px;
  }

  .testimonials-slide p {
    font-size: 18px;
  }

  .faq-item__content {
    padding-left: 55px;
  }

  .product-slider__main img {
    height: 630px;
  }

  .product-slider__nav-item {
    height: 152px;
  }

  h5,
  .title5 {
    line-height: 115%;
  }

  .radio-box__info:hover .radio-box__info-content {
    position: absolute;
    top: auto;
    bottom: 30px;
    transform: translateX(-50%);
  }

  .radio-box__info {
    position: initial;

    &:hover {
      .radio-box__info-content {
        opacity: 0;
        visibility: hidden;
        display: none;
      }
    }
  }

  .radio-box__info-content {
    &.active {
      opacity: 1 !important;
      visibility: visible !important;
      display: block !important;
    }
  }
}

@media screen and (max-width: 600px) {
  .checkout .cart-bottom__total-promo {
    display: inline-block;
  }

  .offices {
    padding: 40px 0 20px 0 !important;
  }

  .offices .row {
    display: block !important;
  }

  .offices .col {
    width: 100% !important;
    margin-bottom: 20px !important;
  }

  .new-mocra-service .container {
    display: block !important;
  }

  .products-cat {
    padding: 60px 20px 20px 20px !important;
  }

  .checkout .cart-bottom__total-promo span {
    margin-left: 60px;
  }

  .js-menu {
    width: 95vw;
    max-width: 95vw;
  }

  .contact-bread {
    padding-top: 90px !important;
  }

  .booking .container {
    padding: 0 30px;
  }

  .contact-details .row {
    display: block;
  }

  .contact-details .col {
    width: 100%;
    padding-top: 15px;
  }

  .discount-contacts .main-text {
    font-size: 30px !important;
  }

  .main-checks {
    margin-left: 0px;
  }

  .cart .row {
    display: block;
  }

  .login-form h3,
  .login-form__social {
    margin-bottom: 6px;
  }

  .register-text {
    font-size: 14px;
    margin-bottom: 30px;
  }

  .register-checkboxes .styled-checkbox+label {
    display: block;
  }

  .register-checkboxes .styled-checkbox:checked+label:before {
    margin-top: 0px;
  }

  .register-checkboxes br {
    display: none;
  }

  .registration .fa-xmark {
    position: absolute;
    top: 20px;
    right: 40px;
  }

  #login-form .row {
    display: block !important;
  }

  #login-form .col {
    width: 100% !important;
  }

  .cart .row .col2,
  .cart .row .col1 {
    width: 95%;
  }

  iframe {
    height: 320px;
  }

  .promise-main {
    margin-top: 30px;
  }

  .subscribe-form .box-field__row {
    width: 90vw;
  }

  .promise-main .row {
    display: block;
    margin-bottom: 12px;
  }

  .promise-main .col2 {
    width: 100%;
  }

  .promise-main p {
    padding-right: 30px;
  }

  .promise-main h5 {
    font-size: 19px;
  }

  .promise-sec .container {
    padding: 90px 20px;
  }

  .corporate-sec {
    padding: 30px 30px;
  }

  .corporate-sec .row {
    display: block;
  }

  .checkout .cart-bottom__total {
    width: 462px;
  }

  .corporate-sec .col1 {
    width: 96%;
  }

  .responsible-sec {
    padding: 30px 30px;
    background-color: #5c7842a3;
    background-blend-mode: color;
  }

  .responsible-sec .col1 {
    display: none;
  }

  .sustain-sec {
    padding: 30px 30px;
    background-size: cover;
  }

  .sustain-sec .row {
    display: block;
  }

  .sustain-sec .col1 {
    width: 96%;
  }

  .sustain-sec h2,
  .responsible-sec h2 {
    font-size: 25px;
  }

  .mission-sec {
    padding: 30px 0;
  }

  .mission-sec h2 {
    padding: 10px 20px;
  }

  .mission-sec p {
    padding: 20px 0px 0px 20px;
    width: 94%;
  }


  #about-col-1 {
    display: none;
  }

  .about-main-section .col {
    flex: 0 1 100%;
    width: 100%;
  }

  .about-main-section #about-col-2 {
    padding: 60px 10px;
  }

  .about-certs .container {
    padding: 50px 30px;
  }

  .about-certs .row {
    display: block;
  }

  .about-certs .col {
    flex: 50% 1;
    width: 100%;
  }

  .liquid .liq-cont {
    padding: 0 30px !important;
  }

  .journey-banner h3 {
    font-size: 38px !important;
  }

  .terms {
    padding: 60px 30px;
  }

  .shop-private img {
    display: none;
  }

  .shop-private .row {
    display: block;
  }

  .shop-private .row .col {
    width: 100%;
  }

  #shop-private-1 a {
    font-size: 15px;
    margin-bottom: 30px;
  }

  .shop-private .container {
    padding: 0px 60px;
  }

  .private-button {
    padding: 40px 0;
  }

  .gallery {
    display: none;
  }

  .slider {
    height: 290px !important;
  }

  .container-shop {
    padding: 0 30px;
  }

  .react-multi-carousel-item {
    width: 100%;
  }

  .react-multi-carousel-dot-list {
    display: none !important;
  }

  .slider ul li {
    background-size: contain;
    background-position-x: -170px !important;
  }

  .shop-banner h1 {
    font-size: 36px !important;
    line-height: 40px;
  }

  .shop-banner .btn {
    padding: 0 10px;
    font-size: 14px;
    letter-spacing: 0px;
  }

  .shop-banner .saint-text {
    font-size: 24px !important;
  }

  .shop-banner {
    margin-left: 20px !important;
  }

  .how-works-sec h2 {
    font-size: 27px;
  }

  .capabilities .btn-1,
  .capabilities .btn-2,
  .capabilities .btn-3 {
    margin-top: 25px;
  }

  .our-location-capability {
    padding: 60px 20px;
  }

  .maps {
    padding: 50px 10px;
  }

  .check {
    display: block;
    margin-right: 0;
    width: 100%;
    margin-right: 0;
  }

  .check2 {
    margin-left: 0;
  }

  .liquid {
    padding-top: 0;
  }

  .liquid p {
    width: 95%;
  }

  .filling .row {
    display: block;
  }

  #filling-col {
    padding: 40px 30px;
  }

  #filling-col p {
    width: 95%;
  }

  ul.points {
    padding-left: 40px;
  }

  .filling img {
    height: auto;
  }

  .started {
    padding: 60px 14px;
  }

  .filling .row .col {
    flex: 50% 1;
    width: 100%;
  }

  .products-cat br {
    display: none;
  }

  .products-cat p {
    font-size: 17px;
    padding-right: 20px;
    font-weight: 600;
  }

  #main-banner .btn {
    padding: 0 32px;
  }

  .commitment a {
    padding: 0 24px;
  }

  .turnkey h2 {
    font-size: 31px;
    line-height: 38px;
  }

  .private-faq .faq-item__content p {
    white-space: initial;
  }

  #private-hiw img,
  .how-works-sec img {
    width: 100% !important;
    height: auto !important;
  }

  #private-hiw {
    padding-top: 60px;
  }

  .sub-menu {
    display: none;
  }

  .detail-block__content h1 {
    font-size: 40px;
    line-height: 50px;
  }

  .prods-overview {
    padding: 50px 0 0px 0;
    text-align: center;
  }

  .capabilities .container-fluid {
    padding: 80px 0 80px 0px;
  }

  .capabilities .capa-content-main {
    width: auto;
    padding: 50px 18px;
  }

  .capabilities p#main-capa-cont {
    padding: 0 0px;
    margin-top: 20px;
  }

  .turnkey .info-blocks__item-reverse .info-blocks__item-text {
    padding: 30px 10px 60px 10px !important;
  }

  .facility p {
    padding-bottom: 20px;
    font-size: 14px;
  }

  .fac-cont {
    padding: 70px 5px !important;
  }

  .header-nav {
    display: flex;
    margin-right: auto;
    margin-left: -10px;
  }

  #pd-overview {
    padding: 60px 20px;
    margin-bottom: 30px;
  }

  #cm-overview {
    padding: 60px 20px;
    background-size: cover;
    background-position-x: right;
    margin-bottom: 30px;
    padding: 40px 20px;
    background-size: cover;
    background-position-x: right;
    margin-bottom: 30px;
    background-color: #ddd7d3;
  }

  .products-cat {
    padding: 60px 10px;
  }

  #pd-overview h2,
  #cm-overview h2,
  #pl-overview h2 {
    font-size: 24px;
    line-height: 32px;
  }

  #cm-overview .content {
    margin-left: -38%;
  }

  #pl-overview {
    padding: 40px 20px;
  }

  .prods-overview p {
    padding: 0 10px;
  }

  .products-cat .skin-care {
    padding: 70px 30px;
  }

  .cbd-cat .content {
    margin-left: 0% !important;
  }

  .products-cat .cbd-cat {
    padding: 84px 30px;
    background-color: #f8eacf57;
    background-blend-mode: color;
  }

  .products-cat .otc {
    padding: 100px 30px;
  }

  .organic .content {
    margin-left: 0% !important;
  }

  .products-cat .organic {
    padding: 115px 30px;
    background-color: #cae2cc61;
    background-blend-mode: color;
  }

  .products-cat .makeup {
    padding: 44px 30px;
    margin-bottom: 60px;
  }

  .how-works-sec .how-cont h3 {
    font-size: 20px;
    margin-bottom: 5px;
    padding-left: 5px;
    line-height: 30px;
  }

  .how-works-sec .how-cont p {
    padding-left: 5px !important;
  }

  .support .points li {
    width: 100% !important;
  }

  .support .support-head p {
    width: 100% !important;
  }

  .support {
    padding: 60px 20px !important;
  }

  .liquid #liq-img {
    margin-top: -40px !important;
  }

  #main-banner .col1 {
    width: 100% !important;
    margin-right: 0px !important;
    padding: 50px 20px !important;
    background-size: cover !important;
    margin-bottom: 0px;
    background-position-y: 0;
  }

  #main-banner .col2 {
    width: 100% !important;
    margin-right: 0px !important;
    padding: 50px 20px !important;
    background-size: cover !important;
    background-position-y: 0;
    background-position-x: 0;
  }

  #product-dev,
  #contact-bread {
    background-position-y: 0 !important;
  }

  .powder {
    display: none;
  }

  #main-banner .banner-cont {
    padding: 60px 0px !important;
    width: 450px !important;
    margin-left: 5px !important;
  }

  #main-banner h1 {
    font-size: 30px;
    line-height: 32px;
    margin-top: 0;
  }

  .saint-text {
    font-size: 22px !important;
  }

  #main-banner .container {
    padding-top: 0px;
  }

  #main-banner .row {
    display: block !important;
  }

  .custom-info p {
    font-size: 17px;
    margin-bottom: 15px;
    width: 100% !important;
  }

  #main-banner .container {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }

  .commitment .col2 {
    display: none !important;
  }

  .commitment .col1 {
    padding: 60px 20px !important;
  }

  .commitment .cont {
    padding: 0 20px !important;
  }

  .info-blocks {
    padding-top: 0px;
  }

  .info-blocks__item-text {
    background-image: none !important;
  }

  .info-blocks__item-img img {
    margin-left: -3vw !important;
  }

  .header-content {
    padding: 20px 30px 20px;
  }

  .trending {
    background-image: none;
  }

  .certs {
    display: none;
  }

  .info-blocks__item-reverse .info-blocks__item-text {
    padding: 10px 10px 23px 10px !important;
  }

  .facility-overview .fac-content {
    width: 92%;
  }

  .facility-overview {
    height: 50vh;
  }

  .experience {
    display: none;
  }

  .facility-gallery img {
    width: 277px;
    height: 207px;
    margin-bottom: 20px;
  }

  .facility-gallery .row {
    display: block;
  }

  .facility-gallery .col {
    width: 100%;
  }

  .facility-gallery .container {
    padding: 90px 90px;
  }

  .img-sec .row {
    display: block !important;
  }

  .img-sec .column {
    width: 100% !important;
    flex: 100% 1 !important;
    ;
  }

  .img-sec .img-col-1 {
    padding: 18vh 0 !important;
  }

  .img-sec .img-col-2 {
    padding: 11vh 0 !important;
  }

  .img-sec .img-col-3 {
    padding: 18vh 0 !important;
  }

  .latest-news {
    background-image: none;
  }

  .subscribe-form {
    padding: 35px 15px 35px;
    -webkit-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .subscribe-form__img img {
    display: none;
  }

  #root,
  #root2 {
    height: 65vh !important;
  }

  .carousel-heading {
    padding: 30px !important;
    top: 56% !important;
  }

  .carousel-heading p {
    margin-left: 8px;
    width: 100% !important;
    font-size: 18px;
  }

  .carousel-heading h2 {
    font-size: 71px !important;
    margin-bottom: 40px;
  }

  .discount-info {
    max-width: 290px;
    text-align: left !important;
  }

  .discount-about .wrapper {
    display: flex;
    justify-content: flex-start !important;
  }

  .advantages-item {
    width: calc(100.3% - 60px);
    text-align: center;
    margin-bottom: 40px !important;
  }

  .header-content.fixed {
    padding: 20px 30px 20px;
  }

  .develop-sec .row-1 p {
    padding: 15px 20px !important;
  }

  .develop-sec .row {
    display: block !important;
  }

  .develop-sec .column {
    -webkit-flex: 50% 1;
    flex: 100% 1;
    width: 100%;
    margin-bottom: 30px;
  }

  .develop-sec .img-col-1,
  .develop-sec .img-col-2,
  .develop-sec .img-col-3 {
    padding: 18vh 0 !important;
  }

  .custom-info .row {
    display: block !important;
  }

  .custom-info {
    padding: 50px 20px !important;
    background: none !important;
  }

  .custom-info .column {
    width: 100% !important;
    margin-bottom: 0px !important;
  }

  .facility .img-col {
    display: none !important;
  }

  .facility .row {
    display: block !important;
  }

  .know-more {
    padding: 0 20px !important;
  }

  .know-more iframe {
    width: 500px !important;
    height: 320px !important;
    margin-top: 30px;
  }

  .know-more .col1 {
    width: 100% !important;
  }

  .brand-main .col {
    flex: 100% 1 !important;
    width: 100% !important;
    margin-bottom: 30px;
  }

  .faq-item__content {
    padding-left: 15px;
  }

  .private-faq {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .branding .brand-main .row {
    display: block !important;
  }

  .advantages h4 {
    font-size: 27px !important;
  }

  .branding {
    padding: 60px 20px !important;
    margin-top: 30px !important;
  }

  .subscribe {
    margin-bottom: 0px !important;
  }

  .advantages .wrapper {
    padding: 0 0px 0 0px !important;
  }

  .facility .fac-col-1 {
    margin-top: 0px !important;
    padding: 0 0 !important;
  }

  .facility {
    background-image: url(../../../public/assets/img/facility.jpg);
    padding: 40px 20px !important;
    margin-bottom: 0px !important;
  }

  .promise-main img {
    display: none !important;
  }

  .carousel-slide__content {
    width: 390px;
  }

  .carousel__slide {
    padding-left: 0 !important;
  }

  .help p {
    padding: 10px 10px !important;
    margin-bottom: 10px;
  }

  .main-logos img {
    margin: 0 0px;
    max-width: 110px;
  }

  .main-logos img {
    width: 100%;
    max-width: 100%;
    margin-bottom: 25px;
  }

  .help {
    background-color: #f2e7d6;
    padding: 30px 0px !important;
  }

  .how-works-sec .how-head p {
    padding: 20px 10px !important;
  }

  .booking .discount-info {
    margin-right: 0 !important;
  }

  .how-works-sec .row {
    display: block !important;
  }

  .how-works-sec img {
    margin-bottom: 10px;
  }

  .custom-faq {
    background-image: none !important;
  }

  .info-blocks__item-reverse .info-blocks__item-img {
    left: 10px;
    right: 0;
  }

  #search input[type=search] {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -51px;
    width: 90% !important;
    margin-left: 5% !important;
    color: #878686;
    background: transparent;
    border-top: 2px solid #878686;
    border-bottom: 2px solid #878686;
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;
    font-size: 32px !important;
    font-family: Roboto;
    font-weight: 300;
    text-align: center;
    outline: none;
    padding: 10px;
  }

  .aboutsec {
    display: none !important;
  }

  .main-block {
    // background-image: url(../../../public/assets/img/main-bg-sm.jpg) !important;

    &.load-bg {
      background-image: none;
    }
  }

  .insta-photo {
    height: 155px;
  }

  .top-categories__items {
    margin-left: 0;
    margin-right: 0;
    flex-wrap: wrap;
  }

  .top-categories__item {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 15px;
    width: 100%;
    height: 345px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .all-categories .top-categories__item {
    margin-bottom: 15px;
  }

  .shop-main__items {
    margin-left: 0;
    margin-right: 0;

    .products-item {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      margin-bottom: 20px;
    }
  }

  .product-slider__main img {
    height: 350px;
  }

  .product-slider__nav-item {
    height: 80px;
  }
}

@media screen and (max-width: 428px) {
  .facility-gallery .container {
    padding: 90px 60px;
  }

  .discount-contacts .main-text {
    margin-bottom: 22px;
    font-size: 55px;
  }
}



.categories-bread .wrapper {
  min-width: 320px;
  max-width: 1630px;
  padding: 0 40px 0 40px;
  margin: 0 auto;
  position: relative;
}

#main-categories .row {
  display: flex;
  margin-bottom: 90px;
}

#main-categories .row .col {
  flex: 33.33%;
  width: 33.33%;
  text-align: center;
}

#main-categories h6 {
  font-size: 17px;
  font-weight: 600;
}

.shop-main__filter .shop-main__select {
  float: right;
}

.shop .wrapper {
  min-width: 320px;
  max-width: 1350px;
  padding: 0 40px 0 40px;
  margin: 0 auto;
  position: relative;
}

.shop .react-dropdown .Dropdown-control {
  background-color: #e4e7ef;
  border: 1px solid #000000 !important;
  color: #666666;
  cursor: pointer;
  outline: none;
  padding: 14px 45px 12px 20px;
  font-size: 15px;
}

.shop .box-field .form-control {
  border: 1px solid #000000;
  height: 52px;
  padding: 18px 20px;
  border-right: none;
}

.shop .box-field {
  margin-bottom: 60px;
}

.scroll-to-top {
  background-color: #eddfcc !important;
  width: 60px !important;
  height: 60px !important;
  bottom: 100px !important;
}

.points {
  padding-left: 45px;
}

.points p {
  display: list-item !important;
}

#private-hiw img,
.how-works-sec img {
  width: 568px;
  height: 426px;
  object-fit: cover;
}

#custom-branding-id h3 {
  font-weight: 600;
}

.help h3 {
  font-weight: 600;
}

.powder .saint-text {
  color: #fff !important;
}

// .powder h3 {
//   font-weight: 600;
// }

.powder #head {
  font-style: italic;
  font-weight: 700;
}

.liquid h3 {
  font-weight: 600;
}

.liquid #liq-head {
  font-style: italic;
  font-weight: 700;
  margin-top: 20px;
}

.support h3 {
  font-weight: 600;
}

.shop-menu .main {
  color: #b3724f;
}

.shop-menu a {
  display: list-item;
  display: list-item;
  margin-bottom: 10px;
  margin-top: 10px;
  color: #000;
  font-size: 15px;
  line-height: 17px;
  position: relative;
  text-transform: uppercase;
  z-index: 2;
  font-weight: 600;
}

.capabilities h4 {
  font-weight: 600;
}

.our-location-capability h3 {
  font-weight: 600;
}

.shop-menu .row {
  display: flex;
}

.shop-menu .col {
  flex: 25%;
  width: 25%;
}

.shop .box-field .form-control {
  height: 52px;
  padding: 18px 20px;
  border: 1px solid #000;
}

.shop-menu .saint-text {
  font-size: 25px;
  color: #d3a482;
  margin-top: 20px;
}

.shop-menu .shopsub a {
  display: list-item;
  margin-bottom: 10px;
  margin-top: 10px;
}

.shop-menu h6 {
  margin-top: 10px;
}

h6.saint-text.last {
  margin-top: 25px;
}

.shop-menu .last-item {
  text-decoration: underline;
  font-weight: 600;
  font-size: 17px;
}

.shop-menu .last-item-2 {
  text-decoration: underline;
  font-weight: 600;
  font-size: 17px;
}

.shop-menu .subsub {
  margin-bottom: 30px;
}

.shop-menu .mens {
  margin-bottom: 45px;
}

.shop-menu .last-final {
  margin-top: 0;
}

.prices {
  display: none !important;
}


.prices.current {
  display: flex !important;
}


.slick-current img {
  border: 2px solid #878686;
}

.slick-current img:nth-child(1) {
  border: none;
}

.img-fluid {
  max-width: 100%;
  height: auto !important;
}

.soon {
  background-image: url('../../../public/assets/img/soon.png');

}

.soon .wrapper {
  padding: 60px;
}

.soon-cont {
  float: right;
  text-align: center;
}

.soon-cont h3 {
  color: #000;
  font-weight: 600;
  margin-bottom: 10px;
}

.soon-cont h2 {
  color: #fff;
  margin-bottom: 10px;
}

.soon-cont p {
  color: #000;
  font-weight: 600;
  margin-bottom: 10px;

}

.soon-cont a {
  display: block;
  margin-top: 25px;
}

// .soon {
//   margin-top: 270px;
// }


.white-capabilities img {
  object-fit: fill !important;
  width: 100% !important;
  height: 100% !important;
  margin-top: 0 !important;
}


@media only screen and (min-width: 768px) {
  .mobile-header-bar {
    display: none;
  }
}


.thankyou {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  background-image: url('../../../public/assets/img/register-bg.png');
}

.thankyou .thankyou-main {
  background-color: #fff3ea;
  margin: 90px 0;
  text-align: center;
  padding: 60px 180px;
}

.thankyou h2 {
  color: #895e2e;
  font-size: 42px;
  margin-bottom: 15px;
}

.thankyou p {
  font-weight: 600;
  font-size: 15px;
}

.thankyou .thankyou-btn {
  text-align: center;
}

.thankyou .container {
  max-width: 1075px;
}

.thankyou-contact {
  color: #b39884;
  margin: 15px 0;
}

.thankyou a {
  background-color: #e0cab1;
  width: 55%;
  margin-top: 25px;
}

.thankyou img {
  margin-top: 20px;
}

#subscribed {
  padding: 60px 225px;
}

.location-content img {
  margin-bottom: 20px;
}

.header-nav li:hover #service-sub-menu ul {
  opacity: 1;
  visibility: visible;
  display: flex;
  justify-content: flex-start;
}


#service-sub-menu ul li {
  margin-right: 80px;
}

.indent {
  margin-left: 30px;
}

.faq-content-para {
  line-height: 25px;
}

#prod-start span {
  color: #b3724f;
}


.reset-btn {
  font-size: 14px;
  color: #90675a;
}


#prod-start p {
  padding: 10px 30px;
  margin-bottom: 10px;
}

.chat {
  width: 60px;
  height: 60px;
  background-color: #eddfcc;
  position: fixed;
  bottom: 20px;
  right: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat i {
  color: #000;
}

.checkout-payment__item.active .checkmark {
  background-color: #eddfcc;
}

.checkout-payment__item.active .checkmark {
  background-color: #878787;
  height: 15px;
  width: 15px;
  display: block;
  border-radius: 100%;
  position: absolute;
  top: 3px;
  left: 3px;
}

input.productQty {
  height: 35px;
  width: 68px;
}

.cart .fa-xmark {
  margin-left: 20px;
  margin-top: 0;
  background: none;
  border: none;
  font-size: 18px;
  color: red;
}

.checkout-shipping input[type="radio"] {
  -webkit-appearance: radio;
  margin-bottom: 10px;
}

.checkout-shipping label {
  font-size: 16px;
  color: #000;
  margin-right: 26px;
}

.cart-table__price input.productQty {
  height: 35px;
  width: 56px;
  padding-left: 10px;
  border: 2px solid #878787a3;
  font-weight: 700;
  color: #878787a3;
}

.offices {
  text-align: center;
  padding: 60px 0;
}

.offices .row {
  display: flex;
}

.offices .col {
  flex: 50%;
  width: 50%;
}

.offices a {
  color: #000;
}

.offices .wrapper {
  max-width: 1340px;
}

.offices h6 {
  font-weight: 700;
}

// .products-cat .cbd-cat {
//   margin-bottom: 0px;
// }

.products-cat {
  padding: 60px 210px 20px 210px;
}


.reset .login-form {
  padding: 90px 100px;
}

.reset {
  padding-bottom: 150px;
  padding-top: 150px;
}

.checkout-shipping {
  display: inherit;
}

.cart-bottom__total-num input[type=radio] {
  -webkit-appearance: radio;
  margin-bottom: 10px;
}

.shipping {
  display: block;
}

.shipping span {
  float: right;
}

.ship-options {
  padding-top: 15px;
}

.ship-options label {
  font-size: 14px;
}

.ship-options input {
  margin-bottom: 15px !important;
  margin-right: 10px;
  -webkit-appearance: auto;
}

.other-address .check label::before {
  background: #eee1ce;
  margin-top: 4px;
}

.different-form {
  display: none;
}

.other-address {
  margin-top: 52px;
}

.other-address .check label {
  font-size: 23px;
  font-weight: 600;
}

.other-address .styled-checkbox:checked+label:after {
  top: 13px;
}

.different-form .box-field__row .box-field {
  width: 50%;
}

.different-form .box-field__row .box-field:nth-child(1) {
  margin-right: 15px;
}

.fedex-div {
  margin-bottom: 30px;
  border: 1px solid #eeeeee;
  padding: 11px 39px;
  /* padding-bottom: 19px; */
}

.own-account {
  padding: 30px 0 !important;
  border: none !important;
  display: none;
}

.own-account label {
  font-weight: 600;
}

.own-account textarea {
  width: 100%;
  margin-top: 15px;
}

.details-cart {
  font-size: 13px;
  width: 88%;
}


.header-nav li a:after {
  left: 15% !important;
}

.mens-link:hover:after {
  content: none;
}

// * Dropdown content (hidden by default)
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 100vw;
  left: 0;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  top: 47px;
}

.dropdown {
  position: static !important;
}

/* Mega Menu header, if needed */
.dropdown-content .header {
  background: red;
  padding: 16px;
  color: white;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Create three equal columns that floats next to each other */
.dropdown-content .column {
  float: left;
  width: 33.33%;
  padding: 10px;
  background-color: #ccc;
  height: 250px;
}

/* Style links inside the columns */
.dropdown-content .column a {
  float: none;
  color: black;
  padding: 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

/* Add a background color on hover */
.dropdown-content .column a:hover {
  background-color: #ddd;
}

/* Clear floats after the columns */
.dropdown-content .row:after {
  content: "";
  display: table;
  clear: both;
}

.header-nav li:hover .ahop-menu ul {
  display: flex;
  justify-content: space-around;
}

.header-nav li ul {
  position: absolute;
  background: #f1f1f1;
  padding: 25px 120px 25px 180px;
  z-index: 999;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.ship-options i {
  padding-left: 15px;
}

video {
  width: 100%;
}

.custom-margin input {
  margin-top: 10px;
}

.custom-margin label {
  font-weight: 500;
  margin-left: 05px;

}

.products-item {
  cursor: pointer;
}

.hidden {
  display: none;
  padding-bottom: 10px;
}

// .qty-select,
// .priceandqty {
//   display: none !important;
// }


.card-var {
  display: flex;
  gap: 20px;
}

.stripe-pay input {
  margin-top: 10px;
  background-color: #fff !important;
}

.promo-row {
  display: flex;
  gap: 10px;
  background: #fcf8f5;
}

.image-box {
  width: 4rem;
  height: 4rem;
  border: 1px solid #4b4a4f1f;
}

p.promo-title strong {
  font-weight: 600;
}

.promo-gift-content {
  padding: 3px 0px 0px 5px;
}

.success {
  padding-bottom: 20px;
  color: #d05278;
  line-height: 20px;
}


.catalogs-sec {
  padding: 5% 10% 0 10%;
}

.catalogs-sec .row {
  display: flex;
  flex-wrap: wrap;
  gap: 28px;
  text-align: center;
}

.catalogs-sec .col {
  flex: 0 0 23%;
}

.catalog-flip {
  display: flex;
  justify-content: center;
  padding: 60px 0 0 0;
  margin-bottom: -90px
}

.catalogs-sec h5 {
  font-weight: 600;
  margin-top: 10px;
  font-size: 20px;
}

.catalogs-sec .col {
  position: relative;
}

.catalogs-sec .col:hover .catalog-overlay {
  opacity: 1;
}

.catalogs-sec img {
  border-radius: 14px;
}

.catalogs-sec .catalog-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #eddfcceb;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  opacity: 0;
  transition: 0.3s all ease-in-out;
  border-radius: 12px;
}


@media only screen and (max-width: 500px) {

  .facility .row {
    padding: 0px 0;
  }

  .new-facility-overview .fac-content {
    width: 96% !important;
    padding: 30px 30px !important;
    margin-left: 1vw !important;
  }

  .new-gallery img {
    width: 100% !important;
    height: auto !important;
  }

  .new-gallery .container {
    padding: 30px 0px 10px !important;
  }

  .how-works-sec {
    padding: 50px 0;
  }

  .catalogs-sec .row {
    display: block;
  }

  .about-certs .col:nth-child(2) {
    display: none;
  }

  .catalogs-sec .row .col {
    margin-bottom: 30px;
  }

  .catalog-flip {
    margin-bottom: 0;
  }
}

.Quantityerror {
  margin-top: 11px;
  color: red;
  font-style: italic;
}


.last-title {
  background-image: url(../../../public/assets/img/last-bann.jpg);
  text-align: left;
  padding: 150px 180px;
  background-size: cover;
}

.last-title h1 {
  color: #fff;
  letter-spacing: 8px;
}

.last-title p {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 2px;
}

.hidden-menu7 {
  display: none;
}

.shop-p {
  font-size: 16px;
  font-weight: 600;
  padding: 0 20px 0 46px;
}

h6.saint-text {
  margin-top: 10px;
}

.hidenav {
  display: none;
}


.icon-search {
  background-color: transparent;
  border: 0px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  z-index: 999;
  font-size: 17px;
  cursor: pointer;
}

.prod-col.disabled-sku.color-box,
.custom_row .prod-col {
  position: relative;
}

.prod-col.disabled-sku.color-box:before,
.custom_row .prod-col.disabled-sku:before {
  content: "";
  position: absolute;
  top: 85%;
  left: 10px;
  width: 100%;
  height: 100%;
  border-top: 2px solid black;
  transform: rotate(-45deg);
  transform-origin: top left;
  box-sizing: border-box;
}

p.newsletter-error {
  font-style: italic;
  font-weight: 500;
  margin-top: 5px;
}

p.newsletter-success {
  color: white;
  font-style: italic;
  font-weight: 500;
  margin-top: 7px;
}

[data-aos^=fade][data-aos^=fade] {
  opacity: 1 !important;
  transition-property: opacity, transform;
}

.register-checkboxes .check {
  width: 100%;
}


.mocra-img {
  width: auto;
  height: auto;
}

.mocra-col {
  width: 100%;
  height: 100%;
  text-align: center;
}

.mocra-sec {
  background-image: url(../../../public/assets/img/mocra-bg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 60px 0;
  margin-top: 60px;
}

.new-space {
  margin-top: 50px;
}

.new-mocra-service {
  background-image: none !important;
  background-color: #ddd7d3;
}

.new-facility-overview {
  background-image: url(../../../public/assets/img/ccmilabs-bg-overview.jpg);
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.new-facility-overview .fac-content {
  width: 25%;
  background-color: #f2f2f2e0;
  margin-left: 7vw;
  text-align: start;
  padding: 30px 30px;
}

.new-facility-overview .container {
  padding: 30px 0;
}

.new-facility-overview .fac-content .p-bold {
  margin: 20px 0 40px;
}

.new-facility-overview .fac-content p {
  margin-bottom: 20px;
}

.new-facility-overview .fac-content h3 {
  font-weight: bold;
  font-size: 30px;
}

.new-gallery .container {
  padding: 50px 0px 110px;
}

.new-gallery img {
  width: 100%;
  height: 170px;
  object-fit: cover;
}

.mocra-img img {
  width: 100%;
}

.new-mocra-service .container {
  display: flex;
  gap: 30px;
}

.new-mocra-service .container .mocra-img {
  flex: 0 0 40%;
}

.new-mocra-service#cm-overview .content {
  margin-left: 0%;
}

.labs-header {
  background-color: #333333;
  padding: 90px 0;
  text-align: center;
  margin-top: -3px;
}

.labs-header p {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  margin-top: 16px;
}

.arrivals .products-item__img {
  height: 458px !important;
  object-fit: contain;
}

.arrivals {
  padding-bottom: 60px;
}


.how-works-sec .how-cont:last-child {
  margin-bottom: 0px;
}

.ab-bg h1 {
  color: #000;
}

.product-content {
  display: flex;
  margin-bottom: 30px;
  justify-content: space-between;
}

.promo-image-box {
  width: 7rem;
  height: 7rem;
  border: 1px solid rgba(75, 74, 79, 0.1215686275);
}

.shop-aside__item ul li span {
  font-size: 16px;
  line-height: 170%;
  color: #666666;
  display: block;
  padding: 13px 0;
  border-bottom: 1px solid #eeeeee;
}

// @media (min-width: 1024px) {
//   .login-buttons li a {
//     background-color: #c9b39e;
//     padding: 10px;
//     font-weight: 500;
//     margin: 10px;
//     color: white;
//     border-radius: 5px;
//   }
// }


@media (max-width: 414px) {
  .discover-main {
    padding: 0 0px;
  }

  .main-menu-mobile ul li {
    padding: 0px 1px 10px;
  }

  .discover-main img {
    width: 100%;
    height: auto;
    object-fit: scale-down;
  }

  .log-button {
    cursor: pointer !important;
  }

  .log-button:hover {
    background: #a66d4d;
    color: #fff;
    font-weight: 700;
  }

  .react-tabs__tab {
    margin-top: 9px;
    font-size: 31px;
  }

  .react-multiple-carousel__arrow {
    background: transparent !important;
  }

  .react-multiple-carousel__arrow--left {
    left: 0px;
  }

  .products-cat {
    padding: 0px 20px 20px 20px !important;
  }

  #viewport-meta {
    margin: 0px 0 0;
  }

  .brown-menu .log-button {
    background-color: #d3c8b978;
    padding: 8px;
    border-radius: 5px;
    font-weight: 600;
    color: #b77f60 !important;
  }

  .content .slider .next,
  .content .slider .prev {
    display: none;
  }

  .login-buttons .brown-menu {
    padding: 20px 0;
    margin: 0px 0;
    font-size: 15px;
    font-weight: 300;
  }

  #main-categories h6 {
    font-size: small;
  }

  .all-category {
    padding-top: 0px;
    padding-bottom: 75px;
  }

  .mobile-left-menu-top svg {
    background-color: #fd3434;
    color: white;
    border-radius: 100%;
    padding: 5px;
    font-size: 12px;
    width: 10px;
    height: 10px;
    position: absolute;
    right: 6px;
    top: 10px;
  }

  .carousel-item-padding-40-px {
    margin-right: 0px;
  }
}